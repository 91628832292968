<template>
	<div id="myteamList">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="账号:">
					<el-input size="small " v-model="name" placeholder="请输入账号" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="电话号码:">
					<el-input size="small " v-model="phone" placeholder="请输入电话号码" clearable style="width: 180px;"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
					<el-button type="primary" size="small" @click="openAdd()">添加成员</el-button>
				</el-form-item>
			</el-form>
		</div>
	
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="账号" prop="username" align="center" />
				<el-table-column label="电话号码" prop="phone" align="center" />
				<el-table-column label="昵称" prop="name" align="center" />
				<el-table-column label="直属上级" prop="username" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.parent">{{scope.row.parent.username}}</div>
						<!-- <div v-if="scope.row.parent">{{scope.row.parent.name}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center" />
				<el-table-column label="操作" align="center" width="220" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" @click="restPassword(scope.row.id)">重置密码</el-button>
						<el-button type="text" @click="openEdit(scope.row)">编辑</el-button>
						<el-button type="text" @click="openPermission(scope.row.id)">权限管理</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-dialog title="编辑权限" :visible.sync="permissionShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false"
			@opened="getUserPermissions">
			<div v-loading="permissionLoading">
				<div style="max-height: 50vh;overflow-y: auto;">
					<el-tree ref="permissionTree" :data="permissionsList" show-checkbox :props="{ label: 'title', children: '_child' }"  node-key="id">
						<div slot-scope="{ node, data }" style="display: flex;align-items: center;width: 100%;">
							<div>{{ node.label }}</div>
							<div v-if="data.type==6" style="font-size: 14px;color: #666;">（操作权限）</div>
						</div>
					</el-tree>
				</div>
				<div style="text-align: center;margin-top: 28px;">
					<el-button @click="permissionShow = false">取 消</el-button>
					<el-button type="primary" @click="setPermissions()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog :title="admin_id?'编辑成员':'添加成员'" :visible.sync="addShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false">
			<div>
				<el-form :model="addInfo" :rules="rules" ref="ruleForm" label-width="100px">
					<el-form-item label="账号：" prop="username">
						<el-input size="small " v-model="addInfo.username" :disabled="this.admin_id!=null" placeholder="请输入账号" clearable autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="密码：" prop="password" v-if="!this.admin_id">
						<el-input size="small" type="password" v-model="addInfo.password" placeholder="请输入密码" clearable autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="手机号码：" prop="phone">
						<el-input size="small " v-model="addInfo.phone" placeholder="请输入手机号码" clearable></el-input>
						<div style="font-size: 13px;color: #999;line-height: 22px;">手机号码将用于接收登录验证码，请正确填写</div>
					</el-form-item>
					<el-form-item label="昵称：" prop="name">
						<el-input size="small " v-model="addInfo.name" placeholder="请输入昵称" clearable></el-input>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 28px;">
					<el-button @click="addShow = false">取 消</el-button>
					<el-button type="primary" @click="saveUser()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips,validatePassword} from '@/util/util.js'
	export default {
		name: "myteamList",
		data() {
			return {
				name:'',
				phone:'',
				loading:false,
				page:1,
				total:0,
				list:[],
				permissionShow: false,
				permissionLoading: false,
				permissionsList: [],
				admin_id:null,
				addShow:false,
				rules:{
					username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
					password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
					phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
					name: [{ required: true, message: '请输入昵称', trigger: 'blur' }]
				},
				addInfo:{
					username:'',
					password:'',
					phone:'',
					name:''
				}
			};
		},
		created() {
			this.getList()
			this.getPermissions()
		},
		methods:{
			saveUser(){
				this.$refs['ruleForm'].validate((valid) => {
				    if (!valid) return;
					let url = '/api/main/Admin/create'
					let data = this.addInfo
					if(this.admin_id){
						url = '/api/main/Admin/edit'
						data.id = this.admin_id
						delete data.password
					}
					this.axios.post(url,data).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
							this.addShow = false
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openEdit(item){
				this.admin_id = item.id
				this.addInfo.username = item.username
				this.addInfo.name = item.name
				this.addInfo.phone = item.phone
				this.addInfo.img = item.img
				this.addShow = true
			},
			openAdd(){
				this.admin_id = null
				this.addInfo = {
					username:'',
					password:'',
					phone:'',
					name:''
				}
				this.addShow = true
			},
			openPermission(id){
				this.admin_id = id
				this.permissionShow = true
			},
			//设置权限
			setPermissions() {
				let permissions = this.$refs.permissionTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked]
				let data = {
					user_id: this.admin_id,
					permission_ids: permissions,
				}
				this.permissionLoading = true
				this.axios.post('/api/setPermissions', data).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.permissionShow = false;
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//获取指定用户的权限
			getUserPermissions() {
				let data = { 
					user_id:this.admin_id,
				}
				this.permissionLoading = true
				this.axios.get(`/api/appointPermissions`, { params: data }).then(res => {
					if (res.code == 200) {
						this.$refs.permissionTree.setCheckedKeys(res.data)
						this.$nextTick(()=>{
							let nowPermission = [...this.$refs.permissionTree.getCheckedKeys(),...this.$refs.permissionTree.getHalfCheckedKeys()]
							let permission = res.data
							const difference = nowPermission.filter((value) => !permission.includes(value));
							difference.map(item => {
								this.$refs.permissionTree.setChecked(item, false);
							})
						})
						this.permissionLoading = false
					} else {
						this.permissionLoading = false
						eltips(res.msg, 'error')
					}
				})
			},
			//获取权限列表
			getPermissions() {
				this.axios.get('/api/getPermissions').then(res => {
					if (res.code == 200) {
						this.permissionsList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			restPassword(id) {
				this.$prompt('请输入新密码', `重置密码`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if(!validatePassword(value)) return '密码必须八位以上，且由数字与字母组成，至少包含一位大写字母';
					},
				}).then(({ value }) => {
					let data = { password: value,id:id }
					this.axios.post('/api/main/Admin/rsetPassword', data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					username:this.name,
					phone:this.phone,
					page:this.page
				}
				this.loading = true
				this.axios.get('/api/main/Admin/accountList',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#myteamList{
		min-height: calc(100vh - 70px);
	}
	
	#myteamList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.follows {
			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>