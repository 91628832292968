<template>
	<div id="map-container">
		<el-button type="text" size="large" class="back" @click="back" v-if="deepTree.length > 1">返回</el-button>
		<div class="echarts" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">
			<div id="map"></div>
		</div>
	</div>
</template>

<script>
	import {
		getChinaJson,
		getProvinceJson,
		getCityJson,
		getDistrictJson
	} from "@/api/map";
	import { mapOption } from '@/config/mapOption'
	import resize from '@/utils/resize'
	import * as echarts from 'echarts';
	export default {
		mixins: [resize],
		name: "china",
		components: {},
		props: {
			not_click:{
				type: Boolean,
				default: false,
			},
			map_type: {
				type: String,
				default: 'all'
			},
			areaCode: {
				type: String,
				default: '000000000000'
			},
			areaLevel: {
				type: [String, Number],
				default: 0
			},
			areaName: {
				type: String,
				default: 'china'
			},
			// 当前地图上的地区名字
			mapNameList: {
				type: Array,
				default () {
					return []
				}
			},
			// 当前地图上的地区Code
			mapCodeList: {
				type: Array,
				default () {
					return []
				}
			},
			// 地区统计数据
			areaStatistic: {
				type: Array,
				default () {
					return []
				}
			},
			newPoint: {
				type: Array,
				default () {
					return []
				}
			},
		},
		data() {
			return {
				loading:true,
				chart: null, // 实例化echarts
				mapDataList: [], // 当前地图上的地区
				option: {
					...mapOption.basicOption
				}, // map的相关配置
				deepTree: [], // 点击地图时push，点返回时pop
				areaStatisticMapValue: {}, // 地图数据value, 只是amounts
				areaStatisticMapData: {}, // 地图数据data,包含所有数据
				areaLevelMap: {
					'country': 0,
					'china': 0,
					'province': 1,
					'city': 2,
					'district': 3,
				},
				tooltipAutoplay: null, // 提示框自动播放
				tooltipAutoplayIndex: 0, // 提示框自动播放index
				mapData:{
					all: '',
					company: '',
					job: '',
					online: '',
					job_gap: '',
				},
				lastLevel:0
			}
		},
		beforeDestroy() {
			if (!this.chart) return;
			this.chart.dispose()
			this.chart = null
		},
		mounted() {
			this.$nextTick(() => {
				this.initEcharts();
				this.chart.on('click', this.echartsMapClick);
			});
		},
		watch: {
			areaStatistic: {
				handler(val) {
					var objValue = {},
						objData = {}
					for (var i = 0; i < val.length; i++) {
						objValue[val[i]['areaCode'].substr(0, 6)] = val[i].amounts * 1
						objData[val[i]['areaCode'].substr(0, 6)] = val[i]
					}
					this.areaStatisticMapValue = objValue
					this.areaStatisticMapData = objData
					this.initEcharts()
				},
				deep: true,
			},
			map_type(val){
				this.updateLocation()
			},
			newPoint(val){
				this.mapData[val[2]].last_data.lat = val[0]
				this.mapData[val[2]].last_data.lng = val[1]
				if(this.map_type=='job_gap') return;
				this.updatePoint(val[0],val[1])
			},
		},
		methods: {
			getCountData(adcode) {
				return new Promise((resolve, reject) => {
					let num = 0
					this.axios.get('/api/orange_manage/VisualData/obtain_employment_data',{params:{adcode:adcode}}).then(res => {
						this.mapData.all = res.data
						num++
					})
					this.axios.get('/api/orange_manage/VisualData/company_data',{params:{adcode:adcode}}).then(res => {
						this.mapData.company = res.data
						num++
					})
					this.axios.get('/api/orange_manage/VisualData/job_data',{params:{adcode:adcode}}).then(res => {
						this.mapData.job = res.data
						num++
					})
					this.axios.get('/api/orange_manage/VisualData/online_data',{params:{adcode:adcode}}).then(res => {
						this.mapData.online = res.data
						num++
					})
					this.axios.get('/api/orange_manage/VisualData/job_gap_data',{params:{adcode:adcode}}).then(res => {
						this.mapData.job_gap = res.data
						num++
					})
					let time = 0
					let insetIntervalv = setInterval(() => {
						time += 0.2
						if (num == 5) {
							this.loading = false
							clearInterval(insetIntervalv)
							resolve()
						}
						if (time == 30) {
							this.loading = false
							eltips('请求超时', 'error')
							clearInterval(insetIntervalv)
						}
					}, 200)
				})
			},
			// 初次加载绘制地图
			async initEcharts() {
				//地图容器
				this.chart = echarts.init(document.getElementById('map'));
				await this.getCountData()
				if (this.areaLevel === 0) {
					this.requestGetChinaJson();
				} else if (this.areaLevel === 1) {
					this.requestGetProvinceJSON({
						name: this.areaName,
						level: 'province',
						adcode: this.areaCode.substr(0, 6)
					})
				} else if (this.areaLevel === 2) {
					this.requestGetCityJSON({
						name: this.areaName,
						level: 'city',
						adcode: this.areaCode.substr(0, 6)
					})
				} else if (this.areaLevel === 3) {
					this.requestGetDistrictJSON({
						name: this.areaName,
						level: 'district',
						adcode: this.areaCode.substr(0, 6)
					})
				} else {
					return false
				}
			},
			// 地图点击
			async echartsMapClick(params) {
				if(this.not_click) return;
				this.$emit('update:areaCode', params.data.adcode + '000000')
				this.$emit('update:areaName', params.data.name)
				this.$emit('update:areaLevel', this.areaLevelMap[params.data.level])
				if (params.data.level === 'province') {
					this.loading = true
					await this.getCountData(params.data.adcode)
					this.requestGetProvinceJSON(params.data);
				} else if (params.data.level === 'city') {
					this.loading = true
					await this.getCountData(params.data.adcode)
					this.requestGetCityJSON(params.data)
				} else if (params.data.level === 'district' && this.mapDataList.length > 1) {
					this.loading = true
					await this.getCountData(params.data.adcode)
					this.requestGetDistrictJSON(params.data)
				} else {
					return false
				}
			},
			//绘制全国地图areaStatistic
			requestGetChinaJson(notTree) {
				getChinaJson().then(res => {
					this.$emit('update:areaLevel', 0)
					this.setJsonData(res,{},notTree)
				});
			},
			// 加载省级地图
			requestGetProvinceJSON(params) {
				getProvinceJson(params.adcode).then(res => {
					this.$emit('update:areaLevel', 1)
					this.setJsonData(res, params)
				});
			},
			// 加载市级地图
			requestGetCityJSON(params) {
				getCityJson(params.adcode).then(res => {
					this.$emit('update:areaLevel', 2)
					this.setJsonData(res, params)
				})
			},
			// 加载县级地图
			requestGetDistrictJSON(params) {
				getDistrictJson(params.adcode).then(res => {
					this.$emit('update:areaLevel', 3)
					this.setJsonData(res, params)
				})
			},
			// 设置数据
			setJsonData(res, params,notTree) {
				var mapDataList = [];
				var mapNameList = [];
				var mapCodeList = [];
				for (var i = 0; i < res.features.length; i++) {
					var obj = {
						...res.features[i].properties,
					};
					let data = ''
					let valueData = this.mapData[this.map_type].data || []
					data = valueData.find(item => item.adcode == obj.adcode)
					obj.value = data ? data.count : 0
					mapDataList.unshift(obj)
					mapNameList.unshift(res.features[i].properties.name)
					mapCodeList.unshift(res.features[i].properties.adcode + '000000')
				}
				this.mapDataList = mapDataList;
				this.$emit('update:mapNameList', mapNameList)
				this.$emit('update:mapCodeList', mapCodeList)
				this.setMapData(res, params,notTree)
			},
			// 设置地图信息
			setMapData(res, params,notTree) {
				if (this.areaName === 'china') {
					let index = this.deepTree.findIndex(item=>item.params.level=='country')
					if(index!=-1){
						this.deepTree[index] = {
							mapDataList: this.mapDataList,
							params: {
								name: 'china',
								level: 'country',
								adcode: '100000'
							}
						}
					}else{
						this.deepTree.push({
							mapDataList: this.mapDataList,
							params: {
								name: 'china',
								level: 'country',
								adcode: '100000'
							}
						});
					}
					echarts.registerMap('china', res);
					this.renderMap('china', this.mapDataList);
				} else {
					let index = this.deepTree.findIndex(item=>params.level==item.params.level)
					if(index!=-1){
						this.deepTree[index] = {
							mapDataList: this.mapDataList,
							params: params,
						}
					}else{
						this.deepTree.push({
							mapDataList: this.mapDataList,
							params: params,
						});
					}
					echarts.registerMap(params.name, res);
					this.renderMap(params.name, this.mapDataList);
				}
			},
			// 渲染地图
			renderMap(map, data) {
				var mapDataList = data.map(item => {
					return {
						name: item.name,
						value: item.value
					}
				})
				mapDataList = mapDataList.sort(function(a, b) {
					return b.value - a.value
				});
				var pointData = []
				for (var i = 0; i < data.length; i++) {
					if (data[i].value != 0) {
						pointData.push({
							...data[i],
							value: [data[i].center[0], data[i].center[1], data[i].value],
						})
					}
				}
				// 设置左上角当前位置
				this.option.title[0].text = map === 'china' ? '全国数据统计' : map
				this.option.geo = {
					show: false,
					map: map,
					zoom: 1.2, //当前视角的缩放比例
					roam: true, //是否开启平游或缩放
					center: undefined,
				}
				
				let count = 0
				if(this.map_type=='all') count = this.mapData[this.map_type].all_server_count;
				if(this.map_type=='company') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='job') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='online') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='job_gap') count = this.mapData[this.map_type].count;
				
				if(count>200) {
					count = parseInt(count/100)*100
					let num = parseInt(count/5)
					this.option.dataRange.splitList = [
						{start: 4*num, end: 99999999999, label: `>${4*num}`, color: '#4b5cc4'},
						{start: 3*num+1, end: 4*num, label: num*3+1==num*4?num*3+1:`${3*num+1}-${4*num}`, color: '#3979d1'},
						{start: 2*num+1, end: 3*num, label: num*2+1==num*3?num*2+1:`${2*num+1}-${3*num}`, color: '#2895dd'},
						{start: num+1, end: 2*num, label: num+1==num*2?num+1:`${num+1}-${2*num}`, color: '#16b2ea'},
						{start:1, end: num,label: num==1?'1':`1-${num}`, color: '#04cef6'},
						{start:0,end: 0,label: '无数据', color: '#212225'},
					]
				} else{
					this.option.dataRange.splitList = [
						{start: 101, end: 99999999999, label: '> 100', color: '#4b5cc4'},
						{start: 81, end: 100, label: '81 - 100', color: '#3979d1'},
						{start: 41, end: 80, label: '41 - 80', color: '#2895dd'},
						{start: 21, end: 40, label: '21 - 40', color: '#16b2ea'},
						{start:1, end: 20,label: '1 - 20', color: '#04cef6'},
						{start:0,end: 0,label: '无数据', color: '#212225'}
					]
				}
				this.option.series = [
					{
						selectedMode: false,
						name: map,
						mapType: map,
						zoom: 1, //当前视角的缩放比例
						roam: false, //是否开启平游或缩放
						center: undefined,
						scaleLimit: { //滚轮缩放的极限控制
							min: .5,
							max: 10
						},
						...mapOption.seriesOption,
						data: data
					},
				]
				if(this.map_type!='job_gap'){
					let last_data = this.mapData[this.map_type].last_data
					let arr = []
					arr = [{name:'',tooltip:{show:false},visualMap:false,value:[parseFloat(last_data.lng),parseFloat(last_data.lat),0]}]
					this.option.series.push({
						  type: 'effectScatter',//散点类型
						  coordinateSystem: 'geo',// series坐标系类型
						  data: arr,
						  symbolSize:10,
						  zlevel: 2,
						  rippleEffect: {
							period: 5, //波纹秒数
							brushType: 'fill', //stroke(涟漪)和fill(扩散)，两种效果
							scale: 20 //波纹范围
						  },
						  hoverAnimation: true,
						  itemStyle: {
						    normal: {
								show:true,
								color: '#F4E925',
								shadowBlur: 10,
								shadowColor: '#000'
						    }
						  },
						  showEffectOn: 'render', //加载完毕显示特效
					})
				}
				//渲染地图
				this.chart.setOption(this.option, true)
			},
			updatePoint(lat,lnt,hidden){
				let option = this.chart.getOption();
				if(hidden){
					option.series[1].symbolSize = 0
				}else{
					let arr = []
					arr = [{name:'',tooltip:{show:false},visualMap:false,value:[parseFloat(lnt),parseFloat(lat),0]}]
					option.series[1].data = arr
					option.series[1].symbolSize = 10
				}
				this.chart.setOption(option);    
			},
			updateLocation(){
				let option = this.chart.getOption();
				let valueData = this.mapData[this.map_type].data || []
				option.series[0].data.forEach(item=>{
					let data = 0
					data = valueData.find(valueItem => valueItem.adcode == item.adcode)
					item.value =  data ? data.count : 0
				})
				this.chart.setOption(option); 
				this.upDatePieces()
				if(this.map_type=='job_gap'){
					this.updatePoint(0,0,true)
				}else{
					let last_data = this.mapData[this.map_type].last_data
					this.updatePoint(parseFloat(last_data.lat),parseFloat(last_data.lng),last_data.lat==0)
				}
			},
			upDatePieces(){
				let option = this.chart.getOption()
				let pieces = option.dataRange.pieces
				let count = 0
				if(this.map_type=='all') count = this.mapData[this.map_type].all_server_count;
				if(this.map_type=='company') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='job') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='online') count = this.mapData[this.map_type].all_count;
				if(this.map_type=='job_gap') count = this.mapData[this.map_type].count;
				if(count>200){
					count = parseInt(count/100)*100
					let num = parseInt(count/5)
					pieces[4].min = 1
					pieces[4].max = num
					pieces[4].label = num==1?'1':`1-${num}`
				
					pieces[3].min = num+1
					pieces[3].max = 2*num
					pieces[3].label = num+1==num*2?num+1:`${num+1}-${2*num}`
					
					pieces[2].min = 2*num+1
					pieces[2].max = 3*num
					pieces[2].label = num*2+1==num*3?num*3:`${2*num+1}-${3*num}`
					
					pieces[1].min = 3*num+1
					pieces[1].max = 4*num
					pieces[1].label = num*3+1==num*4?num*4:`${3*num+1}-${4*num}`
					
					pieces[0].min = 4*num+1
					pieces[0].max = 99999999999
					pieces[0].label = `> ${4*num}`
					
				}else{
					pieces[4].min = 1
					pieces[4].max = 20
					pieces[4].label = '1-20'
					
					pieces[3].min = 21
					pieces[3].max = 40
					pieces[3].label = '21-40'
					
					pieces[2].min = 41
					pieces[2].max = 80
					pieces[2].label = '41-80'
					
					pieces[1].min = 81
					pieces[1].max = 100
					pieces[1].label = '81-100'
					
					pieces[0].min = 101
					pieces[0].max = 99999999999
					pieces[0].label = '>100'
				}
				this.chart.setOption(option);
			},
			// 返回
			async back() {
				if (this.deepTree.length > 1) {
					this.deepTree.pop();
					this.mapDataList = this.deepTree[this.deepTree.length - 1].mapDataList;
					var areaName = this.deepTree[this.deepTree.length - 1].params.name;
					var areaCode = this.deepTree[this.deepTree.length - 1].params.adcode;
					var areaLevel = this.deepTree[this.deepTree.length - 1].params.level;
					var map_type = this.deepTree[this.deepTree.length - 1].map_type;
					var mapNameList = this.mapDataList.map(item => {
						return item.name
					})
					var mapCodeList = this.mapDataList.map(item => {
						return item.adcode + '000000'
					})
					if (areaLevel === 'country') {
						this.loading = true
						await this.getCountData()
					} else {
						this.loading = true
						await this.getCountData(areaCode)
					}
					
					this.$emit('update:areaCode', (areaCode === '100000' ? '000000' : areaCode) + '000000')
					this.$emit('update:areaName', areaName)
					this.$emit('update:areaLevel', this.areaLevelMap[areaLevel])
					this.$emit('update:mapNameList', mapNameList)
					this.$emit('update:mapCodeList', mapCodeList)
					this.$emit('update:map_type', map_type)
					this.renderMap(areaName, this.mapDataList);
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	#map-container {
		height: 100%;
		position: relative;

		.echarts {
			height: 100%;

			#map {
				width: 100%;
				height: 100%;
			}
		}

		.back {
			position: absolute;
			top: 25px;
			left: 5px;
			z-index: 9;
			color: #FFFFFF;
			font-weight: bolder;
		}
	}
</style>
