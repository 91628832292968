<template>
	<div id="editaccount">
		<div class="frombox">
			<div>
				<div class="inputbox">
					<div class="label">旧手机号</div>
					<!--					 <el-input size="mini" class="input" v-model="oldphone" disabled clearable></el-input>-->
					 <el-input size="mini" class="input" :value="changephone(oldphone)" disabled clearable></el-input>
					<div class="codebox" v-if="!oldshow" @click="getcode(1,oldphone)">获取验证码</div>
					<div class="codebox" v-else>{{oldtims}}秒后获取</div>
				</div>
				<div class="inputbox">
					<div class="label">验证码</div>
					 <el-input size="mini" class="input" v-model="oldcode" placeholder="请输入验证码" clearable></el-input>
				</div>
				<div class="inputbox">
					<div class="label">新账号</div>
					 <el-input size="mini" class="input" v-model="phone" placeholder="请输入新账号" clearable></el-input>
					<div class="codebox" v-if="!codeshow" @click="getcode(2,phone)">获取验证码</div>
					<div class="codebox" v-else>{{codetimes}}秒后获取</div>
				</div>
				<div class="inputbox">
					<div class="label">验证码</div>
					 <el-input size="mini" class="input" v-model="code" placeholder="请输入验证码" clearable></el-input>
				</div>
			</div>
			<div class="sendbtn" @click="queren">确认</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: '',
	data() {
		return {
			oldphone: JSON.parse(sessionStorage.getItem('userinfo')).phone,
			oldcode: '',
			phone: '',
			code: '',
			phones: '',
			oldtims:59,
			oldshow:false,
			codetimes:59,
			codeshow:false,
		};
	},
	created() {},
	methods: {
		changephone(value) {
			// var value=JSON.parse(sessionStorage.getItem('admin')).phone,
			let atart = value.slice(0, 3);
			let end = value.slice(-4);
			return `${atart}****${end}`;
			this.phones = value;
		},
		getcode(type,phone) {
			if(!phone){
				eltips('请输入手机号码','error')
				return false
			}
			
			this.axios.post('/api/send_code/public',{phone: phone,shop_id:25079057 }).then(res => {
				let that = this;
				console.log(res);
				if (res.code == 200) {
					eltips('发送成功','success')
					if(type==1){
						that.oldshow = true
						let codeInt = setInterval(()=>{
							if(that.oldtims>1){
								that.oldtims--
							}else{
								that.oldshow = false
								that.oldtims = 59
								clearInterval(codeInt)
							}
						},1000)
					}else{
						that.codeshow = true
						let codeInt = setInterval(()=>{
							if(that.codetimes>1){
								that.codetimes--
							}else{
								that.codeshow = false
								that.codetimes = 59
								clearInterval(codeInt)
							}
						},1000)
					}
				}else{
					eltips(res.msg,'error')
				}
				
			});
		},
		queren() {
			let data = {
				phone: this.oldphone,
				sms_code: this.oldcode,
				new_phone: this.phone,
				new_sms_code: this.code
			};
			this.axios
				.post('/api/user/update_number', data, {
					header: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						eltips(res.msg, 'success');
					} else {
						eltips(res.msg, 'error');
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
};
</script>

<style>
#editaccount .el-input__inner {
	border: none !important;
	height: 38px !important;
	line-height: 38px !important;
	padding: 0 !important;
	border-radius: 0 !important;
	font-size: 14px !important;
}

#editaccount .el-input.is-disabled .el-input__inner {
	background: #ffffff;
}
</style>

<style scoped lang="less">
#editaccount {
	min-height: calc(100vh - 70px);
	background: #ffffff;
}

.frombox {
	padding-top: 80px;
	width: 320px;
	margin: 0 auto;
	position: relative;
	left: -60px;
	.inputbox {
		font-size: 14px;
		padding-bottom: 10px;
		border-bottom: 1px solid #f3f3f3;
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;
		.codebox {
			line-height: 38px;
			position: absolute;
			right: 0;
			top: 0;
			cursor: pointer;
		}
		.codecolor {
			color: #1e92ff;
		}
		.label {
			line-height: 38px;
			position: absolute;
			left: 0;
			top: 0;
		}
		.input {
			float: right;
			width: 220px;
			height: 38px;
			line-height: 38px;
			font-size: 16px;
		}
	}
	.sendbtn {
		margin: 60px auto 0;
		line-height: 50px;
		text-align: center;
		background: #409eff;
		color: #ffffff;
		font-size: 20px;
		border-radius: 4px;
	}
}
</style>
