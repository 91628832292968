<template>
	<div id="marketExaminecustomerList">
		<div v-show="!detailShow">
			<div class="headbox">
				<el-form :inline="true">
					<el-form-item label="客户名称:">
						<el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 150px;">
						</el-input>
					</el-form-item>
					<el-form-item label="联系人:">
						<el-input size="small " v-model="contacts" placeholder="请输入客户名称" clearable style="width: 150px;">
						</el-input>
					</el-form-item>
					<el-form-item label="联系电话:">
						<el-input size="small " v-model="contacts_phone" placeholder="请输入客户名称" clearable
							style="width: 150px;"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
		
			<div class="tablebox">
				<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="客户全称" prop="customer.name" align="center" width="200"/>
					<el-table-column label="订单类型" align="center" width="200">
						<template slot-scope="scope">
							<div v-if="scope.row.is_create_account===1">saas系统客户</div>
							<div v-else>{{scope.row.submit_type}}</div>
						</template>
					</el-table-column>
					<el-table-column label="时长调整" align="center" width="120">
						<template slot-scope="scope">
							<div v-if="scope.row.is_create_account===1">{{scope.row.duration}}{{scope.row.duration_type==1?'年':scope.row.duration_type==2?'月':'天'}}</div>
						</template>
					</el-table-column>
					<el-table-column label="提报金额" prop="amount" align="center" width="180"/>
					<el-table-column label="需求说明" prop="remarks" align="center">
						
					</el-table-column>
					
					<el-table-column label="申请人" align="center" width="120">
						<template slot-scope="scope">
							<div>{{scope.row.operator.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="申请时间" prop="created_at" align="center" width="160"/>
					
					<el-table-column label="审核人" align="center" v-if="type!=1" width="120">
						<template slot-scope="scope">
							<div>{{scope.row.approver.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="审核时间" prop="approver_time" align="center" v-if="type!=1" width="160"/>
					<el-table-column label="拒绝理由" prop="approver_remarks" align="center" show-overflow-tooltip v-if="type==3"/>
					<el-table-column label="操作" align="center" width="180" fixed="right" v-if="type==1">
						<template slot-scope="scope">
							<el-button type="text" @click="agreeApplay(scope.row.id)" v-if="type==1">同意</el-button>
							<el-button type="text" @click="refuseApplay(scope.row.id)" v-if="type==1">拒绝</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		
			<div class="pages" v-if="total>10">
				<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
					layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
		</div>
		
		<dealProcess v-if="detailShow" :addhide.sync="detailShow" :contractForm="contractForm" :customeForm="customeForm"></dealProcess>
	</div>
</template>

<script>
	import { eltips,setTimeStyle } from '@/util/util.js'
	import dealProcess from '../components/dealProcess.vue'
	export default {
		name: "marketExaminecustomerList",
		components:{dealProcess},
		data() {
			return {
				name: '',
				contacts: '',
				contacts_phone: '',
				page: 1,
				total: 0,
				list: [],
				loading: false,
				type: '',
				contractForm:'',
				customeForm:'',
				detailShow:false,
			};
		},
		created() {
			if (this.$route.query.props) this.type = this.$route.query.props;
			this.getList()
		},
		watch: {
			$route() {
				this.detailShow = false
				this.name = ''
				this.contacts = ''
				this.contacts_phone = ''
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.type = this.$route.query.props
				this.getList()
			}
		},
		methods: {
			openDetail(item){
				this.customeForm = item.customer
				this.contractForm = item
				this.detailShow = true
			},
			agreeApplay(id) {
				this.$confirm('此操作将该客户转为成交，是否继续?', '通过审核', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: id,
						type: 1
					}
					this.axios.post('/api/main/CustomerDeal/approver', data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.getList()
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			refuseApplay(id) {
				this.$prompt('请输入拒绝理由', '拒绝成交', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if (!value) return '理由不能为空';
						if (value.replace(/\s/g, '').length < 1) return '理由不能为空';
					}
				}).then(({value}) => {
					let data = {
						id: id,
						type: 2,
						approver_remarks: value
					}
					this.axios.post('/api/main/CustomerDeal/approver',data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.getList()
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			changepage(e) {
				this.page = e
				this.getList()
			},
			getList() {
				let data = {
					transaction_status: this.type,
					page: this.page,
					name: this.name,
					contacts: this.contacts,
					contacts_phone: this.contacts_phone
				}
				this.loading = true
				this.axios.get('/api/main/CustomerDeal/listApprover', {
					params: data
				}).then(res => {
					this.loading = false
					if (res.code == 200) {
						this.total = res.data.total
						this.list = res.data.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#marketExaminecustomerList {
		min-height: calc(100vh - 70px);
	}
	
	#marketExaminecustomerList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}

	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}

	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}

	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>
