<template>
	<div id="addCustomer" class="body">
		<div style="background: #FFFFFF;padding: 10px;">
			<el-page-header @back="hideMarketForm">
				<div slot="content">
					<div style="font-size: 15px;line-height: 22px;color: #1989fa;">{{edit_id?'编辑':'添加'}}客户</div>
				</div>
			</el-page-header>
		</div>
		
		<div class="addbox databox" v-loading="loading">
			<el-form :model="form" :rules="rules" ref="addrefs" label-position="top">
				<div class="titleStyle">基本信息</div>
				
				<div style="display: flex;">
					<el-form-item label="客户全称:" style="flex: 1;margin-right: 28px;" prop="name">
						<span slot="label" style="color: #FF0000;">客户全称:</span>
						<el-input size="small" v-model="form.name" placeholder="请输入客户名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户名称:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.short_name" placeholder="请输入客户名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="所在地区:" style="flex: 1;margin-right: 28px;" prop="pickaddress">
						<span slot="label" style="color: #FF0000;">所在地区:</span>
						<xsk-addpick propsValue="label" size="small" style="width: 100%;" :default="defaultAddress" @confirm="pickAddress"></xsk-addpick>
					</el-form-item>
					<el-form-item label="详细地址:" style="flex: 1;" prop="address">
						<span slot="label" style="color: #FF0000;">详细地址:</span>
						<el-input size="small" v-model="form.address" placeholder="请输入详细地址" clearable style="width: 100%;"></el-input>
					</el-form-item>
					
				</div>
				
				<div style="display: flex;">
					<el-form-item label="客户编号:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.number" disabled placeholder="创建成功后将会自动生成编号" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="所在行业:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.industry" placeholder="请选择所在行业">
						    <el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="营业执照编号:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.credit_code" placeholder="请输入营业执照编号" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="注册日期:" style="flex: 1;">
						<el-date-picker
							style="width: 100%;"
						    v-model="form.registered_date"
						    type="date"
							size="small"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						    placeholder="请选择注册日期">
						</el-date-picker>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="注册资金:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.registered_capital" placeholder="请输入注册资金" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="公司规模:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.company_size" placeholder="请选择公司规模">
						    <el-option label="0-20人" value="0-20人"></el-option>
						    <el-option label="20-99人" value="20-99人"></el-option>
						    <el-option label="100-499人" value="100-499人"></el-option>
						    <el-option label="500-999人" value="500-499人"></el-option>
						    <el-option label="1000-9999人" value="1000-9999人"></el-option>
						    <el-option label="10000人以上" value="10000人以上"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业网址:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.website" placeholder="请输入企业网址" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="主营项目:" style="flex: 1;">
						<el-input size="small" v-model="form.main_business" placeholder="请输入主营项目" clearable style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="产品类型:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.type" placeholder="请选择产品类型">
						    <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>	
					</el-form-item>
					<el-form-item label="客户评级:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.level" placeholder="请选择客户评级">
						    <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="销售阶段 :" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.sale_stage" placeholder="请选择销售阶段">
						    <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="客户来源:" style="flex: 1;">
						<el-select style="width: 100%;" size="small" clearable v-model="form.from" placeholder="请选择客户来源">
						    <el-option v-for="item in fromList" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="登录账号/手机号:" style="flex: 1;" prop="phone">
						<el-input size="small" :disabled="edit_id!=''" v-model="form.phone" placeholder="请输入登录账号" clearable style="width: 100%;"></el-input>
						<div class="tips">请输入客户手机号，此手机号将会作为登录账号，并接收验证码。</div>
					</el-form-item>
				</div>
				
				<div style="display: flex;" v-if="!edit_id">
					<el-form-item label="数据归属 :" style="flex: 1;">
						<el-radio v-model="is_private" :label="1">新增至我的客户</el-radio>
						<el-radio v-model="is_private" :label="0">新增至公共客户</el-radio>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="客户附件 :" style="flex: 1;">
						<div class="uploadbox">
							<div class="imgbox" v-for="(item,index) in form.file" :key="index">
								<el-image class="img" :src="item" fit="cover" :preview-src-list="form.file"></el-image>
								<i class="el-icon-error delicon" @click="delImg(index)"></i>
							</div>
							<div class="imgbox" style="cursor: pointer;" @click="imgplugin=true">
								<i class="el-icon-picture addIcon"></i>
								<div class="addtips">上传附件图片</div>
							</div>
						</div>
					</el-form-item>
				</div>
				
				
				<div style="display: flex;">
					<el-form-item label="其他备注 :" style="flex: 1;">
						<el-input type="textarea" :rows="3" size="small" v-model="form.remarks" placeholder="请输入其他备注" clearable style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<template v-if="edit_id">
					<div class="titleStyle">
						联系人信息
						<el-button style="margin-left: 12px;" type="primary" size="small" @click="addContact(1)">新增联系人</el-button>
					</div>
					
					<el-table style="margin-bottom: 20px;" v-loading="loading" :data="contactList" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
						<el-table-column label="姓名" prop="name" align="center" />
						<el-table-column label="电话" prop="phone_number" align="center" />
						<el-table-column label="职位" prop="position" align="center" />
						<el-table-column label="性别" prop="gender" align="center" />
						<el-table-column label="邮箱" prop="email" align="center" />
						<el-table-column label="微信号" prop="wechat" align="center" />
						<el-table-column label="主要联系人" prop="position" align="center">
							<template slot-scope="scope">
								<div :style="{'color':scope.row.is_main?'#1989fa':'#F00'}">{{scope.row.is_main?'是':'否'}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center" width="120">
							<template slot-scope="scope">
								<el-button type="primary" size="small" @click="addContact(2,scope.row)">编辑</el-button>
								<!-- <el-button type="danger" size="small" @click="delContact(scope.row.id)">删除</el-button> -->
							</template>
						</el-table-column>
					</el-table>
				</template>
				
				<div class="btnbox">
					<el-button type="primary" size="medium" @click="hideMarketForm()">取消{{edit_id?'编辑':'创建'}}</el-button>
					<el-button type="primary" size="medium" @click="submitForm()">确认{{edit_id?'编辑':'创建'}}</el-button>
				</div>
			</el-form>
		</div>
		
		<el-dialog
			:title="`${contact_id?'编辑':'添加'}联系人`"
			:visible.sync="addShow"
			top="5vh"
			width="600px">
			<div v-loading="addLoading">
				<el-form label-width="90px">
					<el-form-item label="姓名：">
						<el-input size="small" v-model="contactForm.name" placeholder="请输入姓名" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="电话：">
						<el-input size="small" type="number" v-model="contactForm.phone_number" placeholder="请输入电话号码" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="职位：">
						<el-input size="small" v-model="contactForm.position" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="性别：">
						<el-select style="width: 100%;" size="small" clearable v-model="contactForm.gender" placeholder="请选择性别">
						    <el-option label="男" value="男"></el-option>
						    <el-option label="女" value="女"></el-option>
						    <el-option label="未知" value="未知"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="年龄：">
						<el-input size="small" v-model="contactForm.age" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="生日：">
						<el-input size="small" v-model="contactForm.birthday" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="邮箱：">
						<el-input size="small" v-model="contactForm.email" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="微信号：">
						<el-input size="small" v-model="contactForm.wechat" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="住址：">
						<el-input size="small" v-model="contactForm.address" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="详情备注：">
						<el-input size="small" v-model="contactForm.detail_remark" placeholder="请输入名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="主要联系人">
						<el-switch v-model="contactForm.is_main" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否"></el-switch>
					</el-form-item>
				</el-form>
				<div style="text-align: center;">
					<el-button @click="addShow = false">取 消</el-button>
					<el-button type="primary" @click="saveContack()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		
		<xsk-manage :managehide.sync="imgplugin" @confirm="confirmimg" multiple :selectarr="[]" :selectobjs="[]"></xsk-manage>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "addCustomer",
		data() {
			const validAddress =(rule, value, callback) => {
				let {province,city,area} = this.form;
				if (!province || !city || !area) {
					callback(new Error('请选择所在地'))
				} else {
					callback()
				}
			}
			return {
				rules:{
					name:[{ required: true, message: '请输入客户全称', trigger: 'blur' }],
					pickaddress: [{ required: true, validator:validAddress, trigger: 'change' }],
					address:[{ required: true, message: '请输入客户地址', trigger: 'blur' }],
					phone:[{ required: true, message: '请输入登录账号/手机号', trigger: 'blur' }],
				},
				form:{
					name:'',//客户姓名
					short_name:'',
					number:'',
					credit_code:'',//营业执照编号
					registered_capital:'',//注册资金
					province:'',
					city:'',
					area:'',
					address:'',//地址
					company_size:'',//公司规模
					from:'',//客户来源
					website:'',//企业网站
					main_business:'',//主营项目
					registered_date:'',//注册时间
					remarks:'',//备注
					type:'',
					level:'',
					industry:'',//行业
					sale_stage:'',
					phone:'',
					file:[],
				},
				is_private:1,
				defaultAddress:[],
				loading:false,
				typeList:[],
				levelList:[],
				fromList:[],
				industryList:[],
				stageList:[],
				edit_id:'',
				contactForm:{
					name:'',
					phone_number:'',
					position:'',
					gender:'',
					age:'',
					birthday:'',
					email:'',
					wechat:'',
					address:'',
					detail_remark:'',
					is_main:0,
				},
				contact_id:'',
				addShow:false,
				addLoading:false,
				contactList:[],
				addLoading:false,
				imgplugin:false
			};
		},
		props: {
			//控制插件显示 
			addhide: {
				type: Boolean,
				default: false
			},
			customerId: {
				type: [String, Number],
				default: ''
			},
			customeForm:{
				type: [Object, String],
				default: ''
			}
		},
		created() {
			this.getTypeList()
			if(this.customerId){
				this.edit_id = this.customerId;
				this.getContactList()
			} 
			if(this.customeForm){
				this.form.name = this.customeForm.name
				this.form.short_name = this.customeForm.short_name
				this.form.number = this.customeForm.number
				this.form.credit_code = this.customeForm.credit_code
				this.form.registered_capital = this.customeForm.registered_capital
				this.form.registered_date = this.customeForm.registered_date
				this.form.province = this.customeForm.province
				this.form.city = this.customeForm.city
				this.form.area = this.customeForm.area
				this.form.industry = this.customeForm.industry
				this.form.address = this.customeForm.address
				this.form.company_size = this.customeForm.company_size
				this.form.from = this.customeForm.from
				this.form.remarks = this.customeForm.remarks
				this.form.type = this.customeForm.type
				this.form.level = this.customeForm.level
				this.form.sale_stage = this.customeForm.sale_stage
				this.form.website = this.customeForm.website
				this.form.main_business = this.customeForm.main_business
				this.form.phone = this.customeForm.phone
				this.form.file = this.customeForm.file||[]
			} 
			if(this.customeForm.province) this.defaultAddress = [this.customeForm.province,this.customeForm.city,this.customeForm.area]
		},
		methods:{
			delImg(index){
				this.$confirm('此操作将永久删除该附件, 是否继续?', '删除附件', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.form.file.splice(index,1)
				}).catch(() => { });
			},
			confirmimg(e){
				if(e.imgobjs.length>0){
					e.imgobjs.forEach(item=>{
						this.form.file.push(item.qiniu_url)
					})
				}
			},
			addContact(type,item){
				if(type==1){
					this.contact_id = ''
					this.contactForm ={
						name:'',
						phone_number:'',
						position:'',
						gender:'',
						age:'',
						birthday:'',
						email:'',
						wechat:'',
						address:'',
						detail_remark:'',
						is_main:0
					}
				}else{
					this.contact_id = item.id
					this.contactForm ={
						name:item.name,
						phone_number:item.phone_number,
						position:item.position,
						gender:item.gender,
						age:item.age,
						birthday:item.birthday,
						email:item.email,
						wechat:item.wechat,
						address:item.address,
						detail_remark:item.detail_remark,
						is_main:item.is_main
					}
				}
				this.addShow = true
			},
			delContact(id){
				this.$confirm(`此操作将删除该联系人, 是否继续?`, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
					this.form.contact_data.splice(index,1)
				}).catch(() => {});
			},
			saveContack(){
				if(!this.contactForm.name) return eltips('请输入姓名','error');
				if(!this.contactForm.phone_number) return eltips('请输入电话号码','error');
				
				let url = '/api/main/Customer/contact_add'
				let data = JSON.parse(JSON.stringify(this.contactForm))
				data.customer_id = this.edit_id
				
				if(this.contact_id){
					url = '/api/main/Customer/contact_edit'
					data.id = this.contact_id
				}
				
				this.axios.post(url,data).then(res=>{
					if(res.code==200){
						this.getContactList()
						eltips(res.msg,'success')
						this.addShow = false
						this.$emit('updateList',true)
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getContactList(){
				const data = {customer_id:this.edit_id}
				this.axios.get('/api/main/Customer/contact_list',{params:data}).then(res=>{
					if(res.code==200){
						this.contactList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getTypeList(){
				this.axios.get('/api/main/Customer/TLF_list').then(res=>{
					if(res.code==200){
						this.typeList = res.data.type
						this.levelList = res.data.level
						this.fromList = res.data.from
						this.industryList = res.data.industry
						this.stageList = res.data.sale_stage
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			submitForm() {
				this.$refs['addrefs'].validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.form))
						let url = '/api/main/Customer/create'
						
						if(this.edit_id){
							data.id = this.edit_id
							url = '/api/main/Customer/edit'
						} else {
							data.is_private = this.is_private
						}
						this.loading = true
						this.axios.post(url,data).then(res=>{
							this.loading = false
							if(res.code==200){
								if(!this.edit_id){
									this.edit_id = res.data.id
									this.form.number = res.data.number
								}else{
									this.hideMarketForm()
								}
								this.$emit('updateList',true)
								eltips(res.msg,'success')
							}else{
								eltips(res.msg,'error')
							}
						})
				
					} else {
						this.$nextTick(()=>{
							document.getElementsByClassName("is-error")[0].scrollIntoView({
								behavior: "smooth",
								block: "center"
							});
						})
					}
				});
			},
			pickAddress(e){
				if(e){
					this.form.province = e.obj.province.label
					this.form.city = e.obj.city.label
					this.form.area = e.obj.area.label
					this.$refs.addrefs.clearValidate('pickaddress')
				}else{
					this.form.province = ''
					this.form.city = ''
					this.form.area = ''
				}
			},
			//关闭组件
			hideMarketForm(){
				this.$emit('update:addhide',false)
			},
		}
	};
</script>

<style>
	#addCustomer .addbox .el-form-item .el-form-item__label{
		line-height: 32px;
	}
</style>
<style lang="less" scoped>
	.body{
		background: #FFFFFF;
		min-height: calc(100vh - 70px);
	}
	
	.databox{
		padding: 14px;
		.titleStyle{
			padding: 10px 16px;
			margin-bottom: 12px;
			color: #1989fa;
			background: #f8f8f8;
		}
		.tips{
			font-size: 14px;
			color: #999;
		}
	}
	
	.btnbox{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 18px;
	}
	
	.uploadbox{
		display: flex;
		flex-wrap: wrap;
		.imgbox{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid #DCDFE6;
			width: 120px;
			height:120px;
			margin-right: 8px;
			margin-bottom: 8px;
			position: relative;
			.img{
				width: 100%;
				height: 100%;
			}
			.addIcon{
				font-size: 34px;
				line-height: 1;
				color: #DCDFE6;
			}
			.addtips{
				font-size: 12px;
				color: #DCDFE6;
				margin-top: 8px;
				line-height: 1;
			}
			.delicon {
				display: block;
				font-size: 20px;
				position: absolute;
				right: -8px;
				top: -8px;
			}
		}
	}
</style>