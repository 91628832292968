import axios from 'axios'

// 获取全国JSON
export const getChinaJson = (params) => axios.get(`/map/china.json`, params);

// 获取省JSON
export const getProvinceJson = (path,params) => axios.get(`/map/province/${path}.json`, params);

// 获取市JSON
export const getCityJson = (path,params) => axios.get(`/map/city/${path}.json`, params);

// 获取县JSON
export const getDistrictJson = (path,params) => axios.get(`/map/county/${path}.json`, params);


