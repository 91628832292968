<template>
	<div id="marketExamineCustomerList">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="客户名称:">
					 <el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="联系人:">
					 <el-input size="small " v-model="contacts" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					 <el-input size="small " v-model="contacts_phone" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column label="编号" :prop="type!=1?'main_customer.number':'number'" align="center" width="140"/>
				<el-table-column label="客户全称" :prop="type!=1?'main_customer.name':'name'" align="center" width="200"/>
				<el-table-column label="地区" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="type==1">
							<div v-if="scope.row.province">{{scope.row.province+scope.row.city+scope.row.area}}</div>
						</div>
						<div v-else>
							<div v-if="scope.row.main_customer.province">{{scope.row.main_customer.province+scope.row.main_customer.city+scope.row.main_customer.area}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="所属行业" :prop="type!=1?'main_customer.industry':'industry'" align="center" width="120"/>
				<el-table-column label="产品类型" :prop="type!=1?'main_customer.type':'type'" align="center" width="120"/>
				<el-table-column label="主要联系人" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="type==1">
							<div v-if="scope.row.contact">
								<div>姓名：{{scope.row.contact.name}}</div>
								<div>电话：{{scope.row.contact.phone_number}}</div>
								<div>职位：{{scope.row.contact.position}}</div>
							</div>
						</div>
						<div v-else>
							<div v-if="scope.row.main_customer.contact">
								<div>姓名：{{scope.row.main_customer.contact.name}}</div>
								<div>电话：{{scope.row.main_customer.contact.phone_number}}</div>
								<div>职位：{{scope.row.main_customer.contact.position}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="最后跟进" align="center" width="140">
					<template slot-scope="scope">
						<div v-if="type==1">
							<div v-if="scope.row.follow">
								{{timeDifference(scope.row.follow.created_at)}}
							</div>
						</div>
						<div v-else>
							<div v-if="scope.row.main_customer.follow">
								{{timeDifference(scope.row.main_customer.follow.created_at)}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="录入人" align="center" width="100">
					<template slot-scope="scope">
						<div v-if="type==1">
							<div>{{scope.row.create_guard=='api'?'总后台':scope.row.create_admin.username}}</div>
						</div>
						<div v-else>
							<div>{{scope.row.main_customer.create_guard=='api'?'总后台':scope.row.main_customer.create_admin.username}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="放弃销售" align="center" width="100">
					<template slot-scope="scope">
						<div v-if="type==1">
							<template v-if="scope.row.old_admin_id">
								<div>{{scope.row.old_guard=='api'?'总后台':scope.row.old_admin.username}}</div>
							</template>
						</div>
						<div v-else>
							<template v-if="scope.row.main_customer.old_admin_id">
								<div>{{scope.row.main_customer.old_guard=='api'?'总后台':scope.row.main_customer.old_admin.username}}</div>
							</template>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="放弃时间" align="center" width="160">
					<template slot-scope="scope">
						<div>{{type==1?scope.row.out_time:scope.row.main_customer.out_time}}</div>
					</template>
				</el-table-column>
				
				<el-table-column label="申请信息" align="center" width="660" fixed="right" v-if="type==1">
					<template slot-scope="scope">
						<el-table :data="scope.row.examine_list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
							<el-table-column label="申请人" prop="applicant.username" align="center" width="100"/>
							<el-table-column label="申请理由" prop="remarks" align="center"/>
							<el-table-column label="申请时间" prop="created_at" align="center" width="160"/>
							<el-table-column label="操作" align="center" width="120">
								<template slot-scope="scope">
									<el-button type="text" @click="agreeApplay(scope.row.id)">同意</el-button>
									<el-button type="text" @click="refuseApplay(scope.row.id)">拒绝</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="申请人" align="center" v-if="type!=1" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.applicant.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="申请时间" prop="created_at" align="center" v-if="type!=1" width="160"/>
				<el-table-column label="审核人" align="center" v-if="type!=1" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.approver.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="审核时间" prop="updated_at" align="center" v-if="type!=1" width="160"/>
				<el-table-column label="拒绝理由" prop="remarks" align="center" show-overflow-tooltip v-if="type==3" width="200"/>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page"
				:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle,timeDifference} from '@/util/util.js'
	export default {
		name: "marketExamineCustomerList",
		data() {
			return {
				timeDifference:timeDifference,
				name:'',
				contacts:'',
				contacts_phone:'',
				page:1,
				total:0,
				list:[],
				loading:false,
				type:'',
			};
		},
		created() {
			if(this.$route.query.props) this.type = this.$route.query.props;
			this.getList()
		},
		watch: {
			$route() {
				this.name = ''
				this.contacts = ''
				this.contacts_phone = ''
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.type = this.$route.query.props
				this.getList()
			}
		},
		methods:{
			agreeApplay(id){
				this.$confirm('此操作将分配客户给该申请人，并拒绝其他的所有申请，是否继续?', '通过审核', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
					let data = {
						examine_id:id,
						status:2
					}
				    this.axios.post('/api/main/Customer/examine_distribution',data).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			refuseApplay(id){
				this.$prompt('请输入拒绝理由', '拒绝分配', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    inputValidator(value){
				    	if(!value){
				    		return '理由不能为空'
				    	}
				    	if(value.replace(/\s/g,'').length<1){
				    		return '理由不能为空'
				    	}
				    }
				}).then(({ value }) => {
					let data = {
						examine_id:id,
						status:3,
						refuse_remarks:value
					}
				    this.axios.post('/api/main/Customer/examine_distribution',data).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					type:this.type,
					page:this.page,
					name:this.name,
					contacts:this.contacts,
					contacts_phone:this.contacts_phone
				}
				this.loading = true
				this.axios.get('/api/main/Customer/examine_list',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#marketExamineCustomerList{
		min-height: calc(100vh - 70px);
	}
	
	#marketExamineCustomerList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>