<template>
	<div id="oaMarketMyApplicationPrivate">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="客户名称:">
					 <el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="联系人:">
					 <el-input size="small " v-model="contacts" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					 <el-input size="small " v-model="contacts_phone" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox" v-loading="loading">
			<el-tabs v-model="status" @tab-click="changepage(1)">
				<el-tab-pane label="待审核" name="1"></el-tab-pane>
				<el-tab-pane label="已通过" name="2"></el-tab-pane>
				<el-tab-pane label="已拒绝" name="3"></el-tab-pane>
			</el-tabs>
			
			<el-table :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column label="编号" prop="main_customer.number" align="center" width="140"/>
				<el-table-column label="客户全称" prop="main_customer.name" align="center" width="200"/>
				<el-table-column label="地区" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.province">{{scope.row.main_customer.province+scope.row.main_customer.city+scope.row.main_customer.area}}</div>
					</template>
				</el-table-column>
				<el-table-column label="所属行业" prop="main_customer.industry" align="center" width="120"/>
				<el-table-column label="产品类型" prop="main_customer.type" align="center" width="120"/>
				<el-table-column label="主要联系人" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.contact">
							<div>姓名：{{scope.row.main_customer.contact.name}}</div>
							<div>电话：{{scope.row.main_customer.contact.phone_number}}</div>
							<div>职位：{{scope.row.main_customer.contact.position}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="最后跟进" align="center" width="140">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.follow">
							{{timeDifference(scope.row.main_customer.follow.created_at)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="录入人" align="center" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.main_customer.create_guard=='api'?'总后台':scope.row.main_customer.create_admin.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="放弃销售" align="center" width="100">
					<template slot-scope="scope">
						<template v-if="scope.row.main_customer.old_admin_id">
							<div>{{scope.row.main_customer.old_guard=='api'?'总后台':scope.row.main_customer.old_admin.username}}</div>
						</template>
					</template>
				</el-table-column>
				<el-table-column label="放弃时间" align="center" width="160">
					<template slot-scope="scope">
						<div>{{scope.row.main_customer.out_time}}</div>
					</template>
				</el-table-column>
				<el-table-column label="申请人" align="center" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.apply_guard=='api'">总后台</div>
						<div v-else>{{scope.row.applicant.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="申请时间" prop="created_at" align="center" width="160"/>
				
				<el-table-column label="审核人" align="center" v-if="status!=1" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.examine_guard=='api'">总后台</div>
						<div v-else>{{scope.row.approver.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="审核时间" prop="updated_at" align="center" v-if="status!=1" width="160"/>
				<el-table-column label="拒绝理由" prop="remarks" align="center" show-overflow-tooltip v-if="status==3" width="200"/>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page"
				:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle,timeDifference} from '@/util/util.js'
	export default {
		name: "oaMarketMyApplicationPrivate",
		data() {
			return {
				timeDifference:timeDifference,
				name:'',
				contacts:'',
				contacts_phone:'',
				loading:false,
				total:0,
				page:1,
				list:[],
				status:'1'
			};
		},
		created() {
			this.getList()
		},
		methods:{
			changepage(e){
				this.page = e
				this.list = []
				this.getList()
			},
			getList(){
				this.loading = true
				let data = {
					page:this.page,
					status:this.status,
				}
				this.axios.get('/api/main/Customer/examine_log',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#oaMarketMyApplicationPrivate{
		min-height: calc(100vh - 70px);
	}
	
	#oaMarketMyApplicationPrivate .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>