<template>
	<div id="marketList">
		<div v-show="!addShow&&!detailShow">
			<div class="headbox">
				<el-form :inline="true">
					<el-form-item label="客户全称:">
						<el-input size="small " v-model="name" placeholder="请输入客户全称" clearable style="width: 150px;"></el-input>
					</el-form-item>
					<el-form-item label="客户名称:">
						<el-input size="small " v-model="short_name" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
					</el-form-item>
					<el-form-item label="统一社会信用码:">
						<el-input size="small " v-model="credit_code" placeholder="请输入统一社会信用码" clearable
							style="width: 180px;"></el-input>
					</el-form-item>
					<el-form-item label="产品类型:">
						<el-select size="small" style="width: 140px;" clearable v-model="type" placeholder="请选择">
							<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name" />
						</el-select>
					</el-form-item>
					<el-form-item label="客户评级:">
						<el-select size="small" style="width: 140px;" clearable v-model="level" placeholder="请选择">
							<el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.name" />
						</el-select>
					</el-form-item>
					<el-form-item label="客户来源:">
						<el-select size="small" style="width: 140px;" clearable v-model="from" placeholder="请选择">
							<el-option v-for="item in fromList" :key="item.id" :label="item.name" :value="item.name" />
						</el-select>
					</el-form-item>
					<el-form-item label="销售阶段:">
						<el-select size="small" style="width: 140px;" clearable v-model="sale_stage" placeholder="请选择">
							<el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name" />
						</el-select>
					</el-form-item>
					<el-form-item label="所属行业:">
						<el-select size="small" style="width: 140px;" clearable v-model="industry" placeholder="请选择">
							<el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.name" />
						</el-select>
					</el-form-item>
					<el-form-item label="归属人:" v-if="select_type==1">
						<el-select size="small" filterable style="width: 140px;" clearable v-model="admin_id"
							placeholder="请选择">
							<el-option v-for="item in adminList" :key="item.id" :label="item.username"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="添加时间:">
						<el-date-picker style="width: 270px;" size="small" v-model="time" type="daterange"
							value-format="yyyy-MM-dd" format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
						<el-button type="primary" size="small" @click="openAdd()" v-if="select_type==1" :disabled="guard_name=='api'">添加客户</el-button>
					</el-form-item>
				</el-form>
			</div>

			<div class="tablebox">
				<el-alert v-if="select_type==1" title="发起报单前,必须补充登录账号,未补充的客户不允许发起报单" type="warning" show-icon :closable="false" style="margin-bottom: 12px;"/>
				<el-alert v-if="select_type==3" title="客户初始密码默认为:User + '登录账号后4位' " type="warning" show-icon :closable="false" style="margin-bottom: 12px;"/>
				<el-alert v-if="select_type==4" title="客户剩余时长不足30天" type="warning" show-icon :closable="false" style="margin-bottom: 12px;"/>
				<el-alert v-if="select_type==5" title="客户已到期" type="warning" show-icon :closable="false" style="margin-bottom: 12px;"/>
				<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="编号" prop="number" align="center" width="140" />
					<el-table-column label="客户全称" prop="name" align="center" width="200" />
					<el-table-column label="客户名称" prop="short_name" align="center" width="120" />
					<el-table-column label="登录账号/手机号" prop="phone" align="center" width="160" />
					<el-table-column label="到期时间" prop="expiration_time" align="center" width="160"  v-if="select_type==3||select_type==4||select_type==5"/>
					<el-table-column label="安全发手续费" align="center" width="180" v-if="select_type==3||select_type==4||select_type==5">
						<template slot-scope="scope">
							<!-- <div>发放金额：{{scope.row.claim_amount}}</div> -->
							<div>已结算：{{scope.row.settle_amount}}</div>
							<div>待结算：<span :style="{'color':scope.row.unsettle_amount>0?'#F00':''}">{{scope.row.unsettle_amount}}</span></div>
						</template>
					</el-table-column>
					<el-table-column label="短信用量(条)" prop="Sum_short_send_count" align="center" width="120" v-if="select_type==3||select_type==4||select_type==5"/>
					<el-table-column label="人脸识别(次)" prop="Sum_baidu_face_count" align="center" width="120" v-if="select_type==3||select_type==4||select_type==5"/>
					<el-table-column label="身份证二要素(次)" prop="Sum_auth_card_count" align="center" width="140" v-if="select_type==3||select_type==4||select_type==5"/>
					<el-table-column label="资源云储存" prop="Sum_qiniuyun_count" align="center" width="120" v-if="select_type==3||select_type==4||select_type==5">
						<template slot-scope="scope">
							<div>{{calcSize(scope.row.Sum_qiniuyun_count)}}</div>
						</template>
					</el-table-column>
					<el-table-column label="电子合同(份)" prop="Sum_esign_count" align="center" width="120" v-if="select_type==3||select_type==4||select_type==5">
						<template slot-scope="scope">
							<div v-if="scope.row.user">
								{{scope.row.Sum_esign_count}} / {{scope.row.Sum_esign_count+scope.row.user.contracts_count}}
							</div>
							<div v-else>{{scope.row.Sum_esign_count}}</div>
						</template>
					</el-table-column>
					<el-table-column label="地区" align="center" width="200">
						<template slot-scope="scope">
							<div v-if="scope.row.province"> {{scope.row.province+scope.row.city+scope.row.area}} </div>
						</template>
					</el-table-column>
					<el-table-column label="所属行业" prop="industry" align="center" width="100" />
					<el-table-column label="产品类型" prop="type" align="center" width="100" />
					<el-table-column label="销售阶段" prop="sale_stage" align="center" width="100" />
					<el-table-column label="客户来源" prop="from" align="center" width="100" />
					<el-table-column label="主要联系人" align="center" width="200">
						<template slot-scope="scope">
							<div v-if="scope.row.contact">
								<div>姓名：{{scope.row.contact.name}}</div>
								<div>电话：{{scope.row.contact.phone_number}}</div>
								<div>职位：{{scope.row.contact.position}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="`最后${select_type==1||select_type==2?'跟进':'回访'}`" align="center" width="140" v-if="select_type!=6">
						<template slot-scope="scope">
							<div v-if="scope.row.follow">
								{{timeDifference(scope.row.follow.created_at)}}
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="`${select_type==1||select_type==2?'跟进':'回访'}记录`" align="center" width="300" v-if="select_type!=6">
						<template slot-scope="scope">
							<div class="follows" v-if="scope.row.follow">{{scope.row.follow.content}}</div>
						</template>
					</el-table-column>

					<el-table-column label="放弃销售" align="center" width="100" v-if="select_type==2">
						<template slot-scope="scope">
							<template v-if="scope.row.old_admin_id">
								<div>{{scope.row.old_guard=='api'?'总后台':scope.row.old_admin.username}}</div>
							</template>
						</template>
					</el-table-column>
					<el-table-column label="录入人" align="center" width="100" v-if="select_type==2">
						<template slot-scope="scope">
							<div>{{scope.row.create_guard=='api'?'总后台':scope.row.create_admin.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="录入时间" align="center" width="120" v-if="select_type==2">
						<template slot-scope="scope">
							<div>{{setTimeStyle(scope.row.created_at)}}</div>
						</template>
					</el-table-column>

					<el-table-column label="归属人" align="center" width="100" v-if="select_type==1">
						<template slot-scope="scope">
							<div>{{scope.row.guard=='api'?'总后台':scope.row.admin.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="转入时间" align="center" width="100" v-if="select_type==1">
						<template slot-scope="scope">
							<div>{{setTimeStyle(scope.row.enter_time||scope.row.created_at)}}</div>
						</template>
					</el-table-column>

					<el-table-column label="成交人" align="center" width="100" v-if="select_type==3||select_type==4||select_type==5">
						<template slot-scope="scope">
							<div>{{scope.row.guard=='api'?'总后台':scope.row.admin.username}}</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column label="禁用理由" align="center" width="200" v-if="select_type==6">
						<template slot-scope="scope">
							<div>{{scope.row.blacklists.remarks}}</div>
						</template>
					</el-table-column>
					
					<el-table-column label="申请人" align="center" width="100" v-if="select_type==6">
						<template slot-scope="scope">
							<div>{{scope.row.blacklists.apply_guard=='api'?'总后台':scope.row.blacklists.applicant.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="申请时间" align="center" width="160" v-if="select_type==6">
						<template slot-scope="scope">
							<div>{{scope.row.blacklists.created_at}}</div>
						</template>
					</el-table-column>
					
					<el-table-column label="审核人" align="center" width="100" v-if="select_type==6">
						<template slot-scope="scope">
							<div>{{scope.row.blacklists.examine_guard=='api'?'总后台':scope.row.blacklists.approver.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="审核时间" align="center" width="160" v-if="select_type==6">
						<template slot-scope="scope">
							<div>{{scope.row.blacklists.updated_at}}</div>
						</template>
					</el-table-column> -->

					<el-table-column label="操作" align="center" width="280" fixed="right">
						<template slot-scope="scope">
							<el-button type="text" v-if="select_type==3||select_type==4||select_type==5" @click="addFollow(scope.row.id,2)">回访</el-button>
							<el-button type="text" v-if="select_type!=3&&select_type!=4&&select_type!=5&&select_type!=6" @click="addFollow(scope.row.id,1)">跟进</el-button>
							<el-button type="text" v-if="select_type==2" :disabled="guard_name=='api'" @click="applyCustomer(scope.row.id)">转私</el-button>
							<el-button type="text" v-if="select_type==1" :disabled="guard_name=='api'" @click="giveupCustomer(scope.row.id)">放弃</el-button>
							<el-button type="text" v-if="select_type==1" :disabled="guard_name=='api'" @click="openShareBox(scope.row)">分享客户</el-button>
							<el-button type="text" v-if="select_type==1||select_type==3||select_type==4||select_type==5" @click="openDeal(scope.row.id,scope.row.user)">申请报单</el-button>
							<el-button type="text" v-if="select_type==3||select_type==4||select_type==5" @click="openDisAfterSales(scope.row.id)">分配售后人员</el-button>
							<!-- <el-button type="text" v-if="select_type==3||select_type==4||select_type==5" @click="openDisAccount(scope.row.user.id)">分配权限人员</el-button> -->
							<el-button type="text" v-if="select_type==3||select_type==4||select_type==5" @click="openSettlement(scope.row)">手续费结算</el-button>
							<el-button type="text" v-if="select_type==3||select_type==4||select_type==5" @click="copylink(scope.row.shop_id)">复制登录地址</el-button>
							<el-button type="text" @click="openEdit(scope.row)">编辑</el-button>
							<!-- <el-button type="text">操作记录</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="pages" v-if="total>10">
				<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
					layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
		</div>


		<el-dialog title="分享客户" width="600px" :visible.sync="shareShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false">
			<div style="margin-bottom: 18px;">
				<div style="margin-bottom: 18px;">请选择分享的账号：</div>
				<el-select size="small" style="width: 100%;" filterable clearable v-model="share_admin_id" placeholder="请选择账号">
					<el-option v-for="item in shareList" :key="item.id" :label="item.username" :value="item.id" />
				</el-select>
			</div>

			<div style="text-align: center;">
				<el-button @click="shareShow = false">取消</el-button>
				<el-button type="primary" @click="shareCustomer()">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog :title="`添加${follow.type==1?'跟进':'回访'}`" width="800px" :visible.sync="followShow"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div v-loading="saveLoading">
				<div class="followBox">
					<div class="box" v-if="follow.type!=3">
						<div class="menu">历史{{follow.type==1?'跟进':'回访'}}</div>
						<div class="content listbox" v-loading="followLoading">
							<div class="nodata" v-if="followList.length==0">暂无记录</div>
							<div class="list" v-for="(item,index) in followList" :key="index">
								<span><span style="color: #1989fa;">时间：</span>{{setTimeStyle(item.created_at)}}</span>
								<span><span style="color: #1989fa;">{{item.type==1?'跟进':'回访'}}人：</span>{{item.guard=='api'?'总后台':item.admin.username}}</span>
								<span><span style="color: #1989fa;">联系人：</span>{{item.contact?item.contact.name:'-'}}</span>
								<span><span style="color: #1989fa;">{{item.type==1?'跟进':'回访'}}内容：</span>{{item.content}}</span>
							</div>
						</div>
					</div>

					<div class="box" v-if="follow.type!=3">
						<div class="menu"><span style="color: #F00;">*{{follow.type==1?'销售阶段':'客户评级'}}</span></div>
						<div class="content" v-if="follow.type==1">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.sale_stage" placeholder="请选择">
								<el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name" />
							</el-select>
						</div>
						<div class="content" v-if="follow.type==2">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.level" placeholder="请选择">
								<el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.name" />
							</el-select>
						</div>
						<div class="menu"><span style="color: #F00;">*联系人</span></div>
						<div class="content" style="flex: 1.5;">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.contact_id" placeholder="请选择">
								<el-option v-for="item in followContactList" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</div>
					</div>

					<div class="box" v-if="follow.type!=3">
						<div class="menu">下次{{follow.type==1?'跟进':'回访'}}时间</div>
						<div class="content">
							<el-date-picker style="width: 100%;" size="small" v-model="follow.next_contact_date"
								type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
							</el-date-picker>
						</div>
						<div class="menu">预警内容</div>
						<div class="content" style="flex: 1.5;">
							<el-input type="textarea" v-model="follow.warning_content" :rows="2" resize="none"
								placeholder="请输入"></el-input>
						</div>
					</div>

					<div class="box" v-if="follow.type!=3">
						<div class="menu"><span style="color: #F00;">*详情备注</span></div>
						<div class="content">
							<el-input type="textarea" v-model="follow.content" :rows="5" resize="none"
								placeholder="请输入"></el-input>
						</div>
					</div>
				</div>

				<div style="text-align: center;margin-top: 18px;">
					<el-button @click="followShow = false">取消</el-button>
					<el-button type="primary" @click="saveFollow()">确 定</el-button>
				</div>
			</div>
		</el-dialog>

		<addCustomer v-if="addShow" :addhide.sync="addShow" :customerId="customerId" :customeForm="customeForm" @updateList="changepage(1)" />
		<customerDetail v-if="detailShow" :addhide.sync="detailShow" :customeForm="customeForm" />
	
		<el-dialog title="申请报单" width="600px" v-if="dealShow" :visible.sync="dealShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false">
			<div v-loading="dealLoading">
				<el-form :model="dealForm" :rules="rules" ref="dealForm" label-width="90px">
					<el-form-item label="客户类型:" prop="is_create_account">
						<el-select size="small" style="width: 100%;" :disabled="disableAccount" v-model="dealForm.is_create_account" placeholder="请选择">
							<el-option label="saas系统客户" :value="1"></el-option>
							<el-option label="定制客户" :value="0"></el-option>
						</el-select>
						<div style="line-height: 1.5em;font-size: 14px;color: #999;" v-if="!disableAccount">定制客户将不会生成saas系统账号，报单成功后客户类型不可更改</div>
					</el-form-item>
					<template v-if="dealForm.is_create_account!==''">
						<el-form-item label="订单类型:" prop="submit_type" v-if="dealForm.is_create_account===0">
							<el-select size="small" style="width: 100%;" v-model="dealForm.submit_type" placeholder="请选择">
								<el-option v-for="item in orderList" :key="item.id" :label="item.name" :value="item.name" />
							</el-select>
						</el-form-item>
						<el-form-item label="时长调整:" prop="duration">
							<el-input size="small" type="number" placeholder="请输入增加时长" v-model="dealForm.duration">
							    <el-select size="small" style="width: 70px;" v-model="dealForm.duration_type" slot="append" placeholder="请选择">
									<el-option label="年" :value="1"></el-option>
									<el-option label="月" :value="2"></el-option>
									<el-option label="日" :value="3"></el-option>
							    </el-select>
							</el-input>
						</el-form-item>
						<el-form-item label="提报金额:" prop="amount">
							<el-input size="small" type="number" placeholder="请输入提报金额" v-model="dealForm.amount">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="需求说明:" prop="remarks">
							<el-input size="small" :rows="5" type="textarea" placeholder="请详细说明提报的需求" v-model="dealForm.remarks" />
						</el-form-item>
					</template>
				</el-form>
				
				<div style="text-align: center;">
					<el-button @click="dealShow = false">取消</el-button>
					<el-button type="primary" @click="sendDeal()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	
		<el-dialog title="分配售后人员" width="600px" :visible.sync="afterSalesShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false">
			<div style="margin-bottom: 18px;">
				<div style="margin-bottom: 18px;">请选择售后人员账号：</div>
				<el-select size="small" style="width: 100%;" filterable clearable v-model="afterSaleId" placeholder="请选择账号">
					<el-option v-for="item in afterSalesList" :key="item.id" :label="item.username" :value="item.id" />
				</el-select>
			</div>
		
			<div style="text-align: center;">
				<el-button @click="afterSalesShow = false">取消</el-button>
				<el-button type="primary" @click="saveAfterSales()">确 定</el-button>
			</div>
		</el-dialog>
		
		<el-dialog title="分配权限人员" width="600px" :visible.sync="permissionShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false">
			<div style="margin-bottom: 18px;">
				<div style="margin-bottom: 18px;">请选择权限人员账号：</div>
				<el-select size="small" style="width: 100%;" filterable clearable v-model="permission_id" placeholder="请选择账号">
					<el-option v-for="item in afterSalesList" :key="item.id" :label="item.username" :value="item.id" />
				</el-select>
			</div>
		
			<div style="text-align: center;">
				<el-button @click="permissionShow = false">取消</el-button>
				<el-button type="primary" @click="saveAccount()">确 定</el-button>
			</div>
		</el-dialog>
		
		<el-dialog
			title="安全发手续费结算"
			width="1000px"
			:visible.sync="settlementShow"
			:close-on-click-modal="false"
			destroy-on-close>
			<div v-loading="settlementLoading">
				<div style="margin-bottom: 18px;">
					<el-button type="primary" size="small" @click="addSettlementShow=true">添加结算记录</el-button>
				</div>
				<el-table :key="Math.random()" :data="settlementList" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
					<el-table-column label="结算月份" prop="date" align="center"/>
					<el-table-column label="结算金额" prop="settle_amount" align="center"/>
					<el-table-column label="收款发票" prop="invoice_file" align="center">
						<template slot-scope="scope">
							<el-image style="width: 40px;height: 40px;" :src="scope.row.invoice_file" :preview-src-list="[scope.row.invoice_file]" fit="cover"></el-image>
						</template>
					</el-table-column>
					<el-table-column label="备注信息" prop="remarks" align="center"/>
					<el-table-column label="处理人员" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.guard=='api'">总后台</div>
							<div v-else>{{scope.row.admin.username}}</div>
						</template>
					</el-table-column>
					<el-table-column label="添加时间" prop="created_at" align="center" width="160"/>
					<el-table-column label="操作" align="center" width="100">
						<template slot-scope="scope">
							<el-button type="text" style="color: #F00;" @click="delSettlement(scope.row.id)">删除记录</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="text-align: center;margin-top: 18px;">
					<el-pagination background @current-change="changeSettlementPage" :current-page="settlementPage" :page-size="10"
						layout="total, prev, pager, next, jumper" :total="settlementTotal"></el-pagination>
				</div>
			</div>
			<!-- 添加结算记录 -->
			<el-dialog
				width="600px"
				title="添加结算记录"
				:visible.sync="addSettlementShow"
				:close-on-click-modal="false"
				destroy-on-close
				append-to-body>
				<div v-loading="settlementLoading">
					<el-form ref="settleForm" :model="settleForm" label-width="90px" :rules="settleRules">
						<el-form-item label="结算金额" prop="settle_amount">
							<el-input type="number" size="small" v-model="settleForm.settle_amount" placeholder="请输入结算金额"></el-input>
						</el-form-item>
						<el-form-item label="结算月份" prop="date">
							<el-date-picker 
								style="width: 100%;"
								size="small" 
								v-model="settleForm.date" 
								value-format="yyyy-MM"
								format="yyyy-MM"
								type="month" 
								placeholder="请选择">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="收款发票">
							<div class="imgbox" v-if="settleForm.invoice_file">
								<el-image class="img" :src="createFileUrl(settleForm.invoice_file)" :preview-src-list="[createFileUrl(settleForm.invoice_file)]" fit="cover"></el-image>
								<i class="el-icon-error delIcon" @click="settleForm.invoice_file=''"></i>
							</div>
							<div v-else class="imgbox upload" @click="$refs.uploadFile.click()">
								<i class="el-icon-upload"></i>
								<div style="font-size: 14px;line-height: 1;">上传收款发票</div>
								<input class="input" ref="uploadFile" accept="image/*" type="file" @change="changeUploadImage($event)" />
							</div>
						</el-form-item>
						<el-form-item label="备注">
							<el-input type="text" size="small" placeholder="请输入备注信息"></el-input>
						</el-form-item>
					</el-form>
					
					<div style="text-align: center;margin-top: 18px;">
						<el-button size="small" @click="addSettlementShow = false">取消</el-button>
						<el-button size="small" type="primary" @click="saveSettlement()">添加记录</el-button>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
	import addCustomer from './components/addCustomer.vue'
	import customerDetail from './components/customerDetail.vue'
	import dealProcess from './components/dealProcess.vue'
	import { eltips, setTimeStyle, timeDifference,httpurl,copylink,qiniuUpload } from '@/util/util.js'
	export default {
		name: "marketList",
		components: { addCustomer, customerDetail, dealProcess },
		data() {
			return {
				timeDifference: timeDifference,
				setTimeStyle: setTimeStyle,
				guard_name: JSON.parse(sessionStorage.getItem('userinfo')).guard_name,
				loading: false,
				select_type: '',
				page: 1,
				total: 0,
				list: [],
				addShow: false,
				customerId: '',
				customeForm: '',
				type: 1,
				customer_id: '',
				contractForm: '',
				detailShow: false,
				file: '',
				adminList: [],
				fromList: [],
				levelList: [],
				typeList: [],
				industryList: [],
				stageList: [],
				name: '',
				short_name:'',
				credit_code: '',
				type: '',
				level: '',
				from: '',
				sale_stage: '',
				industry: '',
				time: [],
				admin_id: '',
				shareList: [],
				share_admin_id: '',
				shareShow: false,
				followShow: false,
				followLoading: false,
				saveLoading: false,
				follow: {
					type: 1, //1跟进 2回访 3售后
					content: '', //跟进内容
					contact_id: '', //联系人ID
					sale_stage: '', //销售阶段
					level:'',//客户评级
					next_contact_date: '', //下次预警时间
					warning_content: '', //预警内容
					remarks: '',
				},
				followList: [],
				followContactList: [],
				dealShow: false,
				rules:{
					is_create_account: [{ required: true, message: '请选择客户类型', trigger: 'change' }],
					submit_type: [{ required: true, message: '请选择订单类型', trigger: 'blur' }],
					duration: [{ required: true, message: '请输入增加时长', trigger: 'blur' }],
					amount: [{ required: true, message: '请输入提报金额', trigger: 'blur' }],
					remarks: [{ required: true, message: '请输入需求说明', trigger: 'blur' }],
				},
				dealForm: {
					is_create_account:'',//是否创建账号 1创建 0不创建
					submit_type:'',//订单类型
					duration:'',
					duration_type:1, //时间类型 1年 2月 3日
					customer_id:'',
					remarks:'',
					amount:'',
				},
				dealLoading:false,
				afterSalesShow:false,
				afterSalesList:[],
				afterSaleId:'',
				permissionShow:false,
				permission_id:'',
				account_id:'',
				orderList:[],
				disableAccount:false,
				settlementShow:false,
				settlementLoading:false,
				settlementList:[],
				settlementTotal:0,
				settlementPage:1,
				shopId:'',
				addSettlementShow:false,
				settleForm:{
					settle_amount:'', //结算金额
					invoice_file:'', //收款发票
					date:'', //结算月份
					remarks:'', //备注信息
				},
				settleRules:{
					settle_amount: [{ required: true, message: '请输入结算金额', trigger: 'blur' }],
					date: [{ required: true, message: '请选择结算月份', trigger: 'change' }],
				}
			};
		},
		created() {
			if (this.$route.query.props) this.select_type = this.$route.query.props;
			this.getList()
			this.getAdminList()
			this.getSeting()
			this.getSalesAdmin()
		},
		watch: {
			$route() {
				this.followShow = false
				this.addShow = false
				this.dealShow = false
				this.detailShow = false
				this.name = ''
				this.contacts = ''
				this.contacts_phone = ''
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.select_type = this.$route.query.props
				this.getList()
			}
		},
		methods: {
			saveSettlement(){
				this.$refs.settleForm.validate(async (valid) => {
				    if (!valid) return;
					this.settlementLoading = true
					let data = {
						settle_amount:this.settleForm.settle_amount,
						date:this.settleForm.date,
						remarks:this.settleForm.remarks,
						shop_id:this.shopId
					}
					if(this.settleForm.invoice_file){
						const qiniuTokenRes = await this.axios.post('/api/upload/get_qiniu_token')
						if(qiniuTokenRes.code!=200) return eltips('上传图片失败，请稍后重试'),this.settlementLoading = false;
						const userKey = `settlement-invoice-${this.shopId}-${new Date().getTime()}`
						const invoice_file = await qiniuUpload(this.settleForm.invoice_file,userKey,qiniuTokenRes.data)
						if(!invoice_file) return eltips('上传图片失败，请稍后重试'),this.settlementLoading = false;
						data.invoice_file = invoice_file
					}
					this.axios.post('/api/main/Commission/createSettlement',data).then(res=>{
						if(res.code==200){
							this.addSettlementShow = false
							eltips(res.msg,'success')
							this.getSettlementList()
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			//解析图片文件对象
			createFileUrl(file){
				const windowURL = window.URL || window.webkitURL;
				return windowURL.createObjectURL(file)
			},
			changeUploadImage(e){
				let imgsFiles = e.target.files[0]
				if(!imgsFiles.type.startsWith('image/')) return  this.$refs.uploadFile.value = '',eltips('仅支持图片格式');
				this.settleForm.invoice_file = imgsFiles
				this.$refs.uploadFile.value = ''
			},
			delSettlement(id){
				this.$confirm('此操作将删除该结算记录, 是否继续?', '删除记录', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.axios.post('/api/main/Commission/deleteSettlement',{id:id}).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getSettlementList()
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			openSettlement(row){
				this.shopId = row.shop_id
				this.settlementPage = 1
				this.settlementTotal = 0
				this.settlementList = []
				this.getSettlementList()
				this.settlementShow = true
			},
			changeSettlementPage(e){
				this.settlementPage = e
				this.getSettlementList()
			},
			getSettlementList(){
				this.settlementLoading = true
				let data = {
					shop_id:this.shopId,
					page:this.settlementPage
				}
				this.axios.get('/api/main/Commission/getSettlementList',{params:data}).then(res=>{
					this.settlementLoading = false
					if(res.code==200){
						this.settlementTotal = res.data.total
						this.settlementList = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			copylink(shopid){
				let url = httpurl+'/login?shopId='+shopid
				copylink(url)
			},
			openDisAccount(id){
				this.account_id = id
				this.permission_id = ''
				this.permissionShow = true
			},
			saveAccount(){
				if(!this.permission_id) return eltips('请选择权限人员','error')
				let data = {
					admin_id:this.permission_id,
					user_id:this.account_id
				}
				this.axios.post('/api/main/User/assign_account_empower',data).then(res=>{
					if(res.code==200){
						eltips(res.msg,'success')
						this.permissionShow = false
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			saveAfterSales(){
				if(!this.afterSaleId) return eltips('请选择售后人员','error')
				let data = {
					customer_id:this.customer_id,
					admin_id:this.afterSaleId
				}
				this.axios.post('/api/main/Customrt/AssignSales',data).then(res=>{
					if(res.code==200){
						eltips(res.msg,'success')
						this.afterSalesShow = false
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getSalesAdmin(){
				let data = {
					no_page:1,
					all:1
				}
				this.axios.get('/api/main/Admin/accountList',{params:data}).then(res=>{
					if(res.code==200){
						this.afterSalesList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			openDisAfterSales(id){
				this.customer_id = id
				this.afterSaleId = ''
				this.afterSalesShow = true
			},
			calcSize(size){
				if(size < 1024){
					return size+'K'
				}else if(size < 1024*1024){
					return parseFloat((size/1024).toFixed(2)) +'M'
				}else if(size < 1024*1024*1024){
					return parseFloat((size/(1024*1024)).toFixed(2)) +'G'
				}else{
					return parseFloat((size/(1024*1024*1024)).toFixed(2)) +'T'
				}
			},
			sendDeal(){
				this.$refs['dealForm'].validate((valid) => {
					if (!valid) return; 
					this.dealLoading = true
					let data = {
						is_create_account:this.dealForm.is_create_account,
						customer_id:this.dealForm.customer_id,
						remarks:this.dealForm.remarks,
						amount:this.dealForm.amount,
						duration:this.dealForm.duration,
						duration_type:this.dealForm.duration_type
					}
					if(data.is_create_account===0){
						data.submit_type = this.dealForm.submit_type
					}
					
					this.axios.post('/api/main/CustomerDeal/create',data).then(res=>{
						this.dealLoading = false
						if(res.code==200){
							this.dealShow = false
							eltips(res.msg,'success')
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openShareBox(item) {
				this.customer_id = item.id
				this.share_admin_id = ''
				this.shareShow = true
			},
			shareCustomer() {
				if (!this.share_admin_id) return eltips('请选择账号', 'error')
				const data = {
					customer_id: this.customer_id,
					share_admin_id: this.share_admin_id,
				}
				this.axios.post('/api/main/Customer/share', data).then(res => {
					if(res.code==200){
						eltips(res.msg,'success')
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getSeting() {
				this.axios.get('/api/main/Customer/TLF_list').then(res => {
					if (res.code == 200) {
						this.fromList = res.data.from
						this.levelList = res.data.level
						this.typeList = res.data.type
						this.industryList = res.data.industry
						this.stageList = res.data.sale_stage
						this.orderList = res.data.order_type
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getAdminList() {
				this.axios.get('/api/main/Customer/member', { params: { type: 1 } }).then(res => {
					if (res.code == 200) {
						this.adminList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
				this.axios.get('/api/main/Customer/member', { params: { type: 2 } }).then(res => {
					if (res.code == 200) {
						this.shareList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			openDetail(item) {
				this.customeForm = item
				this.detailShow = true
			},
			openDeal(id,user) {
				this.disableAccount = false
				this.dealForm = {
					is_create_account:'',
					submit_type:'',
					duration:'',
					duration_type:1, //时间类型 1年 2月 3日
					customer_id:id,
					remarks:'',
					amount:'',
				}
				if(this.select_type!=1){
					this.disableAccount = true
					if(!user){
						this.dealForm.is_create_account = 0
					}else{
						this.dealForm.is_create_account = 1
					}
				}
				this.dealShow = true;
			},
			//添加跟进记录
			addFollow(id, type) {
				this.customer_id = id
				this.follow = {
					type: type, //1跟进 2回访 3售后
					content: '', //跟进内容
					contact_id: '', //联系人ID
					sale_stage: '', //销售阶段
					level:'',
					next_contact_date: '', //下次预警时间
					warning_content: '', //预警内容
					remarks: ''
				}
				this.followList = []
				this.followContactList = []
				this.getFollowList()
				this.getFollowContactList()
				this.followShow = true
			},
			saveFollow() {
				if (this.follow.type == 1 && !this.follow.sale_stage) return eltips('请选择销售阶段', 'error');
				if (this.follow.type == 2 && !this.follow.level) return eltips('请选择客户评级', 'error');
				
				if (!this.follow.contact_id) return eltips('请选择联系人', 'error');
				
				if (this.follow.next_contact_date && !this.follow.warning_content) return eltips('请输入预警内容', 'error');
				if (!this.follow.next_contact_date && this.follow.warning_content) return eltips('请选择预警时间', 'error');
				
				if (!this.follow.content) return eltips('请输入详情备注', 'error');

				let data = JSON.parse(JSON.stringify(this.follow));
				data.customer_id = this.customer_id
				this.saveLoading = true
				this.axios.post('/api/main/Customer/createFollow', data).then(res => {
					this.saveLoading = false
					if (res.code == 200) {
						this.getList()
						this.followShow = false
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getFollowContactList() {
				let data = { customer_id: this.customer_id }
				this.axios.get('/api/main/Customer/contact_list', { params: data }).then(res => {
					if (res.code == 200) {
						this.followContactList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getFollowList() {
				let data = {
					customer_id: this.customer_id,
					type: this.follow.type,
					no_page: 1
				}
				this.followLoading = true
				this.axios.get('/api/main/Customer/followList', {
					params: data
				}).then(res => {
					this.followLoading = false
					if (res.code == 200) {
						this.followList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//放弃客户
			giveupCustomer(id) {
				this.$prompt('请输入放弃理由', '放弃客户', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if (!value) return '理由不能为空'
						if (value.replace(/\s/g, '').length < 1) return '理由不能为空'
					}
				}).then(({ value }) => {
					let data = { customer_id: id, give_up_remarks: value }
					this.axios.post('/api/main/Customer/turn_public', data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.getList()
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//申请分配
			applyCustomer(id) {
				this.$prompt('请输入申请理由', '申请分配', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if (!value) return '理由不能为空'
						if (value.replace(/\s/g, '').length < 1) return '理由不能为空'
					}
				}).then(({ value }) => {
					let data = { customer_id: id, remarks: value }
					this.axios.post('/api/main/Customer/apply_distribution', data).then(res => {
						if (res.code == 200) {
							this.getList()
							eltips(res.msg, 'success')
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//新建客户
			openAdd() {
				this.customerId = ''
				this.customeForm = ''
				this.addShow = true
			},
			//编辑客户
			openEdit(item) {
				this.customerId = item.id
				this.customeForm = item
				this.addShow = true
			},
			//获取列表
			changepage(e) {
				this.page = e
				this.getList()
			},
			getList() {
				let data = {
					page: this.page,
					select_type: this.select_type,
					name: this.name,
					short_name:this.short_name,
					credit_code: this.credit_code,
					type: this.type,
					level: this.level,
					from: this.from,
					sale_stage: this.sale_stage,
					industry: this.industry,
					admin_id: this.admin_id
				}
				if (this.time && this.time.length == 2) {
					data.created_at_st = this.time[0]
					data.created_at_ed = this.time[1]
				}
				this.loading = true
				this.axios.get('/api/main/Customer/list', {
					params: data
				}).then(res => {
					this.loading = false
					if (res.code == 200) {
						this.total = res.data.total
						this.list = res.data.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			}
		}
	};
</script>


<style lang="less" scoped>
	#marketList {
		min-height: calc(100vh - 70px);
	}

	#marketList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}

	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}

	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.follows {
			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}

	.followBox {
		border: 1px solid #e3e3e3;

		.box {
			display: flex;
			align-items: stretch;
			border-bottom: 1px solid #e3e3e3;

			&:last-child {
				border-bottom: none;
			}

			.menu {
				flex: 0 0 100px;
				background: #F3F3F3;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #e3e3e3;
			}

			.content {
				flex: 1;
				padding: 12px;
				border-right: 1px solid #e3e3e3;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}

			.listbox {
				min-height: 60px;
				max-height: 160px;
				overflow-y: auto;
				display: block;

				.nodata {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					color: #999;
				}

				.list {
					margin-bottom: 14px;

					&:last-child {
						margin-bottom: 0;
					}

					span {
						margin-right: 14px;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	
	.imgbox{
		width: 120px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.img{
			width: 100%;
			height: 100%;
		}
		.delIcon{
			font-size: 20px;
			position: absolute;
			top: -6px;
			right: -6px;
			color: #ff4444;
			cursor: pointer;
		}
	}
	.upload{
		border: 1px dashed #1989fa;
		font-size: 30px;
		color: #1989fa;
		flex-direction: column;
		cursor: pointer;
		.input{
			width: 0;
			height: 0;
			opacity: 0;
		}
	}
</style>