<template>
	<div id="index">
		<div>页面待开发</div>
	</div>
</template>

<script>
	import { eltips } from '@/util/util.js'
	export default {
		name: 'index',
		data() {
			return {
				
			};
		},
		async created() {
			console.log(1)
			let res =  this.axios.get('/api/main/Customer/list')
			console.log('res---------',res)
			console.log(2)
		},
		methods: {
			
		},
	}
</script>

<style lang="less" scoped>
	#index {
		min-height: calc(100vh - 70px);
	}
	
</style>
