<template>
	<div id="afterSalesList">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="客户名称:">
					<el-input size="small " v-model="name" placeholder="请输入客户名称" clearable
						style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="产品类型:">
					<el-select size="small" style="width: 140px;" clearable v-model="type" placeholder="请选择">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="客户评级:">
					<el-select size="small" style="width: 140px;" clearable v-model="level" placeholder="请选择">
						<el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="客户来源:">
					<el-select size="small" style="width: 140px;" clearable v-model="from" placeholder="请选择">
						<el-option v-for="item in fromList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="销售阶段:">
					<el-select size="small" style="width: 140px;" clearable v-model="sale_stage" placeholder="请选择">
						<el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="所属行业:">
					<el-select size="small" style="width: 140px;" clearable v-model="industry" placeholder="请选择">
						<el-option v-for="item in industryList" :key="item.id" :label="item.name"
							:value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="编号" prop="number" align="center" width="140" />
				<el-table-column label="客户全称" prop="name" align="center" width="200" />
				<el-table-column label="登录账号/手机号" prop="phone" align="center" width="160" />
				<el-table-column label="到期时间" prop="expiration_time" align="center" width="160"/>
				<el-table-column label="地区" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.province"> {{scope.row.province+scope.row.city+scope.row.area}} </div>
					</template>
				</el-table-column>
				<el-table-column label="所属行业" prop="industry" align="center" width="100" />
				<el-table-column label="产品类型" prop="type" align="center" width="100" />
				<el-table-column label="销售阶段" prop="sale_stage" align="center" width="100" />
				<el-table-column label="客户来源" prop="from" align="center" width="100" />
				<el-table-column label="主要联系人" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.contact">
							<div>姓名：{{scope.row.contact.name}}</div>
							<div>电话：{{scope.row.contact.phone_number}}</div>
							<div>职位：{{scope.row.contact.position}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="成交人" align="center" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.admin.username}}</div>
					</template>
				</el-table-column>
		
				<el-table-column label="操作" align="center" width="120" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" @click="addAfterSales(scope.row.id)">添加售后</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "afterSalesList",
		data() {
			return {
				name:'',
				credit_code:'',
				type:'',
				level:'',
				from:'',
				sale_stage:'',
				industry:'',
				typeList:[],
				levelList:[],
				fromList:[],
				stageList:[],
				industryList:[],
				loading:false,
				total:0,
				page:1,
				list:[],
			};
		},
		created() {
			this.getList()
		},
		methods:{
			//添加售后
			addAfterSales(id){
				this.$prompt('请输入记录内容', '添加售后记录', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
					inputType:'textarea',
					inputValidator(value) {
						if (!value) return '提交的内容不能为空'
						if (value.replace(/\s/g, '').length < 1) return '提交的内容不能为空'
					}
				}).then(({ value }) => {
					let data = {
						customer_id:id,
						content:value
					}
					this.loading = true
				    this.axios.post('/api/main/Customrt/addSaleLog',data).then(res=>{
						this.loading = false
						if(res.code==200){
							eltips(res.msg,'success')
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			//获取列表
			changepage(e) {
				this.page = e
				this.getList()
			},
			getList() {
				let data = {
					page: this.page,
					name: this.name,
					credit_code: this.credit_code,
					type: this.type,
					level: this.level,
					from: this.from,
					sale_stage: this.sale_stage,
					industry: this.industry,
				}
				this.loading = true
				this.axios.get('/api/main/Customrt/sale_list', { params: data }).then(res => {
					this.loading = false
					if (res.code == 200) {
						this.total = res.data.total
						this.list = res.data.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#afterSalesList {
		min-height: calc(100vh - 70px);
	}
	
	#afterSalesList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.follows {
			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>