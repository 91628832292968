<template>
	<div id="customerDetail" class="body">
		<div style="background: #FFFFFF;padding: 10px;">
			<el-page-header @back="hideMarketForm">
				<div slot="content">
					<div style="font-size: 15px;line-height: 22px;color: #1989fa;">客户详情</div>
				</div>
			</el-page-header>
		</div>
		
		<div class="addbox databox" v-if="customeForm">
			<div class="titleStyle">客户信息</div>
			
			<div class="formbox">
				<div class="flexbox">
					<div class="label">客户全称：</div>
					<div class="value">{{customeForm.name}}</div>
				</div>
				<div class="flexbox">
					<div class="label">营业执照编号：</div>
					<div class="value">{{customeForm.credit_code}}</div>
				</div>
				<div class="flexbox">
					<div class="label">所在行业：</div>
					<div class="value">{{customeForm.industry}}</div>
				</div>
			</div>
			
			<div class="formbox">
				<div class="flexbox">
					<div class="label">注册日期：</div>
					<div class="value">{{customeForm.register_time}}</div>
				</div>
				<div class="flexbox">
					<div class="label">注册资金：</div>
					<div class="value">{{customeForm.registered_capital}}</div>
				</div>
				<div class="flexbox">
					<div class="label">所在地区：</div>
					<div class="value">{{customeForm.province+customeForm.city+customeForm.area}}</div>
				</div>
			</div>
			
			<div class="formbox">
				<div class="flexbox">
					<div class="label">客户评级：</div>
					<div class="value">{{customeForm.level}}</div>
				</div>
				<div class="flexbox">
					<div class="label">产品类型：</div>
					<div class="value">{{customeForm.type}}</div>
				</div>
				<div class="flexbox">
					<div class="label">客户来源：</div>
					<div class="value">{{customeForm.from}}</div>
				</div>
			</div>
			
			<div class="titleStyle">主要联系人</div>
			<div class="formbox" style="color: #888;" v-if="!customeForm.contact">
				尚未添加主要联系人
			</div>
			<div class="formbox" v-else>
				<div class="flexbox">
					<div class="label">联系人姓名：</div>
					<div class="value">{{customeForm.contact.name}}</div>
				</div>
				<div class="flexbox">
					<div class="label">联系电话：</div>
					<div class="value">{{customeForm.contact.phone_number}}</div>
				</div>
				<div class="flexbox">
					<div class="label">联系人职位：</div>
					<div class="value">{{customeForm.contact.position}}</div>
				</div>
			</div>
		
			<div class="titleStyle">其他信息</div>
			
			<div class="formbox">
				<div class="flexbox">
					<div class="label">负责人：</div>
					<div class="value">
						<div v-if="customeForm.admin">{{customeForm.admin.username}}</div>
					</div>
				</div>
				<div class="flexbox">
					<div class="label">转入时间：</div>
					<div class="value">{{customeForm.enter_time}}</div>
				</div>
				
				<div class="flexbox">
					<div class="label">添加时间：</div>
					<div class="value">{{customeForm.created_at}}</div>
				</div>
			</div>
			
			<div class="formbox">
				<div class="flexbox">
					<div class="label">是否成交：</div>
					<div class="value" :style="{'color':customeForm.sign_status==2?'#1989fa':'#FF0000'}">{{customeForm.sign_status==2?'已成交':'未成交'}}</div>
				</div>
				<div class="flexbox" style="flex: 2;">
					<div class="label">备注信息：</div>
					<div class="value">{{customeForm.remarks}}</div>
				</div>
			</div>
			
			<div class="titleStyle">最新跟进记录</div>
			<div class="formbox">
				<div class="flexbox">
					<div class="label">跟进人：</div>
					<div class="value">
						<template v-if="customeForm.follow">
							<div v-if="customeForm.follow.guard=='api'">总后台</div>
							<div v-else>{{customeForm.follow.admin.username}}</div>
						</template>
					</div>
				</div>
				<div class="flexbox">
					<div class="label">跟进内容：</div>
					<div class="value">
						<template v-if="customeForm.follow">{{customeForm.follow.content}}</template>
					</div>
				</div>
				<div class="flexbox">
					<div class="label">跟进时间：</div>
					<div class="value">
						<template v-if="customeForm.follow">{{customeForm.follow.created_at}}</template>
					</div>
				</div>
			</div>
			
			<div v-if="customeForm.sign_status==2">
				<div class="titleStyle">成交信息</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">合同名称：</div>
						<div class="value">{{customeForm.contracts.name}}</div>
					</div>
					<div class="flexbox">
						<div class="label">合同类型：</div>
						<div class="value">{{customeForm.contracts.contract_type}}</div>
					</div>
					<div class="flexbox">
						<div class="label">联系人：</div>
						<div class="value">{{customeForm.contracts.contacts}}</div>
					</div>
					<div class="flexbox">
						<div class="label">联系电话：</div>
						<div class="value">{{customeForm.contracts.contacts_phone}}</div>
					</div>
				</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">预计启动日期：</div>
						<div class="value">{{customeForm.contracts.estimate_start_time}}</div>
					</div>
					<div class="flexbox">
						<div class="label">合同生效日期：</div>
						<div class="value">{{customeForm.contracts.contracts_effect_time}}</div>
					</div>
					<div class="flexbox">
						<div class="label">合同截止日期：</div>
						<div class="value">
							<span style="color: #FF0000;" v-if="new Date(customeForm.contracts.contracts_end_time).getTime()<new Date().getTime()">
								{{customeForm.contracts.contracts_end_time}} （超期风险）
							</span>
							<span v-else>{{customeForm.contracts.contracts_end_time}}</span>
						</div>
					</div>
					<div class="flexbox">
						<div class="label">客户付款日期：</div>
						<div class="value">{{customeForm.contracts.customer_payment_time}}</div>
					</div>
				</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">签约日期：</div>
						<div class="value">{{customeForm.contracts.sign_date}}</div>
					</div>
					<div class="flexbox">
						<div class="label">签约主体：</div>
						<div class="value">{{customeForm.contracts.sign_subject}}</div>
					</div>
					<div class="flexbox">
						<div class="label">预计年度人数：</div>
						<div class="value">{{customeForm.contracts.estimate_year_people_count}}</div>
					</div>
					<div class="flexbox">
						<div class="label">预计年度金额：</div>
						<div class="value">{{customeForm.contracts.estimate_year_amount}}</div>
					</div>
				</div>
			
				<div class="titleStyle">收费标准</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">收费金额：</div>
						<div class="value">{{customeForm.contracts.charge_amount}}</div>
					</div>
				</div>
				
				<div class="titleStyle">
					<span class="text">银行信息</span>
					<span class="text btn">修改</span>
				</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">客户银行账号：</div>
						<div class="value">{{customeForm.contracts.bank_account}}</div>
					</div>
					<div class="flexbox">
						<div class="label">客户开户银行：</div>
						<div class="value">{{customeForm.contracts.opening_bank}}</div>
					</div>
					<div class="flexbox">
						<div class="label">客户开户名称：</div>
						<div class="value">{{customeForm.contracts.account_holder}}</div>
					</div>
					<div class="flexbox">
						<div class="label">客户开票单位：</div>
						<div class="value">{{customeForm.contracts.invoicing_company}}</div>
					</div>
				</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">客户纳税人识别码：</div>
						<div class="value">{{customeForm.contracts.taxpayer_code}}</div>
					</div>
					<div class="flexbox">
						<div class="label">发票预留电话：</div>
						<div class="value">{{customeForm.contracts.invoice_reserve_phone}}</div>
					</div>
					<div class="flexbox">
						<div class="label">客户纳税类型：</div>
						<div class="value">{{customeForm.contracts.tax_type}}</div>
					</div>
					<div class="flexbox"></div>
				</div>
				
				<div class="titleStyle">合同附件</div>
				<div class="formbox">
					<div class="flexbox" style="display: block;">
						<div class="label" v-if="customeForm.contracts.file.length==0">未上传附件</div>
						<div class="filelist" v-for="(item,index) in customeForm.contracts.file" :key="item.id">
							<div>文件名称：</div>
							<div class="name">{{item.fileName||'合同文件'}}</div>
							<el-button type="text" icon="el-icon-download download" @click="downLoadFile(item)">下载附件</el-button>
						</div>
					</div>
				</div>
				
				<div class="titleStyle">合同备注</div>
				<div class="formbox">
					<div class="flexbox">
						<div class="label">备注信息：</div>
						<div class="value">{{customeForm.contracts.remarks}}</div>
					</div>
				</div>
			</div>
			
			<div v-else>
				<div class="titleStyle">成交信息</div>
				<div style="color: #888;">此客户尚未成交</div>
			</div>
			<!-- <div class="titleStyle">跟进记录</div>
			
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column label="跟进内容" prop="content" align="left" />
				<el-table-column label="跟进人" prop="industry" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.guard=='api'">总后台</div>
						<div v-else>{{scope.row.admin.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="跟进时间" prop="industry" align="center"/>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="changepage" :current-page="page"
					:page-size="10" small layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle} from '@/util/util.js'
	export default {
		name: "customerDetail",
		data() {
			return {
				page:1,
				total:0,
				list:[],
				loading:false,
			};
		},
		props: {
			//控制插件显示
			addhide: {
				type: Boolean,
				default: false
			},
			customeForm:{
				type: [Object, String],
				default: ''
			}
		},
		created() {
			console.log(this.customeForm)
			// this.getFollowList()
		},
		methods:{
			//下载附件
			downLoadFile(item){
				this.axios.get('/'+item.file,{responseType:'blob'}).then(response=>{
					let blob = new Blob([response], {
						type: 'application/*;charset=utf-8'
					});
					if (window.navigator.msSaveOrOpenBlob) {
						//兼容ie
						window.navigator.msSaveBlob(blob, item.fileName);
					} else {
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob); //创建下载的链接
						downloadElement.href = href;
						downloadElement.download = item.fileName; //下载后文件名
						document.body.appendChild(downloadElement);
						//此写法兼容火狐
						let evt = document.createEvent("MouseEvents");
						evt.initEvent("click", false, false);
						downloadElement.dispatchEvent(evt);
						document.body.removeChild(downloadElement);
					}
				})
			},
			//关闭组件
			hideMarketForm(){
				this.$emit('update:addhide',false)
			},
			//跟进记录
			getFollowList(){
				let data = {
					customer_id:this.customeForm.id,
					page:this.page
				}
				this.loading = true
				this.axios.get('/api/main/Customer/followList',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg)
					}
				})
			}
		}
	};
</script>

<style>
	#customerDetail .addbox .el-form-item .el-form-item__label{
		line-height: 32px;
	}
</style>
<style lang="less" scoped>
	.body{
		background: #FFFFFF;
		min-height: calc(100vh - 70px);
	}
	
	.databox{
		padding: 14px;
		.titleStyle{
			padding: 10px 16px;
			margin-bottom: 18px;
			color: #1989fa;
			background: #f8f8f8;
			.text{
				margin-right: 8px;
			}
			.btn{
				cursor: pointer;
			}
		}
		
		.formbox{
			display: flex;
			align-items: center;
			margin-bottom: 18px;
			.flexbox{
				flex: 1;
				display: flex;
				align-items: flex-start;
				.label{
					min-width: 70px;
					color: #999;
					margin-right: 6px;
				}
				.value{
					&:empty::before{
						content: '-';
					}
				}
				.filelist{
					display: flex;
					align-items: center;
					margin-bottom: 12px;
					&:last-child{
						margin-bottom: 0;
					}
					.name{
						color: #1989fa;
						margin-right: 18px;
					}
					.download{
						cursor: pointer;
						color: #1989fa !important;
					}
				}
			}
		}
		
		.pages {
			text-align: center;
			background: #ffffff;
			padding: 20px 0;
			border-top: 1px solid #ededed;
		}
	}
</style>