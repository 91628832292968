<template>
	<div id="oaMarketSeting" v-if="type">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" size="small" @click="openAdd(1,null)">
						添加
						<span v-if="type==1">产品类型</span>
						<span v-if="type==2">客户评级</span>
						<span v-if="type==3">客户来源</span>
						<span v-if="type==4">销售阶段</span>
						<span v-if="type==5">所属行业</span>
						<span v-if="type==6">订单类型</span>
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column :label="type==1?'产品类型':type==2?'客户评级':type==3?'客户来源':type==4?'销售阶段':type==5?'所属行业':'订单类型'" prop="name" align="center" />
				<el-table-column label="备注" prop="remarks" align="center" />
				<el-table-column label="添加人"align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.guard=='api'">总后台</div>
						<div v-else>{{scope.row.admin.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加时间" prop="created_at" align="center" />
				<el-table-column label="操作" align="center" width="180">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="openAdd(2,scope.row)">编辑</el-button>
						<el-button type="danger" size="small" @click="delInfo(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<el-dialog
			:title="`${edit_id?'编辑':'添加'}${this.type==1?'产品类型':this.type==2?'客户评级':this.type==3?'客户来源':this.type==4?'销售等级':type==5?'所属行业':'订单类型'}`"
			:visible.sync="addShow"
			width="600px">
			<div v-loading="addLoading">
				<el-form label-width="90px">
					<el-form-item :label="type==1?'产品类型：':type==2?'客户评级：':type==3?'客户来源：':type==4?'销售阶段：':type==5?'所属行业':'订单类型'">
						<el-input style="width: 100%;" v-model="name" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="备注：">
						<el-input style="width: 100%;" v-model="remarks" placeholder="请输入"></el-input>
					</el-form-item>
				</el-form>
				<div style="text-align: center;">
					<el-button @click="addShow = false">取 消</el-button>
					<el-button type="primary" @click="saveInfo()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips,setTimeStyle} from '@/util/util.js'
	export default {
		name: "oaMarketSeting",
		data() {
			return {
				addLoading:false,
				loading:false,
				list:[],
				type:'',
				addShow:false,
				name:'',
				remarks:'',
				edit_id:'',
			};
		},
		created() {
			if(!this.$route.query.props) return eltips('页面错误','error');
			this.type = this.$route.query.props;
			this.getList()
		},
		watch: {
			$route() {
				this.list = [];
				this.type = this.$route.query.props
				this.getList()
			}
		},
		methods:{
			delInfo(id){
				this.$confirm(`此操作将删除该${this.type==1?'产品类型':this.type==2?'客户评级':this.type==3?'客户来源':this.type==4?'销售阶段':type==5?'所属行业':'订单类型'}, 是否继续?`, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
					this.axios.post('/api/main/Customer/TLF_del',{type_id:id}).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			openAdd(type,item){
				if(type==1){
					this.name = ''
					this.remarks = ''
					this.edit_id = ''
				}
				if(type==2){
					this.name = item.name
					this.remarks = item.remarks
					this.edit_id = item.id
				}
				this.addShow = true
			},
			saveInfo(){
				if(!this.name) return eltips(`请输入${this.type==1?'产品类型':this.type==2?'客户评级':this.type==3?'客户来源':this.type==4?'销售阶段':type==5?'所属行业':'订单类型'}`,'error');
				this.addLoading = true
				let data = { name:this.name, type:this.type ,remarks:this.remarks}
				let url = '/api/main/Customer/TLF_create'
				if(this.edit_id){
					data.type_id = this.edit_id;
					url = '/api/main/Customer/TLF_edit'
				} 
				this.axios.post(url,data).then(res=>{
					this.addLoading = false
					if(res.code==200){
						this.addShow = false
						eltips(res.msg,'success')
						this.getList()
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					page:this.page,
					type:this.type
				}
				this.loading = true
				this.axios.get('/api/main/Customer/TLF_list',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.list = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	#oaMarketSeting{
		min-height: calc(100vh - 70px);
	}
	
	#oaMarketSeting .headbox /deep/ .el-form-item {
		margin-bottom: 0px !important;
	}
	
	.headbox {
		padding: 10px 20px;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.redText{
			color: #FF0000;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>