<template>
	<div id="accountList">
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="所属客户" prop="customer.name" align="center"/>
				<el-table-column label="支付宝手续费" align="center" width="110">
					<template slot-scope="scope">
						<div>{{parseFloat(scope.row.ratio*100)}}% <el-button type="text" icon="el-icon-edit" @click="changeRatio(scope.row.id)"></el-button></div>
					</template>
				</el-table-column>	
				<el-table-column label="电子合同余量" align="center">
					<template slot-scope="scope">
						<div>{{scope.row.contracts_count}} <el-button type="text" icon="el-icon-edit" @click="changeContracts(scope.row.shop_id)"></el-button></div>
					</template>
				</el-table-column>	
				<el-table-column label="网商账户" prop="name" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.alipay_merchant">
							<div>{{scope.row.alipay_merchant.name}}</div>
						</div>
					</template>
				</el-table-column>	
				<el-table-column label="微信特约商户号" prop="name" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.wx_merchant">{{scope.row.wx_merchant.sub_mchid}}</div>
					</template>
				</el-table-column>	
				
				<el-table-column label="到期时间" prop="customer.expiration_time" align="center"/>
				<el-table-column label="登录账号" prop="phone" align="center"/>
				<el-table-column label="品牌名称" prop="name" align="center"/>
				<el-table-column label="系统全称" prop="shop_name" align="center"/>
				<el-table-column label="公司全称" prop="company_name" align="center"/>
				<el-table-column label="操作" align="center" width="260">
					<template slot-scope="scope">
						<el-button type="text" v-if="hasPermissions(2658)" @click="restPassword(scope.row.id)">重置密码</el-button>
						<el-button type="text" v-if="hasPermissions(2659)" @click="openPayConfig(scope.row,1)">网商账户</el-button>
						<el-button type="text" v-if="hasPermissions(2660)" @click="openPayConfig(scope.row,2)">微信特约商户</el-button>
						<el-button type="text" v-if="hasPermissions(2661)" @click="openPremission(scope.row.id)">权限管理</el-button>
						<el-button type="text" v-if="hasPermissions(2662)" @click="openStyle(scope.row)">界面调整</el-button>
						<el-button type="text" @click="copylink(scope.row.shop_id)">复制登录地址</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-dialog
			title="界面调整"
			:visible.sync="styleShow"
			v-if="styleShow"
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			width="600px">
			<div v-loading="saveLoading">
				<el-form :model="styleForm" label-width="108px" :rules="rules" ref="styleForm">
					<el-form-item label="系统全称：" prop="shop_name">
						<el-input size="small" type="text" v-model="styleForm.shop_name" placeholder="请输入系统全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="招聘端名称：" prop="shop_name">
						<el-input size="small" type="text" v-model="styleForm.recruit_name" placeholder="请输入系统全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="系统简介：" prop="introduce">
						<el-input size="small" type="textarea" :rows="3" v-model="styleForm.introduce" placeholder="请输入系统简介" clearable></el-input>
					</el-form-item>
					<el-form-item label="公司全称：" prop="company_name">
						<el-input size="small" type="text" v-model="styleForm.company_name" placeholder="请输入公司全称" clearable></el-input>
					</el-form-item>
					<el-form-item label="品牌名称：" prop="name">
						<el-input size="small" type="text" v-model="styleForm.name" placeholder="请输入品牌名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="logo：" prop="logo">
						<div class="uploadbox">
							<div class="imgbox" v-if="styleForm.logo">
								<el-image class="img" :src="styleForm.logo" fit="cover" :preview-src-list="[styleForm.logo]"></el-image>
								<i class="el-icon-error delicon" @click="delImg('logo')"></i>
							</div>
							<div v-else class="imgbox" style="cursor: pointer;" @click="openPlugin('logo')">
								<i class="el-icon-picture addIcon"></i>
								<div class="addtips">上传logo</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="背景图：" prop="bj_img">
						<div class="uploadbox">
							<div class="imgbox" v-if="styleForm.bj_img">
								<el-image class="img" :src="styleForm.bj_img" fit="cover" :preview-src-list="[styleForm.bj_img]"></el-image>
								<i class="el-icon-error delicon" @click="delImg('bj_img')"></i>
							</div>
							<div v-else class="imgbox" style="cursor: pointer;" @click="openPlugin('bj_img')">
								<i class="el-icon-picture addIcon"></i>
								<div class="addtips">上传背景图</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="版权信息：">
						<el-switch
						  v-model="styleForm.copyright_is_show"
						  :active-value="1"
						  active-text="显示"
						  :inactive-value="0"
						  inactive-text="隐藏">
						</el-switch>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 18px;">
					<el-button size="medium" @click="styleShow = false">取 消</el-button>
					<el-button size="medium" type="primary" @click="saveStyle()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog
			:title="payType==1?'网商账户':'微工卡账户'"
			:visible.sync="payShow"
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			width="600px">
			<div v-loading="saveLoading">
				<el-form :model="payForm" :rules="payRules" ref="payForm">
					<el-form-item label="企业名称：" prop="name" v-if="payType==1">
						<el-input size="small" type="text" v-model="payForm.name" placeholder="请输入企业名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="APPID：" prop="appid" v-if="payType==1">
						<el-input size="small" type="text" v-model="payForm.appid" placeholder="请输入APPID" clearable></el-input>
					</el-form-item>
					<el-form-item label="商户号：" prop="merchantId" v-if="payType==1">
						<el-input size="small" type="text" v-model="payForm.merchantId" placeholder="请输入商户号" clearable></el-input>
					</el-form-item>
					<el-form-item label="结算户：" prop="settlement_account" v-if="payType==1">
						<el-input size="small" type="text" v-model="payForm.settlement_account" placeholder="请输入结算户" clearable></el-input>
					</el-form-item>
					<el-form-item label="社会信用码：" prop="creditCode" v-if="payType==1">
						<el-input size="small" type="text" v-model="payForm.creditCode" placeholder="请输入社会信用码" clearable></el-input>
					</el-form-item>
					<el-form-item label="微信特约商户号：" prop="sub_mchid" v-if="payType==2">
						<el-input size="small" type="text" v-model="payForm.sub_mchid" placeholder="请输入微信特约商户号" clearable></el-input>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 18px;">
					<el-button size="medium" @click="payShow = false">取 消</el-button>
					<el-button size="medium" type="primary" @click="savePayConfig()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		
		<xsk-manage :managehide.sync="imgplugin" @confirm="confirmimg" :selectarr="[]" :selectobjs="[]"></xsk-manage>
		
		<el-dialog title="编辑权限" :visible.sync="permissionShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false"
			@opened="getUserPermissions">
			<div style="max-height: 50vh;overflow-y: auto;">
				<el-tree ref="permissionTree" :data="permissionsList" show-checkbox :props="{ label: 'title', children: '_child' }" v-loading="permissionLoading"  node-key="id">
					<div slot-scope="{ node, data }" style="display: flex;align-items: center;width: 100%;">
						<div :style="{'color':data.type==3?'#666':'#000'}">{{ node.label }}</div>
						<div v-if="data.type==3" style="font-size: 14px;color: #666;">（操作权限）</div>
					</div>
				</el-tree>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="permissionShow = false">取 消</el-button>
				<el-button type="primary" :loading="permissionLoading" @click="setPermissions">确 定</el-button>
			</span>
		</el-dialog>
	
		<el-dialog
			title="操作合同份数"
			v-if="contractShow"
			:visible.sync="contractShow"
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			width="600px">
			<div v-loading="saveLoading">
				<el-form :model="contractsForm" :rules="contractsRules" ref="contractForm" label-width="98px">
					<el-form-item label="变更类型：" prop="type">
						<el-radio v-model="contractsForm.type" label="1">增加合同</el-radio>
						<el-radio v-model="contractsForm.type" label="2">减少合同</el-radio>
					</el-form-item>
					<el-form-item label="变更份数：" prop="count">
						<el-input size="small" type="number" v-model="contractsForm.count" placeholder="请输入变更份数" clearable></el-input>
					</el-form-item>
				</el-form>
				
				<div style="text-align: center;margin-top: 18px;">
					<el-button size="medium" @click="contractShow = false">取 消</el-button>
					<el-button size="medium" type="primary" @click="saveContracts()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips,copylink,validatePassword,httpurl,hasPermissions} from '@/util/util.js'
	export default {
		name: "accountList",
		data() {
			return {
				hasPermissions:hasPermissions,
				loading:false,
				list:[],
				page:1,
				total:0,
				shop_id:'',
				styleShow:false,
				styleForm:{
					name:'',
					recruit_name:'',
					shop_name:'',
					introduce:'',
					bj_img:'',
					logo:'',
					company_name:'',
					copyright_is_show:1,//版权信息
				},
				rules:{
					name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
					recruit_name: [{ required: true, message: '请输入招聘端', trigger: 'blur' }],
					shop_name: [{ required: true, message: '请输入系统全称', trigger: 'blur' }],
					introduce: [{ required: true, message: '请输入系统简介', trigger: 'blur' }],
					logo: [{ required: true, message: '请上传logo', trigger: 'change' }],
					bj_img: [{ required: true, message: '请上传背景图', trigger: 'change' }],
					company_name: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
				},
				imgplugin:false,
				saveLoading:false,
				payShow:false,
				payForm:{
					appid:'',
					name:'',//企业名称
					merchantId:'',//商户号
					settlement_account:'',//结算户
					creditCode:'',//社会信用码
					sub_mchid:'',//微信特约商户号
				},
				payRules:{
					name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
					appid: [{ required: true, message: '请输入APPID', trigger: 'blur' }],
					merchantId: [{ required: true, message: '请输入商户号', trigger: 'blur' }],
					settlement_account: [{ required: true, message: '请输入结算户', trigger: 'blur' }],
					creditCode: [{ required: true, message: '请输入社会信用码', trigger: 'blur' }],
					sub_mchid: [{ required: true, message: '请输入微信特约商户号', trigger: 'blur' }],
				},
				payType:1,
				customer_id:'',
				permissionShow:false,
				permissionLoading:false,
				permissionsList:[],
				user_id:'',
				contractShow:false,
				contractsRules:{
					count: [{ required: true, message: '请输入变更份数', trigger: 'blur' }],
					type: [{ required: true, message: '请选择变更类型', trigger: 'change' }],
				},
				contractsForm:{
					shop_id:'',
					type:'',
					count:'',
				}
			};
		},
		created() {
			this.getList()
			this.getPermissionList()
		},
		methods:{
			changeRatio(id){
				this.$prompt('手续费最低0.001，最高0.006，最多保留4位小数', '支付宝手续费', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputType:'number',
					inputValidator(value) {
						let reg = /^0\.00[1-5][0-9]{0,3}$|^0\.006$/
						if(!reg.test(value)) return '手续费最低0.001，最高0.006';
					},
				}).then(({ value }) => {
					this.axios.post('/api/main/User/setRatio',{id:id,ratio:value}).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			saveContracts(){
				this.$refs['contractForm'].validate((valid) => {
					if (!valid) return;
					this.saveLoading = true
					this.axios.post('/api/main/Contract/de_Or_increment',this.contractsForm).then(res=>{
						this.saveLoading = false
						if(res.code==200){
							this.contractShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			changeContracts(shopId){
				this.contractsForm = {
					shop_id:shopId,
					type:'',
					count:'',
				}
				this.contractShow = true
			},
			openPremission(id) {
				this.user_id = id
				this.permissionShow = true
			},
			getPermissionList(){
				this.axios.get('/api/getPermissionsOA').then(res=>{
					if(res.code==200){
						this.permissionsList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			//获取指定用户的权限
			getUserPermissions() {
				let data = { user_id: this.user_id }
				this.permissionLoading = true
				this.axios.get('/api/getSelfPermissionsOA', { params: data }).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.$refs.permissionTree.setCheckedKeys(res.data)
						this.$nextTick(()=>{
							let nowPermission = [...this.$refs.permissionTree.getCheckedKeys(),...this.$refs.permissionTree.getHalfCheckedKeys()]
							let permission = res.data
							const difference = nowPermission.filter((value) => !permission.includes(value));
							difference.map(item => {
								this.$refs.permissionTree.setChecked(item, false);
							})
						})
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//设置权限
			setPermissions() {
				let permissions = this.$refs.permissionTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked]
				let data = {
					user_id: this.user_id,
					permission_ids: permissions,
				}
				this.permissionLoading = true
				this.axios.post('/api/setSelfPermissionsOA', data).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.permissionShow = false;
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//支付账户绑定
			savePayConfig(){
				this.$refs['payForm'].validate((valid) => {
					if (!valid) return;
					let url = '/api/main/SysTem/setAlipayMerchant'
					let data = this.payForm
					data.customer_id = this.customer_id
					if(this.payType==2) url = '/api/main/SysTem/setWxMerchant'
					this.saveLoading = true
					this.axios.post(url,data).then(res=>{
						this.saveLoading = false
						if(res.code==200){
							this.payShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openPayConfig(item,type){
				this.customer_id = item.customer.id
				this.payType = type
				if(type==1){
					this.payForm = {
						name:item.alipay_merchant?item.alipay_merchant.name:'',//企业名称
						appid:item.alipay_merchant?item.alipay_merchant.appid:'',//appid
						merchantId:item.alipay_merchant?item.alipay_merchant.merchantId:'',//商户号
						settlement_account:item.alipay_merchant?item.alipay_merchant.settlement_account:'',//结算户
						creditCode:item.alipay_merchant?item.alipay_merchant.creditCode:'',//社会信用码
					}
				}else{
					this.payForm = {
						sub_mchid:item.wx_merchant?item.wx_merchant.sub_mchid:'',//微信特约商户号
					}
				}
				this.payShow = true
			},
			//界面调整
			saveStyle(){
				this.$refs['styleForm'].validate((valid) => {
					if (!valid) return;
					let data = {
						name:this.styleForm.name,
						recruit_name:this.styleForm.recruit_name,
						shop_name:this.styleForm.shop_name,
						introduce:this.styleForm.introduce,
						bj_img:this.styleForm.bj_img,
						logo:this.styleForm.logo,
						company_name:this.styleForm.company_name,
						shop_id:this.shop_id
					}
					this.saveLoading = true
					this.axios.post('/api/main/SysTem/setInfo',data).then(res=>{
						this.saveLoading = false
						if(res.code==200){
							this.styleShow = false
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				});
			},
			openPlugin(type){
				this.imgType = type
				this.imgplugin = true
			},
			confirmimg(e){
				console.log(e)
				if(e.imgobjs.length>0){
					this.styleForm[this.imgType] = e.imgobjs[0].qiniu_url
					this.$refs['styleForm'].validateField(this.imgType)
				}
			},
			delImg(type){
				this.styleForm[type] = ''
				this.$refs['styleForm'].validateField(type)
			},
			openStyle(item){
				this.shop_id = item.shop_id
				this.styleForm.name = item.name
				this.styleForm.shop_name = item.shop_name
				this.styleForm.introduce = item.introduce
				this.styleForm.bj_img = item.bj_img
				this.styleForm.logo = item.logo
				this.styleForm.copyright_is_show = item.copyright_is_show
				this.styleForm.company_name = item.company_name
				this.styleShow = true
			},
			restPassword(id) {
				this.$prompt('请输入新密码', `重置密码`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if(!validatePassword(value)) return '密码必须八位以上，且由数字与字母组成，至少包含一位大写字母';
					},
				}).then(({ value }) => {
					let data = { password: value,id:id }
					this.axios.post('/api/main/User/resetPassword', data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			copylink(shopid){
				let url = httpurl+'/login?shopId='+shopid
				copylink(url)
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				this.loading = true
				this.axios.get('/api/main/User/list',{params:{page:this.page}}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#accountList{
		min-height: calc(100vh - 70px);
	}
	
	#accountList .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		// margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
	
	.uploadbox{
		display: flex;
		flex-wrap: wrap;
		.imgbox{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid #DCDFE6;
			width: 120px;
			height:120px;
			margin-right: 8px;
			margin-bottom: 8px;
			position: relative;
			.img{
				width: 100%;
				height: 100%;
			}
			.addIcon{
				font-size: 34px;
				line-height: 1;
				color: #DCDFE6;
			}
			.addtips{
				font-size: 12px;
				color: #DCDFE6;
				margin-top: 8px;
				line-height: 1;
			}
			.delicon {
				display: block;
				font-size: 20px;
				position: absolute;
				right: -8px;
				top: -8px;
			}
		}
	}
</style>