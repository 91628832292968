<template>
	<div id="oaMarketShare">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="客户名称:">
					<el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 180px;"></el-input>
				</el-form-item>
				<el-form-item label="统一社会信用码:">
					<el-input size="small " v-model="credit_code" placeholder="请输入统一社会信用码" clearable style="width: 180px;"></el-input>
				</el-form-item>
				<el-form-item label="产品类型:">
					<el-select size="small" style="width: 180px;" clearable v-model="type" placeholder="请选择">
					    <el-option v-for="item in typeList" :key="item.id" :label="item.name"  :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="客户评级:">
					<el-select size="small" style="width: 180px;" clearable v-model="level" placeholder="请选择">
					    <el-option v-for="item in levelList" :key="item.id" :label="item.name"  :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="客户来源:">
					<el-select size="small" style="width: 180px;" clearable v-model="from" placeholder="请选择">
					    <el-option v-for="item in fromList" :key="item.id" :label="item.name"  :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="销售阶段:">
					<el-select size="small" style="width: 180px;" clearable v-model="sale_stage" placeholder="请选择">
						<el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item label="所属行业:">
					<el-select size="small" style="width: 180px;" clearable v-model="industry" placeholder="请选择">
						<el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				
				<el-form-item label="添加时间:">
					<el-date-picker
						style="width: 270px;"
						size="small"
					    v-model="time"
					    type="daterange"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
					    range-separator="至"
					    start-placeholder="开始日期"
					    end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="编号" prop="main_customer.number" align="center" width="140" />
				<el-table-column label="客户全称" prop="main_customer.name" align="center" width="200" />
				<el-table-column label="地区" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.province"> {{scope.row.main_customer.province+scope.row.main_customer.city+scope.row.main_customer.area}} </div>
					</template>
				</el-table-column>
				<el-table-column label="所属行业" prop="main_customer.industry" align="center" width="100" />
				<el-table-column label="产品类型" prop="main_customer.type" align="center" width="100" />
				<el-table-column label="销售阶段" prop="main_customer.sale_stage" align="center" width="100" />
				<el-table-column label="客户来源" prop="main_customer.from" align="center" width="100" />
				<el-table-column label="主要联系人" align="center" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.contact">
							<div>姓名：{{scope.row.main_customer.contact.name}}</div>
							<div>电话：{{scope.row.main_customer.contact.phone_number}}</div>
							<div>职位：{{scope.row.main_customer.contact.position}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="最后沟通" align="center" width="140">
					<template slot-scope="scope">
						<div v-if="scope.row.main_customer.follow">
							{{timeDifference(scope.row.main_customer.follow.created_at)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="沟通记录" align="center" width="300">
					<template slot-scope="scope">
						<div class="follows" v-if="scope.row.main_customer.follow">{{scope.row.main_customer.follow.content}}</div>
					</template>
				</el-table-column>
				<el-table-column label="分享人" align="center" width="100">
					<template slot-scope="scope">
						<div>{{select_type==1?scope.row.share_admin.username:scope.row.admin.username}}</div>
					</template>
				</el-table-column>
	
		
				<el-table-column label="操作" align="center" width="120" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" v-if="scope.row.main_customer.sign_status==2" @click="addFollow(scope.row.main_customer.id,2)">回访</el-button>
						<el-button type="text" v-if="scope.row.main_customer.sign_status==1" @click="addFollow(scope.row.main_customer.id,1)">跟进</el-button>
						<el-button type="text" v-if="select_type==1" @click="cancelShare(scope.row.id)">取消分享</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<el-dialog :title="`添加${follow.type==1?'跟进':'回访'}`" width="800px" :visible.sync="followShow"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div v-loading="saveLoading">
				<div class="followBox">
					<div class="box" v-if="follow.type!=3">
						<div class="menu">历史{{follow.type==1?'跟进':'回访'}}</div>
						<div class="content listbox" v-loading="followLoading">
							<div class="nodata" v-if="followList.length==0">暂无记录</div>
							<div class="list" v-for="(item,index) in followList" :key="index">
								<span><span style="color: #1989fa;">时间：</span>{{setTimeStyle(item.created_at)}}</span>
								<span><span style="color: #1989fa;">{{item.type==1?'跟进':'回访'}}人：</span>{{item.guard=='api'?'总后台':item.admin.username}}</span>
								<span><span style="color: #1989fa;">联系人：</span>{{item.contact?item.contact.name:'-'}}</span>
								<span><span style="color: #1989fa;">{{item.type==1?'跟进':'回访'}}内容：</span>{{item.content}}</span>
							</div>
						</div>
					</div>
		
					<div class="box" v-if="follow.type!=3">
						<div class="menu"><span style="color: #F00;">*{{follow.type==1?'销售阶段':'客户评级'}}</span></div>
						<div class="content" v-if="follow.type==1">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.sale_stage" placeholder="请选择">
								<el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.name" />
							</el-select>
						</div>
						<div class="content" v-if="follow.type==2">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.level" placeholder="请选择">
								<el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.name" />
							</el-select>
						</div>
						<div class="menu"><span style="color: #F00;">*联系人</span></div>
						<div class="content" style="flex: 1.5;">
							<el-select size="small" style="width: 100%;" clearable v-model="follow.contact_id" placeholder="请选择">
								<el-option v-for="item in followContactList" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</div>
					</div>
		
					<div class="box" v-if="follow.type!=3">
						<div class="menu">下次{{follow.type==1?'跟进':'回访'}}时间</div>
						<div class="content">
							<el-date-picker style="width: 100%;" size="small" v-model="follow.next_contact_date"
								type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
							</el-date-picker>
						</div>
						<div class="menu">预警内容</div>
						<div class="content" style="flex: 1.5;">
							<el-input type="textarea" v-model="follow.warning_content" :rows="2" resize="none"
								placeholder="请输入"></el-input>
						</div>
					</div>
		
					<div class="box" v-if="follow.type!=3">
						<div class="menu"><span style="color: #F00;">*详情备注</span></div>
						<div class="content">
							<el-input type="textarea" v-model="follow.content" :rows="5" resize="none"
								placeholder="请输入"></el-input>
						</div>
					</div>
				</div>
		
				<div style="text-align: center;margin-top: 18px;">
					<el-button @click="followShow = false">取消</el-button>
					<el-button type="primary" @click="saveFollow()">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {eltips,setTimeStyle,timeDifference} from '@/util/util.js'
	export default {
		name: "oaMarketShare",
		data() {
			return {
				setTimeStyle:setTimeStyle, 
				timeDifference: timeDifference,
				loading:false,
				select_type:'',
				page:1,
				total:0,
				list:[],
				name:'',
				credit_code:'',
				type:'',
				level:'',
				from:'',
				sale_stage:'',
				industry:'',
				time:[],
				admin_id:'',
				adminList:[],
				fromList:[],
				levelList:[],
				typeList:[],
				industryList:[],
				stageList:[],
				followLoading:false,
				saveLoading:false,
				customer_id:'',
				followList:[],
				followContactList:[],
				followShow:false,
				follow: {
					type: '', //1跟进 2回访 3售后
					content: '', //跟进内容
					contact_id: '', //联系人ID
					sale_stage: '', //销售阶段
					level:'',
					next_contact_date: '', //下次预警时间
					warning_content: '', //预警内容
					remarks: ''
				}
			};
		},
		created() {
			if(this.$route.query.props) this.select_type = this.$route.query.props;
			this.getList()
			this.getSeting()
			this.getAdminList()
		},
		watch:{
			$route(route) {
				this.name = ''
				this.credit_code = ''
				this.type = ''
				this.level = ''
				this.from = ''
				this.sale_stage = ''
				this.industry = ''
				this.time = []
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.select_type = this.$route.query.props
				this.getList()
			}
		},
		methods:{
			cancelShare(id){
				this.$confirm('此操作将取消该分享，对方将不能看到此客户, 是否继续?', '取消分享', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.axios.post('/api/main/Customer/cancelShare', {share_id:id}).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.getList()
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//添加跟进记录
			addFollow(id, type) {
				this.customer_id = id
				this.follow = {
					type: type, //1跟进 2回访 3售后
					content: '', //跟进内容
					contact_id: '', //联系人ID
					sale_stage: '', //销售阶段
					level:'',
					next_contact_date: '', //下次预警时间
					warning_content: '', //预警内容
					remarks: ''
				}
				this.followList = []
				this.followContactList = []
				this.getFollowList()
				this.getFollowContactList()
				this.followShow = true
			},
			saveFollow() {
				if (this.follow.type == 1 && !this.follow.sale_stage) return eltips('请选择销售阶段', 'error');
				if (this.follow.type == 2 && !this.follow.level) return eltips('请选择客户评级', 'error');
				
				if (!this.follow.contact_id) return eltips('请选择联系人', 'error');
				
				if (this.follow.next_contact_date && !this.follow.warning_content) return eltips('请输入预警内容', 'error');
				if (!this.follow.next_contact_date && this.follow.warning_content) return eltips('请选择预警时间', 'error');
				
				if (!this.follow.content) return eltips('请输入详情备注', 'error');
			
				let data = JSON.parse(JSON.stringify(this.follow));
				data.customer_id = this.customer_id
				this.saveLoading = true
				this.axios.post('/api/main/Customer/createFollow', data).then(res => {
					this.saveLoading = false
					if (res.code == 200) {
						this.getList()
						this.followShow = false
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getFollowContactList() {
				let data = { customer_id: this.customer_id }
				this.axios.get('/api/main/Customer/contact_list', { params: data }).then(res => {
					if (res.code == 200) {
						this.followContactList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getFollowList() {
				let data = {
					customer_id: this.customer_id,
					type: this.follow.type,
					no_page: 1
				}
				this.followLoading = true
				this.axios.get('/api/main/Customer/followList', {
					params: data
				}).then(res => {
					this.followLoading = false
					if (res.code == 200) {
						this.followList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			getAdminList(){
				this.axios.get('/api/main/Customer/member',{params:{type:1}}).then(res=>{
					if(res.code==200){
						this.adminList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getSeting(){
				this.axios.get('/api/main/Customer/TLF_list').then(res=>{
					if(res.code==200){
						this.fromList = res.data.from
						this.levelList = res.data.level
						this.typeList = res.data.type
						this.industryList = res.data.industry
						this.stageList = res.data.sale_stage
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					select_type:this.select_type,
					name:this.name,
					credit_code:this.credit_code,
					type:this.type,
					level:this.level,
					from:this.from,
					admin_id:this.admin_id
				}
				if(this.time&&this.time.length==2){
					data.created_at_st = this.time[0]
					data.created_at_ed = this.time[1]
				}
				this.loading = true
				this.axios.get('/api/main/Customer/shareMainCustomer_list',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	#oaMarketShare{
		min-height: calc(100vh - 70px);
	}
	
	#oaMarketShare .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.follows {
			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
	
	.followBox {
		border: 1px solid #e3e3e3;
	
		.box {
			display: flex;
			align-items: stretch;
			border-bottom: 1px solid #e3e3e3;
	
			&:last-child {
				border-bottom: none;
			}
	
			.menu {
				flex: 0 0 100px;
				background: #F3F3F3;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #e3e3e3;
			}
	
			.content {
				flex: 1;
				padding: 12px;
				border-right: 1px solid #e3e3e3;
				display: flex;
				align-items: center;
	
				&:last-child {
					border-right: none;
				}
			}
	
			.listbox {
				min-height: 60px;
				max-height: 160px;
				overflow-y: auto;
				display: block;
	
				.nodata {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					color: #999;
				}
	
				.list {
					margin-bottom: 14px;
	
					&:last-child {
						margin-bottom: 0;
					}
	
					span {
						margin-right: 14px;
	
						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
</style>