<template>
	<div id="marketRecord">
		<div class="headbox">
			<el-form inline>
				<el-form-item label="编号:">
					<el-input size="small " v-model="number" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="客户名称:">
					<el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="产品类型:">
					<el-select size="small" style="width: 140px;" clearable v-model="customer_type" placeholder="请选择">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column label="编号" prop="customer.number" align="center"/>
				<el-table-column label="客户全称" prop="customer.name" align="center"/>
				<el-table-column label="客户地址" align="center">
					<template slot-scope="scope">
						<div>{{scope.row.customer.province + scope.row.customer.city + scope.row.customer.area}}</div>
					</template>
				</el-table-column>
				<el-table-column label="产品类型" prop="customer.type" align="center"/>
				<el-table-column label="销售阶段" prop="sale_stage" align="center" v-if="type==1"/>
				<el-table-column label="客户评级" prop="level" align="center" v-if="type==2"/>
				<el-table-column label="联系人信息" prop="content" align="center" width="180">
					<template slot-scope="scope">
						<div v-if="scope.row.contact">
							<div>姓名：{{scope.row.contact.name}}</div>
							<div>电话：{{scope.row.contact.phone_number}}</div>
							<div>职位：{{scope.row.contact.position}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="沟通内容" prop="content" align="center" />
				<el-table-column :label="`${type==1?'跟进':'回访'}人`" prop="content" align="center" width="180">
					<template slot-scope="scope">
						<div v-if="scope.row.guard=='api'">总后台</div>
						<div v-else>{{scope.row.admin.username}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="`${type==1?'跟进':'回访'}时间`" prop="created_at" align="center" width="180"/>
			</el-table>	
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page"
				:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle} from '@/util/util.js'
	export default {
		name: "marketRecord",
		data() {
			return {
				loading:false,
				list:[],
				page:1,
				total:0,
				number:'',
				name:'',
				customer_type:'',
				typeList:[],
				type:1,
			};
		},
		created() {
			this.type = this.$route.query.props
			this.getList()
			this.getSeting()
		},
		watch: {
			$route(route) {
				this.name = ''
				this.number = ''
				this.customer_type = ''
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.type = this.$route.query.props
				this.getList()
			}
		},
		methods:{
			getSeting() {
				this.axios.get('/api/main/Customer/TLF_list').then(res => {
					if (res.code == 200) {
						this.typeList = res.data.type
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					page:this.page,
					name:this.name,
					number:this.number,
					customer_type:this.customer_type,
					type:this.type,
					follow_type:1,
				}
				this.loading = true
				this.axios.get('/api/main/Customer/followList',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#marketRecord{
		min-height: calc(100vh - 70px);
	}
	
	#marketRecord .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.redText{
			color: #FF0000;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>