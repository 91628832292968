<template>
	<div id="systemFeedback">
		<div class="tablebox" v-loading="loading">
			<el-tabs v-model="status" @tab-click="changepage(1)">
				<el-tab-pane label="未处理" name="1"></el-tab-pane>
				<el-tab-pane label="已处理" name="2"></el-tab-pane>
			</el-tabs>
			<el-table :key="Math.random()" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="所属客户" prop="customer.name" align="center" width="240"/>
				<el-table-column label="客户意见" prop="content" align="center"/>
				<el-table-column label="提交时间" prop="created_at" align="center" width="200"/>
				<el-table-column label="处理情况" prop="created_at" align="center" width="200">
					<template slot-scope="scope">
						<div style="color: #1989fa;" v-if="scope.row.status==1">未处理</div>
						<div style="color: #FF0000;" v-if="scope.row.status==2">已处理</div>
					</template>
				</el-table-column>
				<el-table-column v-if="status==2" label="处理时间" prop="handle_time" align="center" width="200"/>
				<el-table-column v-if="status==2" label="处理结果" prop="handle_remarks" align="center"/>
				<el-table-column v-if="status==1" label="操作" prop="created_at" align="center" width="120">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="addFeedback(scope.row.id)">处理反馈</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page" :page-size="10"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "systemFeedback",
		data() {
			return {
				loading:false,
				list:[],
				page:1,
				total:0,
				status:'1',
			};
		},
		created() {
			this.getList()
		},
		methods:{
			addFeedback(id){
				this.$prompt('请输入处理结果', '处理反馈', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
					inputType:'textarea',
				    inputValidator(value) {
				    	if (!value) return '提交的内容不能为空'
				    	if (value.replace(/\s/g, '').length < 1) return '提交的内容不能为空'
				    }
				}).then(({ value }) => {
				    this.axios.post('/api/main/SysTem/operator_handele',{id:id,handle_remarks:value}).then(res=>{
				    	if(res.code==200){
				    		eltips(res.msg,'success')
				    		this.getList()
				    	}else{
				    		eltips(res.msg,'error')
				    	}
				    })
				}).catch(() => { });
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					page:this.page,
					status:this.status
				}
				this.loading = true
				this.axios.get('/api/main/SysTem/Feedback_list',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#systemFeedback{
		min-height: calc(100vh - 70px);
	}
	
	#systemFeedback .headbox /deep/ .el-form-item {
		margin-bottom: 10px !important;
	}
	
	.headbox {
		margin-top:10px;
		padding: 10px 20px 0;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		padding: 10px 20px;
		background: #ffffff;
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>