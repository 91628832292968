<template>
	<div id="dealProcess" class="body">
		<div style="padding: 10px;">
			<el-page-header @back="hideMarketForm">
				<div slot="content">
					<div style="font-size: 15px;line-height: 22px;color: #1989fa;">{{disabled?'合同详情':'成交申请'}}</div>
				</div>
			</el-page-header>
		</div>
		
		<div class="databox" v-loading="loading">
			<div class="titleStyle">客户信息</div>
			<el-form>
				<div style="display: flex;">
					<el-form-item label="客户全称:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="customeForm.name" disabled style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户简称:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="customeForm.short_name" disabled style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="所在地区:" style="flex: 1;margin-right: 28px;">
						<xsk-addpick propsValue="label" size="small" style="width: 100%;" disabled :default="customeForm.province?[customeForm.province,customeForm.city,customeForm.area]:[]"></xsk-addpick>
					</el-form-item>
					<el-form-item label="详细地址:" style="flex: 1;">
						<el-input size="small" v-model="customeForm.address" disabled style="width: 100%;"></el-input>
					</el-form-item>
					
				</div>
				
				<div style="display: flex;">
					<el-form-item label="客户编号:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="customeForm.number" disabled style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="所在行业:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" v-model="customeForm.industry" disabled placeholder=""></el-select>
					</el-form-item>
					<el-form-item label="营业执照编号:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="customeForm.credit_code" disabled style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="注册日期:" style="flex: 1;">
						<el-date-picker
							style="width: 100%;"
							disabled
						    v-model="customeForm.registered_date"
						    type="date"
							size="small">
						</el-date-picker>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="注册资金:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="customeForm.registered_capital" disabled style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="公司规模:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" disabled v-model="customeForm.company_size" placeholder=""/>
					</el-form-item>
					<el-form-item label="企业网址:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" disabled v-model="customeForm.website" style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="主营项目:" style="flex: 1;">
						<el-input size="small" disabled v-model="customeForm.main_business" style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="产品类型:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" disabled v-model="customeForm.type" placeholder=""/>
					</el-form-item>
					<el-form-item label="客户评级:" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" disabled v-model="customeForm.level" placeholder=""/>
					</el-form-item>
					<el-form-item label="销售阶段 :" style="flex: 1;margin-right: 28px;">
						<el-select style="width: 100%;" size="small" disabled v-model="customeForm.sale_stage" placeholder=""/>
					</el-form-item>
					<el-form-item label="客户来源:" style="flex: 1;">
						<el-select style="width: 100%;" size="small" disabled v-model="customeForm.from" placeholder=""/>
					</el-form-item>
				</div>
			</el-form>
			
			<div class="titleStyle">合同信息</div>
			<el-form :model="form" :rules="rules" ref="addrefs" label-position="top">
				<div style="display: flex;">
					<el-form-item label="合同名称:" style="flex: 1;margin-right: 28px;" prop="name">
						<span slot="label" style="color: #FF0000;">合同名称:</span>
						<el-input size="small" v-model="form.name" placeholder="请输入合同名称" :disabled="disabled" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="产品类型:" style="flex: 1;margin-right: 28px;" prop="contract_type">
						<span slot="label" style="color: #FF0000;">产品类型:</span>
						<el-select v-model="form.contract_type" size="small" style="width: 100%;" :disabled="disabled" placeholder="请选择产品类型" clearable>
						    <el-option v-for="(item,index) in typeList" :key="index" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="签约联系人:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.contacts" placeholder="请输入签约联系人" :disabled="disabled" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="联系电话:" style="flex: 1;">
						<el-input size="small" v-model="form.contacts_phone" placeholder="请输入联系电话" :disabled="disabled" clearable style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="预计启动日期:" style="flex: 1;margin-right: 28px;" prop="estimate_start_time">
						<span slot="label" style="color: #FF0000;">预计启动日期:</span>
						<el-date-picker
							:disabled="disabled"
							style="width: 100%;"
						    v-model="form.estimate_start_time"
						    type="date"
							size="small"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						    placeholder="请选择预计启动日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="合同生效日期:" style="flex: 1;margin-right: 28px;" prop="contracts_effect_time">
						<span slot="label" style="color: #FF0000;">合同生效日期:</span>
						<el-date-picker
							 :disabled="disabled"
							style="width: 100%;"
						    v-model="form.contracts_effect_time"
						    type="date"
							size="small"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						    placeholder="请选择合同生效日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="合同截止日期:" style="flex: 1;margin-right: 28px;" prop="contracts_end_time">
						<span slot="label" style="color: #FF0000;">合同截止日期:</span>
						<el-date-picker
							 :disabled="disabled"
							style="width: 100%;"
						    v-model="form.contracts_end_time"
						    type="date"
							size="small"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						    placeholder="请选择合同截止日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="客户付款日期:" style="flex: 1;" prop="customer_payment_time">
						<span slot="label" style="color: #FF0000;">客户付款日期:</span>
						<div style="width: 100%;display: flex;align-items: center;">
							<el-select size="small" style="width: 100px" :disabled="disabled" v-model="form.settlement_type" placeholder="请选择" @change="form.customer_payment_time=''">
							    <el-option label="一次付清" :value="1"> </el-option>
							    <el-option label="按月结算" :value="2"> </el-option>
							</el-select>
							<el-date-picker
								v-if="form.settlement_type==1"
								style="flex: 1;"
								:disabled="disabled"
							    v-model="form.customer_payment_time"
							    type="date"
								size="small"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
							    placeholder="请选择付款日期">
							</el-date-picker>
							<div style="flex: 1;display: flex;align-items: center;" v-if="form.settlement_type==2">
								<div class="tipsbox tipsboxLeft">每月</div>
								<el-select size="small" :disabled="disabled" filterable style="flex: 1;border-radius: 0;" placeholder="" v-model="form.customer_payment_time">
								    <el-option v-for="(item,index) in 31" :key="index" :value="item"> </el-option>
								</el-select>	
								<div class="tipsbox tipsboxRight">日</div>
							</div>
						</div>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="签约日期:" style="flex: 1;margin-right: 28px;" prop="sign_date">
						<span slot="label" style="color: #FF0000;">签约日期:</span>
						<el-date-picker
							:disabled="disabled"
							style="width: 100%;"
						    v-model="form.sign_date"
						    type="date"
							size="small"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						    placeholder="请选择签约日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="签约主体:" style="flex: 1;margin-right: 28px;" prop="sign_subject">
						<span slot="label" style="color: #FF0000;">签约主体:</span>
						<el-input size="small" v-model="form.sign_subject" :disabled="disabled" placeholder="请输入签约主体" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="预计年度人数:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.estimate_year_people_count" :disabled="disabled" placeholder="请输入预计年度人数" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="预计年度金额:" style="flex: 1;">
						<el-input size="small" v-model="form.estimate_year_amount" :disabled="disabled" placeholder="请输入预计年度金额" clearable style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="是否垫支:" style="flex: 1;margin-right: 28px;">
						<el-radio :disabled="disabled"  v-model="form.is_advance_payment" :label="0">否</el-radio>
						<el-radio :disabled="disabled"  v-model="form.is_advance_payment" :label="1">是</el-radio>
					</el-form-item>
					<el-form-item label="合作年限:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.cooperation_years" :disabled="disabled" placeholder="请输入合作年限" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item style="flex: 1;margin-right: 28px;"/>
					<el-form-item style="flex: 1;" />
				</div>
		
				<div class="titleStyle">收费标准</div>
				
				<div style="display: flex;">
					<el-form-item label="收费金额:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.charge_amount" :disabled="disabled" placeholder="请输入收费金额" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item style="flex: 1;margin-right: 28px;"></el-form-item>
					<el-form-item style="flex: 1;margin-right: 28px;"></el-form-item>
					<el-form-item style="flex: 1;"></el-form-item>
				</div>
				
				<div class="titleStyle">银行信息</div>
				
				<div style="display: flex;">
					<el-form-item label="客户银行账号:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.bank_account" :disabled="disabled" placeholder="请输入客户银行账号" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户开户银行:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.opening_bank" :disabled="disabled" placeholder="请输入客户开户银行" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户开户名称:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.account_holder" :disabled="disabled" placeholder="请输入客户开户名称" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户开票单位:" style="flex: 1;">
						<el-input size="small" v-model="form.invoicing_company" :disabled="disabled" placeholder="请输入客户开票单位" clearable style="width: 100%;"></el-input>
					</el-form-item>
				</div>
				
				<div style="display: flex;">
					<el-form-item label="客户纳税人识别码:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.taxpayer_code" :disabled="disabled" placeholder="请输入客户纳税人识别码" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="发票预留电话:" style="flex: 1;margin-right: 28px;">
						<el-input size="small" v-model="form.invoice_reserve_phone" :disabled="disabled" placeholder="请输发票预留电话" clearable style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item label="客户纳税类型:" style="flex: 1;margin-right: 28px;">
						<el-select v-model="form.tax_type" size="small" style="width: 100%;" :disabled="disabled" placeholder="请选择客户纳税类型" clearable>
						    <el-option label="一般纳税人" value="一般纳税人"></el-option>
						    <el-option label="小规模纳税人" value="小规模纳税人"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="flex: 1;"></el-form-item>
				</div>
				
				<!-- <div class="titleStyle"><template v-if="!disabled">上传</template>合同附件</div>
				
				<div style="display: flex;">
					<el-form-item style="flex: 1;margin-right: 28px;">
						<div v-show="!file&&!disabled">
							<div class="uploadbtn" v-if="!file">
								<el-button type="primary" size="medium">选择合同</el-button>
								<input class="addinput" ref="uploadFileInput" accept="application/msword,application/pdf" type="file" @change="uploadFile($event)"/>
							</div>
							<div class="tips">仅支持 doc,docx,pdf 格式文件，且大小不超过20M</div>
						</div>
						<div v-show="file&&!disabled">
							<div class="filebox">
								<div class="label">已选文件：</div>
								<div class="text">{{file.name}}</div>
								<i class="el-icon-delete delIcon" @click="delFile()"></i>
							</div>
						</div>
						<template v-if="disabled">
							<div v-if="contractForm.file.length==0">未上传附件</div>
							<div class="filelist" v-for="(item,index) in contractForm.file" :key="item.id">
								<div>文件名称：</div>
								<div class="name">{{item.fileName||'合同文件'}}</div>
								<el-button type="text" icon="el-icon-download download" @click="downLoadFile(item)">下载附件</el-button>
							</div>
						</template>
					</el-form-item>
					<el-form-item style="flex: 1;margin-right: 28px;"></el-form-item>
					<el-form-item style="flex: 1;margin-right: 28px;"></el-form-item>
					<el-form-item style="flex: 1;"></el-form-item>
				</div> -->
				
				<div class="titleStyle">其他</div>
				
				<div style="display: flex;">
					<el-form-item style="flex: 1;">
						<el-input type="textarea" rows="5" v-model="form.remarks" :disabled="disabled" placeholder="请填写备注信息" style="width: 100%;"></el-input>
					</el-form-item>
				</div>
			</el-form>
			
			<div class="btnbox" v-if="!disabled">
				<el-button type="primary" @click="hideMarketForm()">取消申请</el-button>
				<el-button type="primary" @click="confirmApply()">确认提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle} from '@/util/util.js'
	export default {
		name: "dealProcess",
		data() {
			return {
				rules:{
					name:[{ required: true, message: '请输入合同名称', trigger: 'blur' }],
					contract_type: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
					estimate_start_time: [{ required: true, message: '请选择预计启动日期', trigger: 'change' }],
					contracts_effect_time: [{ required: true, message: '请选择合同生效日期', trigger: 'change' }],
					contracts_end_time: [{ required: true, message: '请选择合同截止日期', trigger: 'change' }],
					customer_payment_time: [{ required: true, message: '请填写付款日期', trigger: 'change' }],
					sign_date: [{ required: true, message: '请选择签约日期', trigger: 'change' }],
					sign_subject: [{ required: true, message: '请选择主体', trigger: 'change' }],
				},
				typeList:[],
				form:{
					name:'',//合同名称
					contract_type:'',//合同类型 
					contacts:'',//联系人
					contacts_phone:'',//联系电话
					estimate_sign_time:'',//预警签约日期
					estimate_start_time:'',//预计启动时日期
					contracts_effect_time:'',//合同生效日期
					contracts_end_time:'',//合同终止日期
					settlement_type:1,// 1一次付  2按月结
					customer_payment_time:'',//客户付款日期
					sign_date:'',//签约日期
					estimate_year_people_count:'',//预计人数
					estimate_year_amount:'',//预计金额
					sign_subject:'',//签约主体
					charge_type:'',//收费方式
					bank_account:'',//客户银行账号
					opening_bank:'',//客户开户行
					account_holder:'',//客户开户名称
					invoicing_company:'',//客户开票单位
					taxpayer_code:'',//纳税人识别码
					invoice_reserve_phone:'',//发票预留电话
					tax_type:'',//纳税人类型
					file:'',//合同文件
					remarks:'',
					cooperation_years:'',//合作年限
					is_advance_payment:0,//是否垫支
				},
				disabled:false,
				file:'',
				loading:false
			};
		},
		props: {
			//控制插件显示
			addhide: {
				type: Boolean,
				default: false
			},
			customeForm: {
				type: [String, Object],
				default: ''
			},
			contractForm:{
				type: [String, Object],
				default: ''
			}
		},
		created() {
			this.getTypeList()
			if(this.contractForm){
				this.disabled = true
				this.form.name = this.contractForm.name
				this.form.contract_type = this.contractForm.contract_type
				this.form.contacts = this.contractForm.contacts
				this.form.contacts_phone = this.contractForm.contacts_phone
				this.form.estimate_sign_time = this.contractForm.estimate_sign_time
				this.form.estimate_start_time = this.contractForm.estimate_start_time
				this.form.contracts_effect_time = this.contractForm.contracts_effect_time
				this.form.contracts_end_time = this.contractForm.contracts_end_time
				this.form.customer_payment_time = this.contractForm.customer_payment_time
				this.form.sign_date = this.contractForm.sign_date
				this.form.estimate_year_people_count = this.contractForm.estimate_year_people_count
				this.form.estimate_year_amount = this.contractForm.estimate_year_amount
				this.form.sign_subject = this.contractForm.sign_subject
				this.form.charge_type = this.contractForm.charge_type
				this.form.bank_account = this.contractForm.bank_account
				this.form.opening_bank = this.contractForm.opening_bank
				this.form.account_holder = this.contractForm.account_holder
				this.form.invoicing_company = this.contractForm.invoicing_company
				this.form.taxpayer_code = this.contractForm.taxpayer_code
				this.form.invoice_reserve_phone = this.contractForm.invoice_reserve_phone
				this.form.tax_type = this.contractForm.tax_type
				this.form.file = this.contractForm.file
				this.form.remarks = this.contractForm.remarks
				this.form.cooperation_years = this.contractForm.cooperation_years
				this.form.is_advance_payment = this.contractForm.is_advance_payment
				this.form.settlement_type = this.contractForm.settlement_type
			}
			
		},
		methods:{
			getTypeList(){
				this.axios.get('/api/oa/Customer/TLF_list').then(res=>{
					if(res.code==200){
						this.typeList = res.data.type
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			//删除附件
			delFile (){
				this.file = ''
				this.$nextTick(()=>{
					this.$refs['uploadFileInput'].value = ''
				})
			},
			//删除附件
			delContractsFile(id){
				this.$confirm('此操作将永久删除该附件, 是否继续?', '删除附件', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.axios.get()
				}).catch(() => {});
			},
			//下载附件
			downLoadFile(item){
				this.axios.get('/'+item.file,{responseType:'blob'}).then(response=>{
					let blob = new Blob([response], {
						type: 'application/*;charset=utf-8'
					});
					if (window.navigator.msSaveOrOpenBlob) {
						//兼容ie
						window.navigator.msSaveBlob(blob, item.fileName);
					} else {
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob); //创建下载的链接
						downloadElement.href = href;
						downloadElement.download = item.fileName; //下载后文件名
						document.body.appendChild(downloadElement);
						//此写法兼容火狐
						let evt = document.createEvent("MouseEvents");
						evt.initEvent("click", false, false);
						downloadElement.dispatchEvent(evt);
						document.body.removeChild(downloadElement);
					}
				})
			},
			//上传文件
			uploadFile(e){
				if(e.target.files.length==0) return;
				let file = e.target.files[0]
				if(file.type!='application/pdf'&& file.type!='application/msword') return eltips('请上传指定格式的文件','error');
				if(file.size > 20*1024 * 1024) return eltips('请上传小于20M的文件','error')
				this.file = file
			},
			//关闭组件
			hideMarketForm(){
				this.$emit('update:addhide',false)
			},
			confirmApply(){
				this.$refs['addrefs'].validate((valid) => {
					if (valid) {
						let data = this.paramsToFormData(this.form) 
						// data.customer_id = this.customeForm.id
						data.append('customer_id',this.customeForm.id)
						if(this.file) data.append('file',this.file)
						this.loading = true
						this.axios.post('/api/oa/Contracts/create',data).then(res=>{
							this.loading = false
							if(res.code==200){
								eltips(res.msg,'success')
								this.$emit('update:addhide',false)
							}else{
								eltips(res.msg,'error')
							}
						})
					}else{
						this.$nextTick(()=>{
							document.getElementsByClassName("is-error")[0].scrollIntoView({
								behavior: "smooth",
								block: "center"
							});
						})
					}
				})
			},
			paramsToFormData(obj) {
				const formData = new FormData();
				Object.keys(obj).forEach(key => {
					const value = obj[key]
					if(Array.isArray(value)){
						value.forEach((subValue,i)=>
							formData.append(key+ `[${i}]`,subValue)
						)
					}else{
						formData.append(key, obj[key])
					}
				})
				return formData;
			}
		}
	};
</script>

<style lang="less" scoped>
	.body{
		background: #FFFFFF;
	}
	
	.databox{
		padding: 14px;
		.titleStyle{
			padding: 10px 16px;
			margin-bottom: 12px;
			color: #1989fa;
			background: #f8f8f8;
		}
		
		.tipsbox{
			background-color: #F5F7FA;
			color: #909399;
			display: flex;
			align-items: center;
			padding: 0 20px;
			height: 32px;
			border: 1px solid #DCDFE6;
		}
		.tipsboxLeft{
			border-radius: 4px 0 0 4px;
			border-right: none;
		}
		.tipsboxRight{
			border-radius: 0 4px 4px 0;
			border-left: none;
		}
		
		.uploadbtn{
			display: inline-block;
			position: relative;
			overflow: hidden;
			padding: 0;
			.addinput{
				position: absolute;
				left: 0;
				top: 0;
				width: calc(100% + 80px);
				height: 100%;
				cursor: pointer !important;
				margin-left: -80px;
				opacity: 0;
			}
		}
		.tips{
			line-height: 1;
			color: #999999;
		}
		.filebox{
			display: flex;
			align-items: center;
			.label{
				
			}
			.text{
				color: #1989fa;
			}
			.delIcon{
				cursor: pointer;
				font-size: 18px;
				margin-left: 12px;
				color: #FF0000;
			}
		}
		.filelist{
			display: flex;
			align-items: center;
			margin-bottom: 12px;
			&:last-child{
				margin-bottom: 0;
			}
			.name{
				color: #1989fa;
				margin-right: 18px;
			}
			.download{
				cursor: pointer;
				color: #1989fa !important;
			}
		}
	}
	
	.btnbox{
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>