<template>
	<div id="marketRemind">
		<div class="headbox">
			<el-form :inline="true">
				<el-form-item label="编号:">
					<el-input size="small " v-model="number" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="客户名称:">
					<el-input size="small " v-model="name" placeholder="请输入客户名称" clearable style="width: 150px;"></el-input>
				</el-form-item>
				<el-form-item label="产品类型:">
					<el-select size="small" style="width: 140px;" clearable v-model="customer_type" placeholder="请选择">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="tablebox">
			<el-table v-loading="loading" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }" >
				<el-table-column label="编号" prop="customer.number" align="center"/>
				<el-table-column label="客户全称" prop="customer.name" align="center"/>
				<el-table-column label="客户地址" align="center">
					<template slot-scope="scope">
						<div>{{scope.row.customer.province + scope.row.customer.city + scope.row.customer.area}}</div>
					</template>
				</el-table-column>
				<el-table-column label="产品类型" prop="customer.type" align="center"/>
				<el-table-column label="销售阶段" prop="sale_stage" align="center" v-if="type==1"/>
				<el-table-column label="客户评级" prop="level" align="center" v-if="type==2"/>
				<el-table-column label="预警内容" prop="warning_content" align="center" />
				<el-table-column label="预警人" prop="content" align="center" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.guard=='api'">总后台</div>
						<div v-else>{{scope.row.admin.username}}</div>
					</template>
				</el-table-column>
				<el-table-column label="预警时间" prop="next_contact_date" align="center" width="180"/>
				<el-table-column label="剩余时间" align="center" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.pro_by_status?'已完成':calcTime(scope.row.next_contact_date)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="完成时间" prop="pro_by_time" align="center" width="180"/>
				<el-table-column label="操作" prop="content" align="center">
					<template slot-scope="scope">
						<el-button size="small" type="primary" v-if="!scope.row.pro_by_status" @click="handleAlerts(scope.row.id)">处理预警</el-button>
					</template>
				</el-table-column>
			</el-table>	
		</div>
		
		<div class="pages" v-if="total>10">
			<el-pagination background @current-change="changepage" :current-page="page"
				:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {eltips,setTimeStyle} from '@/util/util.js'
	export default {
		name: "marketRemind",
		data() {
			return {
				loading:false,
				list:[],
				page:1,
				total:0,
				number:'',
				name:'',
				customer_type:'',
				typeList:[],
				type:1,
			};
		},
		created() {
			this.type = this.$route.query.props
			this.getList()
			this.getSeting()
		},
		watch: {
			$route(route) {
				this.name = ''
				this.number = ''
				this.customer_type = ''
				this.page = 1;
				this.total = 0;
				this.list = [];
				this.type = this.$route.query.props
				this.getList()
			}
		},
		methods:{
			handleAlerts(id){
				this.$confirm('此操作将完成此预警，请确认预警内容已完成。', '处理预警', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.axios.post('/api/main/Customer/AfterSalesConfirma',{id:id}).then(res=>{
						if(res.code==200){
							eltips(res.msg,'success')
							this.getList()
						}else{
							eltips(res.msg,'error')
						}
					})
				}).catch(() => {});
			},
			calcTime(date) {
				let startTime = new Date().getTime()
				let endTime = new Date(date).getTime()
				if(startTime>endTime) return '已超期';
			
				let flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
				let start = new Date(startTime);
				let end = new Date(endTime);
				let year = end.getFullYear() - start.getFullYear();
				let month = end.getMonth() - start.getMonth();
				let day = end.getDate() - start.getDate();
				if (month < 0||(month==0&&day<0)) {
					year--;
					month = end.getMonth() + (12 - start.getMonth());
				}
				if (day < 0) {
					month--;
					let index = flag.findIndex((temp) => {
						return temp === start.getMonth() + 1
					});
					let monthLength;
					if (index <= 6) {
						monthLength = 31;
					} else if (index > 6 && index <= 10) {
						monthLength = 30;
					} else {
						monthLength = 28;
					}
					day = end.getDate() + (monthLength - start.getDate());
				}
				const siging = `${year?year+'年':''}${month?month+'月':year&!month?'零':''}${day?day+'天':''}`
				return siging?siging:'1天'
			},
			getSeting() {
				this.axios.get('/api/main/Customer/TLF_list').then(res => {
					if (res.code == 200) {
						this.typeList = res.data.type
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			changepage(e){
				this.page = e
				this.getList()
			},
			getList(){
				let data = {
					page:this.page,
					name:this.name,
					number:this.number,
					customer_type:this.customer_type,
					type:this.type,
					follow_type:2,
				}
				this.loading = true
				this.axios.get('/api/main/Customer/followList',{params:data}).then(res=>{
					this.loading = false
					if(res.code==200){
						this.total = res.data.total
						this.list = res.data.data
					}else{
						eltips(res.msg,'error')
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	#marketRemind{
		min-height: calc(100vh - 70px);
	}
	
	#marketRemind .headbox /deep/ .el-form-item {
		margin-bottom: 0px !important;
	}
	
	.headbox {
		padding: 10px 20px;
		background: #ffffff;
		border-radius: 2px;
	}
	
	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
		.redText{
			color: #FF0000;
		}
	}
	
	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>