<template>
	<div id="project">
		<div class="headbox">
			<div class="searchbox">
				<el-form :inline="true" class="demo-form-inline">

					<el-form-item label="昵称:">
						 <el-input size="mini" v-model="name" placeholder="请输入昵称" clearable></el-input>
					</el-form-item>

					<el-form-item label="电话:">
						 <el-input size="mini" v-model="phone" placeholder="请输入电话" clearable></el-input>
					</el-form-item>

					<el-form-item label="添加时间:">
						 <el-date-picker size="mini" v-model="time" clearable type="datetimerange" start-placeholder="开始日期"
							format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期"
							:default-time="['12:00:00']">
						</el-date-picker>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" size="small" @click="search">查询</el-button>
						<el-button type="primary" size="small" @click="addAdmin">添加数据看板账号</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="tablebox">
			<el-table v-loading="loading" :data="list" stripe :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<el-table-column label="账号情况" prop="name" >
					<template slot-scope="scope">
						<div>账号：{{scope.row.username}}</div>
						<div>电话：{{scope.row.phone}}</div>
					</template>
				</el-table-column>
				
				<el-table-column label="操作记录" align="center" width="300">
					<template slot-scope="scope">
						<div style="text-align: left;">
							<div>
								<span style="margin-right: 2em;display: inline-block;">创建人：{{scope.row.addData.admin_name}}</span>
								<span>创建时间：{{setTimeStyle(scope.row.addData.created_at)}}</span>
							</div>
							<div>
								<span style="margin-right: 2em;display: inline-block;">修改人：{{scope.row.editData?scope.row.editData.admin_name:'-'}}</span>
								<span>修改时间：{{scope.row.editData?setTimeStyle(scope.row.editData.created_at):'-'}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column label="明文密码" prop="open_pass" align="center" ></el-table-column>
				
				<el-table-column label="是否正常" align="center" width="80">
					<template slot-scope="scope">
						<div :style="{color:scope.row.status==0?'#ff1f2c':''}">{{ scope.row.status == 1 ? "启用" : "禁用" }}</div>
					</template>
				</el-table-column>
				
				
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button size="small" type="text" @click="edit(scope.row)">编辑</el-button>
						<el-button size="small" type="text" @click="changePwd(scope.row.id)">修改密码</el-button>
						<el-button size="mini" type="text" :style="{color:scope.row.status==1?'#ff1f2c':''}" @click="changestatus(scope.row.id,scope.row.status,scope.$index)">{{scope.row.status==1?'禁用':'启用'}}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<div class="pages">
			<el-pagination background :hide-on-single-page="false" @current-change="changepage" :current-page="page"
				:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>

		<el-dialog :title="titleText" :visible.sync="dialogShow" width="600px">
			<div>
				<el-form ref="form" label-width="100px">
					<el-form-item label="昵称:">
						 <el-input size="mini" v-model="from.name" placeholder="请输入昵称" clearable></el-input>
					</el-form-item>
					<el-form-item label="登录账号:">
						 <el-input size="mini" v-model="from.username" placeholder="请输入登录账号" clearable></el-input>
					</el-form-item>
					<el-form-item label="电话号码:">
						 <el-input size="mini" v-model="from.phone" placeholder="请输入电话号码" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="edit_id == ''" label="登录密码:">
						 <el-input size="mini" type="text" v-model="from.password" show-password placeholder="请输入登录密码"></el-input>
					</el-form-item>
					<el-form-item v-if="edit_id == ''" label="确认密码:">
						 <el-input size="mini" type="text" v-model="from.password_confirmation" show-password placeholder="请输入确认密码">
						</el-input>
					</el-form-item>
					<el-form-item label="账号备注:">
						 <el-input size="mini" v-model="from.remarks" placeholder="请输入账号备注" clearable></el-input>
					</el-form-item>
					<el-form-item label="账号状态:">
						<el-radio-group v-model="from.status">
							<el-radio :label="0">禁用</el-radio>
							<el-radio :label="1">启用</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer">
				<el-button type="infor" @click="dialogShow = false">取消</el-button>
				<el-button type="primary" @click="sendinfo">保存</el-button>
			</div>
		</el-dialog>


	</div>
</template>

<script>
	import {
		eltips
	} from "@/util/util.js";
	export default {
		name: "serviceListss",
		data() {
			return {
				dialogShow: false,
				dialogVisible: false,
				choose: "",
				loading: false,
				list: [],
				total: 0,
				page: 1,
				sort:'',

				titleText: "",
				childBack: false,
				type: 0,
				from: {
					name: "",
					username: "",
					password: "",
					password_confirmation: "",
					phone: "",
					status: 1,
					is_default_customer_service:0,
					remarks: "",
				},

				// 筛选
				name: "",
				phone: '',
				time: '',

				edit_id: "",

			};
		},
		created() {
			this.getlist();
		},
		methods: {
			changePwd(id){
				this.$prompt('请输入新密码', '修改密码', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^\S{6,}$/,
					inputErrorMessage: '密码不少于6位'
				}).then(({ value }) => {
					let data = {
						admin_id:id,
						password:value
					}
					this.loading = true
					this.axios.put('/api/orange_manage/admin/reset_password',data).then(res=>{
						if(res.code==200){
							eltips(res.msg, "success");
							this.getlist();
						}else{
							this.loading = false
							eltips(res.msg, "error");
						}
					})
				}).catch(() => {});
			},
			setTimeStyle(time){
				let y = new Date(time).getFullYear()
				let m = new Date(time).getMonth()+1
				let d = new Date(time).getDate()
				return `${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d}`
			},
			getlist() {
				let data = {
					name: this.name,
					phone: this.phone,
					role_id:90,
					page: this.page,
					sort:this.sort,
					is_extension:1
				};
				this.loading = true
				this.axios.get("/api/orange_manage/user/adminUsers", {
					params: data
				}).then((res) => {
					this.loading = false
					this.total = res.data.total
					this.list = res.data.data
				});
			},
			
			
			addAdmin() {
				this.titleText = "添加数据看板账号";
				this.dialogShow = true;
				this.from= {
					name: "",
					username: "",
					password: "",
					password_confirmation: "",
					phone: "",
					status: 1,
					remarks: "",
				}
				this.edit_id=''
			},
			// 修改
			edit(item){
				this.edit_id=item.id
				this.from={
					name: item.name,
					username: item.username,
					phone: item.phone,
					status: item.status,
					remarks: item.remarks,
				}
				this.dialogShow = true;
			},
			
			//页码切换事件
			changepage(e) {
				console.log(e, '页码切换')
				this.page = e;
				this.getlist()
			},
			
			sendinfo() {
				let data=this.from
				data.role_id=90
				
				let url="/api/orange_manage/user/adminUser"
				
				if(this.edit_id){
					url="/api/orange_manage/user/adminUser/"+this.edit_id
				}
				
				this.axios.post(url, data).then((res) => {
					if (res.code == 200) {
						eltips(res.msg, "success");
						this.dialogShow = false;
						this.getlist()
					} else {
						eltips(res.msg, "error");
					}
				});
			},
			
			changestatus(id,e,index){
			
				if(e==1){
					e=0
				}else{
					e=1
				}
				this.axios.post("/api/orange_manage/user/adminUser/"+id,{status:e}).then(res=>{
					if(res.code==200){
						this.getlist()
						eltips(res.msg,'success')
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			search(){
				this.page=1
				this.getlist()
			}
		},
	};
</script>


<style>
	#project .headbox .el-form-item {
		margin-bottom: 0 !important;
	}
</style>
<style scoped lang="less">
	#project {
		min-height: calc(100vh - 70px);
		
	}

	.headbox {
		padding: 20px;
		background: #ffffff;
		border-radius: 2px;
	}

	.tablebox {
		margin-top: 10px;
		padding: 10px 20px;
		background: #ffffff;
	}

	.pages {
		text-align: center;
		background: #ffffff;
		padding: 20px 0;
		border-top: 1px solid #ededed;
	}
</style>

