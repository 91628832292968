<template>
	<div id="premission">
		<div class="classList">
			<div class="titlestyle">
				<div class="text">部门列表</div>
				<el-button type="text" size="medium" @click="editClass({},'post')" v-if="$hasPermissions(2259)">添加部门
				</el-button>
			</div>
			<el-tree ref="tree" :data="classList" default-expand-all :expand-on-click-node="false" :highlight-current="true"
				:props="{ label: 'name', children: 'child' }" v-loading="classLoading" node-key="id">
				<div slot-scope="{ node, data }" @click="pickClass(data)"
					style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
					<div style="color: #333333;">{{ node.label }}</div>
					<div v-if="data.id==1" style="color: #999999;font-size: 12px;">加盟商类别不可编辑</div>
					<div v-else>
						<el-button type="text" size="small" v-if="$hasPermissions(2259)"
							@click.stop="() => editClass(data,'post')">添加</el-button>
						<el-button type="text" size="small" v-if="$hasPermissions(2261)"
							@click.stop="() => editClass(data,'put')">编辑</el-button>
						<el-button type="text" size="small" v-if="$hasPermissions(2260)" style="color: #FF0000;"
							@click.stop="() => delClass(data)">删除</el-button>
					</div>
				</div>
			</el-tree>
		</div>

		<div class="tableBox">
			<div class="headbox">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item label="账号:">
						 <el-input size="mini" v-model="name" placeholder="请输入账号" clearable></el-input>
					</el-form-item>
					<el-form-item label="状态:">
						 <el-select size="mini" v-model="status" style="width: 100px;" placeholder="请选择" clearable>
							<el-option label="启用" :value="1"></el-option>
							<el-option label="禁用" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="changePage(1)">查询</el-button>
						<el-button type="primary" size="small" v-if="$hasPermissions(2262)" @click="openAdd">添加账号</el-button>
						<el-button type="primary" size="small" v-if="$hasPermissions(2262)" @click="openPreset">预设权限</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table v-loading="loading" :height="tabHeight" :data="list" border :header-cell-style="{ background: '#fafafa', color: '#000' }">
				<template v-if="class_id==1">
					<el-table-column label="加盟商账号" prop="phone" key="1" align="center"></el-table-column>
					<el-table-column label="店铺名称" prop="shop_name" key="2" align="center"></el-table-column>
					<el-table-column label="联系人" prop="name" align="center" key="3"></el-table-column>
					<el-table-column label="联系电话" prop="customer_phone" align="center" key="4"></el-table-column>
				</template>
				<template v-if="class_id!=1">
					<el-table-column label="账号" prop="username" key="1" align="center"></el-table-column>
					<el-table-column label="电话" prop="phone" key="2" align="center"></el-table-column>
					<el-table-column label="昵称" prop="name" key="3" align="center"></el-table-column>
					<el-table-column label="账号类型" key="4" align="center">
						<template slot-scope="scope">
							<div style="color: #1989fa;">{{scope.row.is_partners==1?'渠道账号':scope.row.is_partners==2?'项目管理账号':'普通账号'}}</div>
						</template>
					</el-table-column>
				</template>
				<el-table-column label="明文密码" prop="open_pass" align="center"></el-table-column>
				<el-table-column label="添加时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="账号状态" prop="created_at" align="center">
					<template slot-scope="scope">
						<div :style="{'color':scope.row.status==1?'#1989fa':'#FF0000'}">
							{{scope.row.status==1?'启用':'禁用'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="240">
					<template slot-scope="scope">
						<el-button type="text" v-if="$hasPermissions(2263)" @click="openEdit(scope.row)">编辑</el-button>
						<el-button type="text" v-if="$hasPermissions(2263)&&class_id!=1" @click="openDepartment(scope.row)">调整部门</el-button>
						<el-button type="text" v-if="$hasPermissions(2266)" @click="restPassword(scope.row)">重置密码
						</el-button>
						<el-button type="text" v-if="$hasPermissions(2265)" @click="openPremission(scope.row)">权限管理
						</el-button>
						<el-button type="text" v-if="$hasPermissions(2264)" @click="changeStatus(scope.row)"
							:style="{'color':scope.row.status==0?'#1989fa':'#FF0000'}">{{scope.row.status==1?'禁用':'启用'}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background :hide-on-single-page="false" @current-change="changePage" :current-page="page"
					:page-size="10" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
		</div>

		<el-dialog :title="`${franchisee_id?'编辑':'添加'}加盟商`" :visible.sync="franchiseeShow" width="600px">
			<div>
				<el-form ref="form" label-width="100px">
					<el-form-item label="店铺名称">
						 <el-input size="mini" type="text" v-model="franchisee.shop_name" placeholder="请输入店铺名称"></el-input>
					</el-form-item>
					<el-form-item label="账号/电话">
						 <el-input size="mini" type="text" v-model="franchisee.phone" placeholder="请输入账号/电话"></el-input>
					</el-form-item>
					<el-form-item label="登陆密码" v-if="!franchisee_id">
						 <el-input size="mini" type="password" v-model="franchisee.password" placeholder="请输入登陆密码"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" v-if="!franchisee_id">
						 <el-input size="mini" type="password" v-model="franchisee.password_confirmation" placeholder="请输入确认登陆密码">
						</el-input>
					</el-form-item>
					<el-form-item label="联系人">
						 <el-input size="mini" type="text" v-model="franchisee.name" placeholder="请输入联系人"></el-input>
					</el-form-item>
					<el-form-item label="联系人电话">
						 <el-input size="mini" type="text" v-model="franchisee.customer_phone" placeholder="请输入联系人电话"></el-input>
					</el-form-item>
					<el-form-item label="账号状态">
						<el-switch v-model="franchisee.status" :active-value="1" :inactive-value="0" active-text="启用"
							inactive-text="禁用">
						</el-switch>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="franchiseeShow = false">取 消</el-button>
				<el-button type="primary" :loading="editLoading" @click="saveFranchisee">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="`${admin_id?'编辑':'添加'}账号`" :visible.sync="adminShow" width="600px" :close-on-click-modal="false" :close-on-press-escape="false">
			<div>
				<el-form ref="form" label-width="100px">
					<el-form-item label="昵称">
						 <el-input size="mini" type="text" v-model="adminUser.name" placeholder="请输入昵称"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						 <el-input size="mini" type="text" v-model="adminUser.username" placeholder="请输入账号"></el-input>
					</el-form-item>
					<el-form-item label="登陆密码" v-if="!admin_id">
						 <el-input size="mini" type="password" v-model="adminUser.password" placeholder="请输入登陆密码"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" v-if="!admin_id">
						 <el-input size="mini" type="password" v-model="adminUser.password_confirmation" placeholder="请输入确认登陆密码">
						</el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						 <el-input size="mini" type="number" v-model="adminUser.phone" placeholder="请输入联系电话"></el-input>
					</el-form-item>
					<el-form-item label="账号备注">
						 <el-input size="mini" type="text" v-model="adminUser.remarks" placeholder="请输入账号备注"></el-input>
					</el-form-item>
					<!-- <el-form-item label="账号类型:">
						<el-radio-group v-model="adminUser.is_partners" :disabled="admin_id!=''">
							<el-radio :label="0">普通账号</el-radio>
							<el-radio :label="1">渠道账号</el-radio>
							<el-radio :label="2">项目管理账号</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item label="默认客服:">
						<el-radio-group v-model="adminUser.is_d_cs">
							<el-radio :label="0">否</el-radio>
							<el-radio :label="1">是</el-radio>
						</el-radio-group>
						<div style="line-height: 1;color: #999;">Tips：只能有一个默认客服账号</div>
					</el-form-item>
					
					<el-form-item label="账号状态">
						<el-switch v-model="franchisee.status" :active-value="1" :inactive-value="0" active-text="启用"
							inactive-text="禁用">
						</el-switch>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="adminShow = false">取 消</el-button>
				<el-button type="primary" :loading="editLoading" @click="saveAdminUser">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="编辑权限" :visible.sync="permissionShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false"
			@opened="getUserPermissions">
			<div style="max-height: 50vh;overflow-y: auto;">
				<div style="margin-bottom: 12px;">
					<div>预设权限：</div>
					<div style="margin-top: 12px;padding: 12px 0;border-top: 1px solid #c3c3c3;border-bottom: 1px solid #c3c3c3;">
						<div v-if="presetList.length==0" style="text-align: center;color: #1989fa;"><span style="cursor: pointer;" @click="openPreset">尚未预设权限，点击添加</span></div>
						<el-button style="margin-bottom: 10px;margin-left: 10px;" type="primary" size="mini" v-for="item in presetList" :key="item.id" @click="usePreset(item)">{{item.name}}</el-button>
					</div>
				</div>
				<el-tree ref="permissionTree" :data="permissionsList" show-checkbox
					:props="{ label: 'title', children: '_child' }" v-loading="permissionLoading" check-strictly
					@check-change="checkChange" node-key="id">
					<div slot-scope="{ node, data }" style="display: flex;align-items: center;width: 100%;">
						<div :style="{'color':data.type==3?'#666':'#000'}">{{ node.label }}</div>
						<div v-if="data.type==3" style="font-size: 14px;color: #666;">（操作权限）</div>
						<!-- <div v-if="data.id==2368||data.parent_id==2368" style="font-size: 14px;color: #666;">（仅供渠道账号使用）</div> -->
						<!-- <div v-if="data.id==2323||data.parent_id==2323" style="font-size: 14px;color: #666;">（仅供项目管理账号使用）</div> -->
					</div>
				</el-tree>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="permissionShow = false">取 消</el-button>
				<el-button type="primary" :loading="permissionLoading" @click="setPermissions">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog title="预设权限" :visible.sync="presetShow" width="600px" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false">
			<div style="max-height: 50vh;overflow-y: auto;">
				<el-table :data="presetList" style="width: 100%">
				    <el-table-column prop="name" label="预设权限"></el-table-column>
				    <el-table-column label="操作" width="120" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="openPresetAdd(scope.row)">编辑</el-button>
							<el-button type="text" @click="delPreset(scope.row.id)" :style="{'color':'#FF0000'}">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer">
				<el-button @click="presetShow = false">取 消</el-button>
				<el-button type="primary" @click="openPresetAdd">添加预设</el-button>
			</span>
			
			<el-dialog
			    width="550px"
			    title="预设权限"
			    :visible.sync="addShow"
				@opened="presetOpened"
				destroy-on-close
			    append-to-body>
				<div style="max-height: 50vh;overflow-y: auto;">
					<el-form labelPosition="top">
						<el-form-item label="预设名称:">
							<el-input size="mini" v-model="presetName" placeholder="请输入预设名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="选择权限:">
							<el-tree style="width: 100%;" ref="permissionPresetTree" :data="permissionsList" show-checkbox
								:props="{ label: 'title', children: '_child' }" v-loading="permissionLoading" check-strictly
								@check-change="checkPresetChange" node-key="id">
								<div slot-scope="{ node, data }" style="display: flex;align-items: center;width: 100%;">
									<div :style="{'color':data.type==3?'#666':'#000'}">{{ node.label }}</div>
									<div v-if="data.type==3" style="font-size: 14px;color: #666;">（操作权限）</div>
								</div>
							</el-tree>
						</el-form-item>
					</el-form>
				</div>
				<div slot="footer">
					<el-button @click="addShow = false">取 消</el-button>
					<el-button type="primary" @click="savePreset">保 存</el-button>
				</div>
			</el-dialog>
		</el-dialog>
		
		<el-dialog title="调整部门" :visible.sync="departmentShow" width="600px" destroy-on-close @opened="setClassId">
			<div>
				<el-tree ref="departmentTree" 
					v-loading="classLoading"
					:data="classList" 
					default-expand-all 
					check-strictly 
					show-checkbox
					:expand-on-click-node="false" 
					:props="{ label: 'name', children: 'child' }"
					@check-change="treeRadio" 
					node-key="id">
				</el-tree>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="departmentShow = false">取 消</el-button>
				<el-button type="primary" :loading="editLoading" @click="changDepartment">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { eltips,validatePassword } from '@/util/util.js'
	export default {
		name: 'premission',
		data() {
			return {
				classLoading: false,
				classList: [],
				class_id: '',
				page: '',
				loading: false,
				total: 0,
				page: 1,
				list: [],
				name: '',
				status: '',
				adminUser: {
					name: '', //昵称
					username: '', //账号
					password: '',
					password_confirmation: '',
					phone: '', //联系电话
					remarks: '', //账号备注
					is_d_cs:0,//默认客服 
					status: 1 ,//0禁用 1启用
					is_partners:0,//0普通用户，1合作商，2驻场
				},
				franchisee: {
					shop_name: '', //加盟商店铺名称
					phone: '', //账号，电话
					password: '',
					password_confirmation: '',
					name: '', //联系人
					customer_phone: '', //联系人电话
					status: 1, //0禁用 1启用
				},
				tabHeight: '100%',
				franchiseeShow: false,
				franchisee_id: '',
				editLoading: false,
				admin_id: '',
				adminShow: false,
				permissionShow: false,
				permissionLoading: false,
				permissionsList: [],
				departmentShow: false,
				chage_class_id: '',
				presetShow:false,
				presetList:[],
				addShow:false,
				presetName:'',
				presetId:'',
				presetTreeList:[],
				userinfo:JSON.parse(sessionStorage.getItem('userinfo'))
			};
		},
		created() {
			this.getPreset()
			this.getPermissions()
		},
		mounted() {
			this.getClassList()
			this.getTableMaxHeight()
		},
		methods: {
			//使用预设
			usePreset(item){
				let permissions = this.$refs.permissionTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked, ...item.permission_ids]
				let list = [...new Set(permissions)];
				this.$refs.permissionTree.setCheckedKeys(list)
			},
			//删除预设
			delPreset(id){
				this.$confirm('此操作将永久删除该预设, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.axios.delete('/api/orange_manage/Permission/presets/'+id).then(res=>{
						if(res.code==200){
							this.getPreset()
							eltips(res.msg, 'success')
						}else{
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//保存预设
			savePreset(){
				if(!this.presetName) return eltips('请输入预设名称','error');
				let permissions = this.$refs.permissionPresetTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionPresetTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked]
				if(permissions.length==0) return eltips('请选择预设权限','error');
				let data = {
					name:this.presetName,
					permission_ids:permissions
				}
				let url = '/api/orange_manage/Permission/presets'
				let method = 'post'
				
				if(this.presetId){
					url = '/api/orange_manage/Permission/presets/'+this.presetId
					method = 'put'
				}
				
				this.axios[method](url, data).then(res => {
					if (res.code == 200) {
						this.addShow = false;
						this.getPreset()
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//新建、编辑预设
			openPresetAdd(item){
				this.presetId = '';
				this.presetName = '';
				this.presetTreeList = []
				if(item){
					this.presetId = item.id;
					this.presetName = item.name;
					this.presetTreeList = item.permission_ids
				} 
				this.addShow = true;
			},
			presetOpened(){
				this.$refs.permissionPresetTree.setCheckedKeys(this.presetTreeList)
			},
			openPreset(){
				this.permissionShow = false
				this.presetShow = true
			},
			getPreset(){
				this.axios.get('/api/orange_manage/Permission/presets').then(res=>{
					this.editLoading = false
					if(res.code==200){
						this.presetList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			changDepartment(){
				let department = this.$refs.departmentTree.getCheckedKeys()
				if(department.length==0) return eltips('请选择部门','error');
				
				this.editLoading = true
				this.axios.post('/api/orange_manage/user/adminUser/'+this.admin_id,{class_id:department[0]}).then(res=>{
					this.editLoading = false
					if(res.code==200){
						eltips(res.msg,'success')
						this.departmentShow = false
						this.getAdminList()
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			openDepartment(item) {
				this.admin_id = item.id
				this.chage_class_id = item.class_id
				this.departmentShow = true
			},
			setClassId() {
				this.$refs.departmentTree.setCheckedKeys([this.chage_class_id]);
			},
			treeRadio(node, check) {
				if(check){
					this.$refs.departmentTree.setCheckedKeys([node.id]);
				}
			},
			checkPresetChange(node, check) {
				if (check === false) {
					if (node._child.length > 0) {
						node._child.map(item => {
							this.$refs.permissionPresetTree.setChecked(item.id, false);
						})
					}
				} else {
					if (node.parent_id !== 0) {
						this.$refs.permissionPresetTree.setChecked(node.parent_id, true);
					}
				}
			},
			checkChange(node, check) {
				if (check === false) {
					if (node._child.length > 0) {
						node._child.map(item => {
							this.$refs.permissionTree.setChecked(item.id, false);
						})
					}
				} else {
					if (node.parent_id !== 0) {
						this.$refs.permissionTree.setChecked(node.parent_id, true);
					}
				}
			},
			openPremission(item) {
				if (this.class_id == 1) {
					this.franchisee_id = item.id
				} else {
					this.admin_id = item.id
				}
				this.permissionShow = true
			},
			//新增账号
			openAdd() {
				if(!this.class_id) return eltips('请选择部门','error');
				if (this.class_id == 1) {
					this.franchisee_id = ''
					this.franchisee = {
						shop_name: '', //加盟商店铺名称
						phone: '', //账号，电话
						password: '',
						password_confirmation: '',
						name: '', //联系人
						customer_phone: '', //联系人电话
						status: 1, //0禁用 1启用
					}
					this.franchiseeShow = true
				} else {
					this.admin_id = ''
					this.adminUser = {
						name: '', //昵称
						username: '', //账号
						password: '',
						password_confirmation: '',
						phone: '', //联系电话
						remarks: '', //账号备注
						is_d_cs:0,//默认客服
						status: 1 ,//0禁用 1启用
						is_partners:0,//0普通用户，1合作商，2驻场账号
					}
					this.adminShow = true
				}
			},
			//编辑账号
			openEdit(item) {
				if (this.class_id == 1) {
					this.franchisee_id = item.id
					this.franchisee = {
						shop_name: item.shop_name, //加盟商店铺名称
						phone: item.phone, //账号，电话
						name: item.name, //联系人
						customer_phone: item.customer_phone, //联系人电话
						status: item.status, //0禁用 1启用
					}
					this.franchiseeShow = true
				} else {
					this.admin_id = item.id
					this.adminUser = {
						name: item.name, //昵称
						username: item.username, //账号
						phone: item.phone, //联系电话
						remarks: item.remarks, //账号备注
						is_d_cs:item.is_default_customer_service,
						status: item.status ,//0禁用 1启用
						is_partners:item.is_partners,//0普通用户，1合作商 2驻场账号
					}
					this.adminShow = true
				}
			},
			//重置密码
			restPassword(item) {
				this.$prompt('请输入新密码', `重置密码`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if(!validatePassword(value)) return '密码必须八位以上，且由数字与字母组成，至少包含一位大写字母';
					},
					inputErrorMessage: '密码不能为空'
				}).then(({ value }) => {
					let data = { password: value }
					let url = '/api/UserAccountStatus/resetPassword/' + item.id
					let method = 'put'
					if (this.class_id != 1) {
						url = '/api/orange_manage/admin/reset_password'
						data.admin_id = item.id
					}
					this.axios[method](url, data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//修改状态
			changeStatus(item) {
				this.$confirm(`此操作将${item.status==1?'禁用':'启用'}该账号, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = '/api/UserAccountStatus/' + item.id;
					let method = 'put'
					if (this.class_id != 1) {
						url = '/api/orange_manage/user/adminUser/' + item.id
						method = 'post'
					}
					let data = {
						status: item.status ? 0 : 1
					}
					this.axios[method](url, data).then(res => {
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.getAdminList()
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//保存加盟商信息
			saveFranchisee() {
				let data = this.franchisee
				let url = '/api/UserAccount'
				let method = 'post'
				if (this.franchisee_id) {
					url += '/' + this.franchisee_id
					method = 'put'
				}
				this.editLoading = true
				this.axios[method](url, data).then(res => {
					this.editLoading = false
					if (res.code == 200) {
						eltips(res.msg, 'success')
						this.franchiseeShow = false
						this.getAdminList()
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//保存账号信息
			saveAdminUser() {
				if(!this.class_id) return eltips('请选择部门','error');
				
				let data = this.adminUser
				data.class_id = this.class_id
				let url = '/api/orange_manage/user/adminUser'
				if (this.admin_id) url += '/' + this.admin_id;
				this.editLoading = true
				this.axios.post(url, data).then(res => {
					this.editLoading = false
					if (res.code == 200) {
						eltips(res.msg, 'success')
						this.adminShow = false
						this.getAdminList()
					} else {
						eltips(res.msg, 'error')
					}

				})
			},
			//计算table高度
			getTableMaxHeight() {
				this.$nextTick(function() {
					let pages = document.querySelector(".pages").attributes
					let pagesHeight = pages[0].ownerElement.clientHeight;
					let headbox = document.querySelector(".pages").attributes
					let headboxHeight = headbox[0].ownerElement.clientHeight;
					this.tabHeight = `calc(100% - ${pagesHeight+headboxHeight}px)`
				})
			},
			pickClass(e) {
				if (this.class_id != e.id) {
					this.class_id = e.id
					this.page = 1
					this.getAdminList()
				}
			},
			//树形结构查找ID
			searchTree(nodes, searchKey) {
				for (let _i = 0; _i < nodes.length; _i++) {
					if (nodes[_i].id === searchKey) {
						return nodes[_i];
					} else {
						if (nodes[_i].child && nodes[_i].child.length > 0) {
							let res = this.searchTree(nodes[_i].child, searchKey);
							if (res) return res;
						}
					}
				}
				return null
			},
			//删除部门
			delClass(data) {
				this.$confirm('此操作将永久删除该部门, 是否继续?', '删除部门', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.axios.delete(`/api/accountClass/${data.id}`).then(res => {
						this.classLoading = false
						if(data.id == this.class_id){
							this.class_id = data.pid?data.pid:(this.classList.length>0?this.classList[0].id:'');
							this.$nextTick(()=>{
								this.$refs.tree.setCurrentKey(this.class_id)
							})
							this.page = 1;
							this.getAdminList()
						} 
						if (res.code == 200) {
							eltips(res.msg, 'success')
							this.$refs.tree.remove(data)
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//新增，编辑部门
			editClass(info, method) {
				this.$prompt('请输入部门名称', `${method=='post'?'添加':'编辑'}部门`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator(value) {
						if (!value) return '部门名称不能为空';
						if (value.replace(/\s/g, '').length < 1) return '部门名称不能为空';
					},
					inputErrorMessage: '部门名称不能为空'
				}).then(({
					value
				}) => {
					let data = {
						name: value
					}
					let url = '/api/accountClass'
					if (method == 'post'){
						data.pid = info.id || '';
						if(this.userinfo.guard_name!='api'&&!data.pid){
							data.pid = this.userinfo.class_id
						}
					} 
					
					if (method == 'put') url += '/' + info.id;
					this.classLoading = true
					this.axios[method](url, data).then(res => {
						this.classLoading = false
						if (res.code == 200) {
							eltips(res.msg, 'success')
							if (method == 'post') {
								if (!info.id) {
									this.classList.push({
										id: res.data.id,
										name: value,
										pid: data.id,
										child: []
									})
									return
								}
								let item = this.searchTree(this.classList, info.id)
								if (item.child && item.child.length > 0) {
									item.child.push({
										id: res.data.id,
										name: value,
										pid: data.pid,
										child: []
									})
								} else {
									item.child = [{
										id: res.data.id,
										name: value,
										pid: data.pid,
										child: []
									}]
								}
							}
							if (method == 'put') {
								let item = this.searchTree(this.classList, info.id)
								item.name = value
							}
						} else {
							eltips(res.msg, 'error')
						}
					})
				}).catch(() => {});
			},
			//获取部门列表
			getClassList() {
				this.classLoading = true
				this.axios.get('/api/accountClass').then(res => {
					this.classLoading = false
					if (res.code == 200) {
						this.classList = res.data
						if (res.data.length > 0) {
							this.class_id = res.data[0].id
							this.getAdminList()
							this.$nextTick(()=>{
								this.$refs.tree.setCurrentKey(this.class_id)
							})
						}
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//翻页
			changePage(e) {
				this.page = e
				this.getAdminList()
			},
			//获取admin账号列表
			getAdminList() {
				let data = {
					class_id: this.class_id,
					page: this.page,
					status: this.status,
				}
				this.loading = true
				let url = '/api/orange_manage/user/adminList';
				if (this.class_id == 1) {
					url = '/api/UserAccount';
					data.phone = this.name
				} else {
					data.username = this.name
				}
				this.axios.get(url, {
					params: data
				}).then(res => {
					this.loading = false
					if (res.code == 200) {
						this.total = res.data.total
						this.list = res.data.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//添加账号
			addAdminUser() {
				let url = '/api/orange_manage/user/adminUser';
				if (this.class_id == 1) url = '/api/addUserAccount';
				let data = {
					username: this.username,
					name: this.name,
					phone: this.phone,
					password: this.password,
					password_confirmation: this.password_confirmation,
					remarks: this.remarks,
					status: this.status,
				}
			},
			//获取权限列表
			getPermissions() {
				this.axios.get('/api/getPermissions').then(res => {
					if (res.code == 200) {
						this.permissionsList = res.data
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//获取指定用户的权限
			getUserPermissions() {
				let id = this.admin_id;
				let data = { guard: 'orange_admin_user' }
				if (this.class_id == 1) {
					id = this.franchisee_id;
					data.guard = 'api'
				}
				this.permissionLoading = true
				this.axios.get(`/api/appointPermissions/${id}`, { params: data }).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.$refs.permissionTree.setCheckedKeys(res.data)
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//设置权限 
			setPermissions() {
				let permissions = this.$refs.permissionTree.getCheckedKeys()
				let halfChecked = this.$refs.permissionTree.getHalfCheckedKeys()
				permissions = [...permissions, ...halfChecked]
				let data = {
					user_id: this.class_id == 1 ? this.franchisee_id : this.admin_id,
					permission_ids: permissions,
					table: this.class_id == 1 ? 'users' : 'orange_admin_user'
				}
				this.permissionLoading = true
				this.axios.post('/api/setPermissions', data).then(res => {
					this.permissionLoading = false
					if (res.code == 200) {
						this.permissionShow = false;
						eltips(res.msg, 'success')
					} else {
						eltips(res.msg, 'error')
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#premission /deep/ .tableBox .headbox .el-form-item {
		margin-bottom: 10px !important;
	}

	#premission /deep/ .classList .el-tree-node {
		margin-bottom: 10px !important;
	}

	#premission {
		min-height: calc(100vh - 70px);
		display: flex;
	}

	.classList {
		width: 350px;
		height: calc(100vh - 70px);
		padding: 10px 18px;
		overflow-y: auto;
		border: 2px solid #1989fa;
		border-radius: 8px;
		background: #FFFFFF;
		margin-right: 10px;

		.titlestyle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12px;
			padding-bottom: 10px;
			padding-top: 4px;
			border-bottom: 1px solid #c3c3c3;

			.text {
				font-size: 14px;
				color: #000000;
				font-weight: bold;
			}
		}
	}

	.tableBox {
		width: calc(100% - 350px - 10px);
		height: calc(100vh - 70px);
		border: 2px solid #1989fa;
		border-radius: 8px;
		padding: 10px;
		background: #FFFFFF;

		.headbox {
			background: #ffffff;
			border-radius: 2px;
			border-bottom: 1px solid #c3c3c3;
		}

		.pages {
			text-align: center;
			background: #ffffff;
			padding: 10px 0;
			border-top: 1px solid #ededed;
		}
	}
</style>
