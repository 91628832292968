<template>
	<div id="dataView" element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="headbox">
			仁立通云数据实时监控中心
			<el-image class="logOut" v-if="guard_name=='orange_admin_user'" @click="logOut" :src="require('assets/logOut.png')" fit="cover"></el-image>
		</div>
		<div class="contentbox">
			<div class="flexbox">
				<div class="eachrtsbox">
					<div class="titlebox">企业TOP榜</div>
					<div class="charts" id="companyEcharts"></div>
				</div>
				<div class="eachrtsbox">
					<div class="titlebox">最受欢迎职位TOP榜</div>
					<div class="charts" id="welcoEcharts">

					</div>
				</div>
				<div class="eachrtsbox">
					<div class="titlebox">赏金职位TOP榜</div>
					<div class="charts" id="rewardEcharts"></div>
				</div>
			</div>
			<div class="mapbox">
				<div class="mapdata">
					<div class="box" :class="{'active':map_type=='all'}" @click="changeMapType('all')">
						<div class="label">服务就业人数</div>
						<div class="value">
							<countTo autoplay :duration="2000" :startVal="topData.Service_emplo_count_start"
								:endVal='topData.Service_emplo_count'></countTo>
						</div>
					</div>
					<div class="box" :class="{'active':map_type=='company'}" @click="changeMapType('company')">
						<div class="label">企业数量</div>
						<div class="value">
							<countTo autoplay :duration="2000" :startVal="topData.Company_count_start"
								:endVal='topData.Company_count'></countTo>
						</div>
					</div>
					<div class="box" :class="{'active':map_type=='job'}" @click="changeMapType('job')">
						<div class="label">岗位数量</div>
						<div class="value">
							<countTo autoplay :duration="2000" :startVal="topData.job_count_start" :endVal='topData.job_count'>
							</countTo>
						</div>
					</div>
					<div class="box" :class="{'active':map_type=='online'}" @click="changeMapType('online')">
						<div class="label">在线数量</div>
						<div class="value">
							<countTo autoplay :duration="2000" :startVal="topData.online_count_start"
								:endVal='topData.online_count'></countTo>
						</div>
					</div>
					<div class="box" :class="{'active':map_type=='job_gap'}" @click="changeMapType('job_gap')">
						<div class="label">岗位缺口人数</div>
						<div class="value">
							<countTo autoplay :duration="2000" :startVal="topData.job_gap_data_start"
								:endVal='topData.job_gap_data'></countTo>
						</div>
					</div>
				</div>
				<div class="maps">
					<china
						:newPoint="updateUserLocation" 
					    :area-code.sync="areaCode"
					    :area-level.sync="areaLevel"
					    :area-name.sync="areaName"
					    :map-name-list.sync="mapNameList"
					    :map-code-list.sync="mapCodeList"
					    :area-statistic="areaStatistic"
						:map_type="map_type"
						:not_click="false"
					/>
				</div>
			</div>
			<div class="flexbox">
				<div class="eachrtsbox">
					<div class="titlebox">数据实时动态</div>
					<div class="listbox">
						<div class="deliveryBox" v-for="(item,index) in seekList" :key="index">
							<div class="name">{{noPassByMobile(item.phone)}}</div>
							<div class="company">{{item.n_area}}</div>
							<!-- <div class="time">{{timeStyle(item.created_at)}}</div> -->
							<div class="time">{{item.created_ats}}</div>
						</div>
					</div>
				</div>
				<div class="eachrtsbox">
					<div class="ringbox">
						<div class="ring" id="ring1"></div>
						<div class="ring" id="ring2"></div>
						<div class="ring" id="ring3"></div>
					</div>
				</div>
				<div class="eachrtsbox">
					<div class="titlebox">实时报名动态</div>
					<div class="listbox">
						<div class="deliveryBox" v-for="(item,index) in list" :key="index">
							<div class="name">{{item.seeker?item.seeker.realname:'-'}}</div>
							<div class="company">{{item.job_title}}</div>
							<div class="time">{{timeStyle(item.created_at)}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapkey,
		eltips,
		setMapEcharts
	} from '@/util/util.js'
	import {resetRouter} from '@/router/index.js'
	import countTo from 'vue-count-to';
	import China from "./components/china";
	export default {
		name: 'dataView',
		components: {
			countTo,China
		},
		data() {
			return {
				areaCode: '000000000000', // 当前用的areaCode
				areaLevel: 0, // 当前用的areaCode
				areaName: 'china', // 当前用的areaName
				mapNameList: [], // 当前地图上的地区名字
				mapCodeList: [], // 当前地图上的地区Code
				areaStatistic: [], // 测试
				loading: true,
				map_type: 'all', //1、在线量
				topData: {
					Service_emplo_count: 0,
					Service_emplo_count_start: 0,
					Company_count: 0,
					Company_count_start: 0,
					job_count: 0,
					job_count_start: 0,
					online_count: 0,
					online_count_start: 0,
					job_gap_data: 0,
					job_gap_data_start: 0
				},
				list: [],
				seekList:[],
				//地图默认数据，
				map_data: {
					all: '',
					company: '',
					job: '',
					online: '',
					job_gap: '',
				},
				websock:'',
				updateUserLocation:[]
			};
		},
		created() {
			this.guard_name = JSON.parse(sessionStorage.getItem('userinfo')).guard_name
			this.initWebSocket();
		},
		destroyed() {
			this.websock.close();
		},
		async mounted() {
			this.getTopData()
			this.getEchartsData()
		},
		methods: {
			noPassByMobile(str){
				if(null != str && str != undefined){
					var pat=/(\d{3})\d*(\d{4})/;
					return str.replace(pat,'$1****$2');
				} else {
					return "";
				}
			},
			logOut(){
				this.$confirm('此操作将登出此账号, 是否继续?', '登出', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
					let type = userinfo.type
					let guard_name = userinfo.guard_name
					let guard_info = {
						type:type,
					}
					guard_info.guard_name = 'orange_admin_user'
					this.$router.replace({path: "/orangelogin"})
					resetRouter()
					this.$store.commit('changeRouteadds', 0)
					sessionStorage.setItem('guard_info',JSON.stringify(guard_info))
					sessionStorage.removeItem('routestext')
					sessionStorage.removeItem('routes')
					sessionStorage.removeItem('userinfo')
					sessionStorage.removeItem('token')
					sessionStorage.removeItem('pluginroute')
					sessionStorage.removeItem('pluginid')
					sessionStorage.removeItem('isApply')
				}).catch(() => {});
			},
			timeStyle(date){
				let newDate = new Date(date)
				let y = newDate.getFullYear()
				let m = newDate.getMonth()+1
				let d = newDate.getDate()
				return `${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d}`
			},
			initWebSocket() { //初始化weosocket
				const wsuri = "wss://juzi.job695.com/wss/";
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onopen = this.websocketonopen;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
			},
			websocketonopen() { //连接建立之后执行send方法发送数据
				console.log('websocket链接成功')
			},
			websocketonerror() { //连接建立失败重连
				this.initWebSocket();
			},
			websocketonmessage(e) { //数据接收
				let res = JSON.parse(e.data)
				if(res.MessageType==1){
					this.topData.Service_emplo_count_start = this.topData.Service_emplo_count
					this.topData.Service_emplo_count = res.count
					this.seekList.unshift(res.data)
					this.updateUserLocation = [res.location.lat,res.location.lng,'all'];
				}
				if(res.MessageType==2){
					this.topData.Company_count_start = this.topData.Company_count
					this.topData.Company_count = res.count
					this.updateUserLocation = [res.location.lat,res.location.lng,'company'];
				}
				if(res.MessageType==3){
					this.topData.job_count_start = this.topData.job_count
					this.topData.job_count = res.count
					this.updateUserLocation = [res.location.lat,res.location.lng,'job'];
				}
				if(res.MessageType==4||res.MessageType==6){
					this.topData.online_count_start = this.topData.online_count
					this.topData.online_count = res.count
					this.updateUserLocation = [res.location.lat,res.location.lng,'online'];
				}
				if(res.MessageType==5){
					let item = {
						seeker:{
							realname:res.data.name
						},
						job_title:res.data.job_title,
						created_at:res.data.date
					}
					this.list.unshift(item)
				}
			},
			websocketclose(e) { //关闭
				console.log('断开连接', e);
			},
			changeMapType(type) {
				if (this.map_type != type) {
					this.map_type = type
				}
			},
			//地图头部统计
			getTopData() {
				this.axios.get('/api/orange_manage/VisualData/Data_summary').then(res => {
					if (res.code == 200) {
						this.topData = {
							Service_emplo_count: res.data.Service_emplo_count,
							Company_count: res.data.Company_count,
							job_count: res.data.job_count,
							online_count: res.data.online_count,
							job_gap_data: res.data.job_gap_data,
						}
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//获取echarts数据
			getEchartsData() {
				//企业榜
				this.axios.get('/api/orange_manage/VisualData/company').then(res => {
					if (res.code == 200) {
						let y_data = []
						let series_data = []
						res.data.forEach(item => {
							y_data.unshift(item.abbr)
							series_data.unshift(item.delivery_count)
						})
						setMapEcharts({
							el: 'companyEcharts',
							y: {
								data: y_data
							},
							series: [{
								data: series_data,
								type: 'bar',
								name: '报名数'
							}]
						})
					}
				})
				//受欢迎职位
				this.axios.get('/api/orange_manage/VisualData/job').then(res => {
					if (res.code == 200) {
						let y_data = []
						let series_data = []
						res.data.forEach(item => {
							y_data.unshift(item.title)
							series_data.unshift(item.delivery_count)
						})
						setMapEcharts({
							el: 'welcoEcharts',
							y: {
								data: y_data
							},
							series: [{
								data: series_data,
								type: 'bar',
								name: '投递数'
							}]
						})
					}
				})
				//赏金职位
				this.axios.get('/api/orange_manage/VisualData/reward').then(res => {
					if (res.code == 200) {
						let y_data = []
						let series_data = []
						res.data.forEach(item => {
							y_data.unshift(item.title)
							series_data.unshift(item.sum_money || 0)
						})
						setMapEcharts({
							el: 'rewardEcharts',
							y: {
								data: y_data
							},
							series: [{
								data: series_data,
								type: 'bar',
								name: '赏金总额'
							}]
						})
					}
				})
				//实时投递
				this.axios.get('/api/orange_manage/delivery/dynamics').then(res => {
					this.list = res.data
				})
				//环形统计
				this.axios.get('/api/orange_manage/VisualData/proportion').then(res=>{
					let gender = res.data.gender
					//性别
					setMapEcharts({
						el: 'ring1',
						title:{
							text:'性别分布',
							left:'center',
							textStyle:{
								color:'#FFFFFF',
								fontWeight:'normal'
							}
						},
						legend:{
							x:'center',
							y:'50%',
							show:true,
							textStyle:{
								color:'#FFFFFF'
							}
						},
						series: [{
							center: ["50%", "30%"],
							radius: ['40%', '60%'],
							data: [
								{value:gender.males ,name:'男'},
								{value:gender.famale ,name:'女'},
								{value:gender.unkonwn ,name:'未知'},
							],
							type: 'pie',
							name: '性别',
							label: {
								show: false
							},
						}]
					})
					//年龄
					let age = []
					res.data.age.forEach(item=>{
						age.push({
							value:item.count,name:item.msg
						})
					})
					setMapEcharts({
						el: 'ring2',
						title:{
							text:'年龄分布',
							left:'center',
							textStyle:{
								color:'#FFFFFF',
								fontWeight:'normal'
							}
						},
						legend:{
							x:'center',
							y:'50%',
							show:true,
							textStyle:{
								color:'#FFFFFF'
							}
						},
						series: [{
							center: ["50%", "30%"],
							radius: ['40%', '60%'],
							data: age,
							type: 'pie',
							name: '年龄',
							label: {
								show: false
							},
						}]
					})
					//学历
					let education = []
					res.data.education.forEach(item=>{
						education.push({
							value:item.count,name:item.msg
						})
					})
					setMapEcharts({
						el: 'ring3',
						title:{
							text:'学历情况',
							left:'center',
							textStyle:{
								color:'#FFFFFF',
								fontWeight:'normal'
							}
						},
						legend:{
							x:'center',
							// y:'65%',
							y:'50%',
							show:true,
							textStyle:{
								color:'#FFFFFF'
							}
						},
						series: [{
							// center: ["50%", "40%"],
							// radius: ['60%', '90%'],
							center: ["50%", "30%"],
							radius: ['40%', '60%'],
							data: education,
							type: 'pie',
							name: '学历',
							label: {
								show: false
							},
						}]
					})
				})
				//数据实时动态
				this.axios.get('/api/orange_manage/seekers/dynamics').then(res=>{
					this.seekList = res.data
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	/deep/ a {
		display: none !important;
	}

	/deep/ .logo-text {
		display: none !important;
	}

	#dataView {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		background: rgb(33, 34, 37);
		color: #FFFFFF;
		box-sizing: border-box;
		background: url('../../assets/data-bg.png');
		background-size: 100% 100%;
		.headbox {
			text-align: center;
			font-size: 30px;
			height: 80px;
			line-height: 80px;
			position: relative;
			.logOut{
				width: 22px;
				height: 22px;
				position: absolute;
				right: 28px;
				top: 29px;
			}
		}

		.contentbox {
			display: flex;
			padding: 0px 18px;
		}

		.flexbox {
			flex: 1;
			display: flex;
			flex-direction: column;
			height: calc(100vh - 104px);
			.eachrtsbox {
				flex: 1;
				overflow: hidden;
				margin-bottom: 18px;
				background: rgba(40, 149, 221,.3);
				padding:14px 18px;
				border-radius: 4px;
				position: relative;
				&::before{
					content: '';
					width: 50%;
					height: 40%;
					border-top: 2px solid #3979d1;
					border-left: 2px solid #3979d1;
					border-radius: 4px 0 0 0;
					position: absolute;
					left: 0;
					top: 0;
				}
				&::after{
					content: '';
					width: 50%;
					height: 40%;
					border-bottom: 2px solid #3979d1;
					border-right: 2px solid #3979d1;
					border-radius: 0 0 4px 0;
					position: absolute;
					right: 0;
					bottom: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}

				.titlebox {
					font-size: 18px;
					padding-left: 12px;
					position: relative;
					line-height: 1;
					margin-bottom: 18px;

					&::after {
						content: '';
						width: 4px;
						height: 100%;
						background: #FFFFFF;
						position: absolute;
						left: 0;
						top: 0;
					}
				}

				.charts {
					width: 100%;
					height: calc(100% - 36px);
				}
				.ringbox{
					display: flex;
					align-items: center;
					height: 100%;
					.ring{
						flex: 1;
						height: 100%;
						border: none !important;
					}
				}

				.listbox {
					height: calc(100% - 46px);
					overflow: hidden;
					.deliveryBox{
						display: flex;
						align-items: center;
						.name{
							
						}
						.company{
							flex: 1;
							padding: 0 18px;
							text-align: center;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.time{
							
						}
					}
				}
			}
		}

		.mapbox {
			flex: 2;
			position: relative;
			height: calc(100vh - 104px);
			margin: 0 98px;
			.mapdata {
				display: flex;
				align-items: center;
				.box{
					flex: 1;
					margin-right: 18px;
					background: rgba(40, 149, 221,.3);
					height: 80px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 16px;
					&:last-child{
						margin-right: 0;
					}
					background: rgba(40, 149, 221,.3);
					padding: 18px;
					border-radius: 4px;
					position: relative;
					cursor: pointer;
					&::before{
						content: '';
						width: 50%;
						height: 40%;
						border-top: 2px solid #3979d1;
						border-left: 2px solid #3979d1;
						border-radius: 4px 0 0 0;
						position: absolute;
						left: 0;
						top: 0;
					}
					&::after{
						content: '';
						width: 50%;
						height: 40%;
						border-bottom: 2px solid #3979d1;
						border-right: 2px solid #3979d1;
						border-radius: 0 0 4px 0;
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}
				.active{
					color: #16b2ea;
				}
			}

			.maps {
				width: 100%;
				height: calc(100% - 98px);
				position: relative;
				margin-top: 18px;
				.stylebox {
					position: absolute;
					left: 0px;
					top: 20px;
					display: flex;

					.levelBox {
						display: flex;
						align-items: center;
						margin-right: 16px;

						.colorbox {
							width: 28px;
							height: 14px;
							background: #FFFFFF;
							margin-right: 8px;
						}

						.text {
							line-height: 1;
							font-size: 14px;
						}
					}
				}

			}
		}
	}
</style>
